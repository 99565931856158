import {Alert, Box, CircularProgress, Paper, Stack, Typography} from "@mui/material";
import {ReactNode, useEffect, useState} from "react";
import {AgentInfo} from "../../components/agents/AgentInfo";
import {AgentInstructionListStandalone} from "../../components/agents/AgentInstructionListStandalone";
import {useSnackbar} from "../../components/agents/SnackMessage";
import {AgentPermissionsProvider, createAgentPerms} from "../../contexts/AgentPermissions";
import {useCurrentUser} from "../../contexts/UserProvider";
import {ContextEngineAgent} from "../../context_engine_client";
import {getContextEngineAgentPlatformService, makeTaggedTitleString} from "../../utilities";
import {agentIdFromPath} from "../../utilities/agent";

// ============================================================================
// HELPERS ====================================================================

const getContent = (agent: null | ContextEngineAgent, isLoading: boolean, defaultAction: "view" | "edit"): ReactNode => {
  if (isLoading) {
    return (
      <Box marginX="auto">
        <CircularProgress sx={{margin: "auto"}} />
      </Box>
    );
  }

  if (!agent) {
    return (
      <Alert severity="error">
        <Typography>Invalid Agent ID</Typography>
      </Alert>
    );
  }

  return (
    <Stack spacing={3} useFlexGap={true}>
      <AgentInfo
        agent={agent}
        isHeading={true}
        defaultAction={defaultAction}
      />

      <Typography variant="h3">Example Instructions</Typography>

      <Paper
        elevation={2}
        sx={{
          backgroundColor: "info.light",
          padding: 2,
          position: "relative",
        }}
      >
        <AgentInstructionListStandalone
          agentId={agent.agent_id}
        />
      </Paper>
    </Stack>
  );
}

const getTitle = (agent: null | ContextEngineAgent, isLoading: boolean): string => {
  if (isLoading) {
    return "Agent Loading ...";
  }

  if (!agent) {
    return "Invalid Agent";
  }

  return agent.agent_info.agent_name;
}

// ============================================================================

export const AgentSinglePage = () => {
  const [agent, setAgent] = useState<null | ContextEngineAgent>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [defaultAction, setDefaultAction] = useState<"view" | "edit">("view");

  const snackbar = useSnackbar();

  useEffect(() => {
    const agentId = agentIdFromPath(window.location.pathname);
    if (!agentId) {
      setIsLoading(false);
      return;
    }

    let hash = window.location.hash;
    if (hash) {
      if (hash.startsWith("#")) {
        hash = hash.substring(1);
      }
      const params = new URLSearchParams(hash);
      const action = params.get("action");
      if (action === "view" || action === "edit") {
        setDefaultAction(action);
      }
    }

    const platformService = getContextEngineAgentPlatformService();
    platformService.getAgent(agentId)
      .then((fetchedAgent: ContextEngineAgent) => {
        setAgent(fetchedAgent);
        snackbar?.success("Loaded agent!");
      })
      .catch((error) => {
        snackbar?.error("Could not load agent");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const perms = createAgentPerms(agent, useCurrentUser().curUser);
  const content = getContent(agent, isLoading, defaultAction);
  const title = getTitle(agent, isLoading);
  document.title = makeTaggedTitleString(title);

  console.log(perms)

  return <AgentPermissionsProvider value={perms}>
    {content}
  </AgentPermissionsProvider>;
};
