import {ReactNode} from 'react';
import {Outlet, Route} from 'react-router-dom';
import {RequireLoggedInUser} from '../../contexts/UserProvider';
import {AgentSinglePage} from './AgentSinglePage';
import {AgentHomePage} from './AgentHomePage';
import {AgentOldHomePage} from './AgentOldHomePage';
import {AgentContexts} from './AgentContexts';
import {AgentSearchPage} from './AgentSearchPage';

export {AgentSearchPage, AgentSinglePage, AgentOldHomePage, AgentContexts};

const RouteMap: {[key: string]: ReactNode} = {
  "old": <AgentOldHomePage />,

  // list is an alias for search
  "list": <AgentSearchPage />,
  "search": <AgentSearchPage />,

  "contexts": <AgentContexts />,
  ":agentId": <AgentSinglePage />,
};

export const Routes = (
  <Route element={<Outlet />}>
    {/* This will be the "home" page */}
    <Route index element={<RequireLoggedInUser><AgentHomePage /></RequireLoggedInUser>} />

    {Object.keys(RouteMap).map((key) =>
      <Route key={key} path={key} element={<RequireLoggedInUser>{RouteMap[key]}</RequireLoggedInUser>} />
    )}
  </Route>
);
