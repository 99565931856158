import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {Button, Collapse, darken, IconButton, List, ListItem, ListItemButton, ListItemText, Typography} from "@mui/material";
import {createContext, PropsWithChildren, useContext, useState} from "react";


export type NavSectionContextInfo = {
  setIsActive: (val: boolean) => void;
};


export const NavSectionContext = createContext<NavSectionContextInfo | null>(null);
const NavSectionProvider = NavSectionContext.Provider;


export type SideBarNavSectionProps = PropsWithChildren & {
  label: string;
  to?: string;
};

export const SideBarNavSection = ({to, label, children}: SideBarNavSectionProps) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [showChildren, setShowChildren] = useState<null | boolean>(null);

  console.log("parent section has active: ", isActive);

  return (
    <NavSectionProvider
      value={{setIsActive}}
    >

      <ListItem
        disablePadding
        key={to + ":" + label}
        sx={{
          flexDirection: "column",
          alignItems: "stretch",
        }}
      >
        <ListItemButton
          dense
          onClick={() => setShowChildren(!showChildren)}
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <Typography>
            {label}
          </Typography>

          <IconButton size="small" sx={{marginLeft: "auto"}}>
            {showChildren
              ? <ExpandLess fontSize="small" />
              : <ExpandMore fontSize="small" />
            }
          </IconButton>
        </ListItemButton>

        <Collapse
          in={
            (isActive && showChildren === null)
            || (showChildren !== null && showChildren)
          }
          sx={{
            paddingLeft: 3,
          }}
        >
          <List>
            {children}
          </List>
        </Collapse>
      </ListItem>

    </NavSectionProvider>
  );
}
