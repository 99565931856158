import {
  Stack,
  SxProps,
  Theme,
} from "@mui/material";
import { useState } from "react";

import {InstructionGenerationResult, TestInstructionGenerator} from "../../components/agents/TestInstructionGenerator";
import {TestInstructionExecutor} from "../../components/agents/TestInstructionExecutor";

export type AgentTesterProps = {
  agentId: string,
  sx?: SxProps<Theme>,
};

export const AgentTester = ({agentId, sx}: AgentTesterProps) => {
  const [dslResult, setDSLResult] = useState<undefined | InstructionGenerationResult>(undefined);
  const [userPrompt, setUserPrompt] = useState("");

  const handleUserPromptChange = (e: any) => {
    setUserPrompt(e.target.value);
  };

  return (
      <Stack
        spacing={3}
        useFlexGap={true}
        sx={[
          // You cannot spread `sx` directly because `SxProps` (typeof sx) can be an array.
          ...(Array.isArray(sx) ? sx : [sx]),    
        ]}
      >
        <TestInstructionGenerator
          agentId={agentId}
          onNewInstructions={(result) => setDSLResult(result)}
        />

        { dslResult?.success &&
          dslResult?.dslId &&
          <TestInstructionExecutor
            dslId={dslResult.dslId}
          />
        }
      </Stack>
  );
}
