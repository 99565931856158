import {Stack, SxProps, Theme} from "@mui/material";
import {ContextEngineAgent} from "../../context_engine_client";
import {AgentPermissionsProvider, createAgentPerms} from "../../contexts/AgentPermissions";
import {useCurrentUser} from "../../contexts/UserProvider";
import {AgentInfo} from "./AgentInfo";

type AgentListEventWithAgent = {
  type: "click" | "edit" | "clone" | "delete" | "test";
  agent: ContextEngineAgent;
};
type AgentListEventWithoutAgent = {
  type: "new";
  info: {
    name: string;
    description: string;
  };
};
export type AgentListEvent = AgentListEventWithAgent | AgentListEventWithoutAgent;

export type AgentClickHandler = (agent: AgentListEventWithAgent) => void;
export type EventClickHandler = (agent: AgentListEventWithoutAgent) => void;

export type AgentListProps = {
  agents: null | ContextEngineAgent[],
  onAgentSelected?: AgentClickHandler;
  onAgentEdit?: AgentClickHandler;
  onAgentDelete?: AgentClickHandler;
  onAgentClone?: AgentClickHandler;
  sx?: SxProps<Theme>;
  agentsClickable?: boolean;
};

export const AgentList = ({
  agents,
  sx,
  onAgentSelected,
  onAgentEdit,
  onAgentClone,
  onAgentDelete,
  agentsClickable,
}: AgentListProps) => {
  const userInfo = useCurrentUser();

  return (
    <Stack gap={0} useFlexGap={true} sx={sx}>
      {agents?.map((agent) => {
        const perms = createAgentPerms(agent, userInfo.curUser);

        return <AgentPermissionsProvider value={perms} key={agent.agent_id}>
          <AgentInfo
            key={agent.agent_id}
            agent={agent}
            isHeading={false}
            compact={true}
            clickable={agentsClickable}
            onClick={(agentEvent) => {
              if (!agentsClickable) {return;}
              onAgentSelected && onAgentSelected(agentEvent);
            }}
            onDelete={(agentEvent) => onAgentDelete && onAgentDelete(agentEvent)}
            onEdit={(agentEvent) => onAgentEdit && onAgentEdit(agentEvent)}
            onClone={(agentEvent) => onAgentClone && onAgentClone(agentEvent)}
          />
        </AgentPermissionsProvider>;
      })}
    </Stack>
  );
}
