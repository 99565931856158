import {Alert, Box, IconButton, Stack, Tooltip, Typography} from "@mui/material";
import {AgentDSLDefinition, ContextEngineAgent} from "../../context_engine_client";
import {InstructionDeleteHandler, InstructionEventHandler, InstructionGenerateHandler, InstructionSaveHandler} from "./AgentEvents";
import {AgentInstruction} from "./AgentInstruction";
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import {useContext, useEffect, useState} from "react";
import {AgentPermissionsContext} from "../../contexts/AgentPermissions";

export type AgentInstructionListProps = {
  instructions: AgentDSLDefinition[];
  onSave?: InstructionSaveHandler;
  onGenerate?: InstructionGenerateHandler;
  onDelete?: InstructionDeleteHandler;
};

type DraftInstruction = AgentDSLDefinition & {
  draftId: string;
};

export const AgentInstructionList = ({instructions, onSave, onGenerate, onDelete}: AgentInstructionListProps) => {
  const [draftInstructions, setDraftInstructions] = useState<DraftInstruction[]>([]);

  const perms = useContext(AgentPermissionsContext);
  const canModify = perms.instructions.modify.can;
  const canAdminModify = perms.admin.modify.can;
  const cantModifyReason = perms.instructions.modify.reason;

  useEffect(() => {
    if (!perms.instructions.modify.can) {
      setDraftInstructions([]);
    }
  }, [perms]);

  const newDraftInstruction = () => {
    if (!perms.instructions.modify.can) {
      return;
    }

    const newDraft: DraftInstruction = {
      dsl_id: "",
      ai: [],
      user: "",
      created_at: "",
      updated_at: "",
      draftId: Date.now().toString(),
    };
    setDraftInstructions([...draftInstructions, newDraft]);
  };

  const removeDraftInstr = (toRemove: DraftInstruction) => {
    setDraftInstructions(
      draftInstructions.filter((toKeep) => toKeep.draftId != toRemove.draftId)
    );
  };

  const noExamples = (draftInstructions.length + instructions.length) === 0;

  return (
    <Stack spacing={3} useFlexGap={true} padding={2} paddingBottom={4}>
      { canAdminModify && <Box sx={{width: 1.0, textAlign: "center"}}>
        <Tooltip
          title={canModify
            ? "Make new example instructions"
            : cantModifyReason
          }
        >
          {/*
          Disabled elements (the IconButton when canModify is false) don't
          fire any events, which prevents the Tooltip from working. So
          we wrap it in a div which will always fire events
          */}
          <div>
            <IconButton
              disabled={!canModify}
              onClick={() => newDraftInstruction()}
              sx={{color: "secondary.main"}}
            >
              <AddCircleTwoToneIcon
                fontSize="large"
                sx={{
                  color: canModify ? "secondary.main" : undefined,
                  opacity: canModify ? 1.0 : 0.5,
                }}
              />
            </IconButton>
          </div>
        </Tooltip>
      </Box>}

      {noExamples &&
        <Alert
          severity="warning"
          sx={{
            alignSelf: "center",
            textAlign: "center",
          }}
          elevation={2}
        >
          <Typography variant="body1">
            {canModify
              ? "Click the plus to add your first example!"
              : cantModifyReason
            }
          </Typography>
        </Alert>
      }

      {draftInstructions.map(
        (instr) =>
          <AgentInstruction
            key={instr.draftId}
            instruction={instr}
            onCancel={() => removeDraftInstr(instr)}
            onSave={(event) => {
              if (event.type != "save") {return;}

              event.onSuccess = () => {
                removeDraftInstr(instr)
              };
              onSave && onSave(event);
            }}
            onGenerate={onGenerate}
            startEditing={true}
            canCollapse={false}
          />
      )}

      {instructions.map(
        (instr) =>
          <AgentInstruction
            key={instr.dsl_id}
            instruction={instr}
            onSave={onSave}
            onGenerate={onGenerate}
            onDelete={onDelete}
          />
      )}
    </Stack>
  );
};
