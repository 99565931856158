import { useEffect, useState } from "react";
import {
  getContextEngineAgentPlatformService,
  getContextEngineDefaultService,
} from "./utilities";
import { ContextEngineAgent } from "./context_engine_client";
import { Box, Stack } from "@mui/system";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import ReactJson from "react-json-view";
import { set } from "mobx";
import moment from "moment";
const DisplayTelemetry = ({ agentId }: { agentId: string }) => {
  const [userRequests, setUserRequests] = useState([] as any[]);
  const [executionTelemetry, setExecutionTelemetry] = useState({});

  // console.log("agentId is ", agentId);

  useEffect(() => {
    const contextEngineDefaultService = getContextEngineDefaultService();

    // Hardcoding the start and end date for now
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 7);
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 1);

    contextEngineDefaultService
      .getAgentTelemetry(agentId, moment(startDate).format("YYYY-MM-DDTHH:mm:ss"), moment(endDate).format("YYYY-MM-DDTHH:mm:ss"))
      .then((result) => {
        console.log(result);
        setUserRequests(result);
      }).catch((error) => {
        console.log(error);
        setUserRequests([]);
      })
      .finally(() => {
    setExecutionTelemetry({});
      });
  }, [agentId]);

  const columns: GridColDef[] = [
    { field: "failed", headerName: "Failed", width: 100 },
    { field: "prompt", headerName: "Prompt", width: 1000 },
    { field: "user", headerName: "User Email", width: 300 },
    { field: "dsl_id", headerName: "DSL ID", width: 200 },
    { field: "execution_id", headerName: "Execution ID", width: 200 },
  ];

  const onRowSelected = (row: any) => {
    console.log("row is ", row);
    if (
      row !== undefined &&
      row.row !== undefined &&
      row.row.execution_id !== undefined
    ) {
      const contextEngineDefaultService = getContextEngineDefaultService();
      contextEngineDefaultService
        .getExecutionTelemetry(agentId, row.row.execution_id)
        .then((result) => {
          console.log(result);
          setExecutionTelemetry(result);
        });
    }
  };

  return (
    <div>
      <h2>Telemetry</h2>
      <Typography variant="subtitle1" component="div">
        {agentId}
      </Typography>
      <Box sx={{ width: "100%" }}>
        <DataGrid
          rows={userRequests}
          columns={columns}
          getRowId={(row) => row.execution_id}
          onRowClick={(row) => {
            onRowSelected(row);
          }}
        />
      </Box>
      <Box sx={{ width: "100%", textAlign: "left" }}>
        <ReactJson src={executionTelemetry} />
      </Box>
    </div>
  );
};

export const AgentTelemetryPage = () => {
  // const handleRun = async () => {
  //     setAgentsLoading(true);
  //     const contextEngineAgentPlatform = getContextEngineAgentPlatformService();
  //     contextEngineAgentPlatform
  //       .getAllAgents()
  //       .then((result) => {
  //         console.log(result);
  //         setAgentsLoading(false);
  //         setAgents(result);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   };

  const [agents, setAgents] = useState([] as ContextEngineAgent[]);
  const [agentsLoading, setAgentsLoading] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState("");

  useEffect(() => {
    const contextEngineAgentPlatform = getContextEngineAgentPlatformService();
    setAgentsLoading(true);
    contextEngineAgentPlatform
      .getAllAgents()
      .then((result) => {
        console.log(result);
        setAgents(result);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setAgentsLoading(false);
      });
  }, []);

  const onAgentSelectionChanged = (event: any) => {
    setSelectedAgent(event.target.value);
  };

  const displayAgentsForSelection = () => {
    if (agentsLoading) {
      return <div>Loading...</div>;
    }
    if (agents.length === 0) {
      return <div>No agents found</div>;
    }
    return (
      <div>
        <h2>Agents</h2>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Agent</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedAgent}
            label="Agent"
            onChange={onAgentSelectionChanged}
          >
            {agents.map((agent) => (
              <MenuItem key={agent.agent_id} value={agent.agent_id}>
                <Stack direction="column" spacing={1} margin={2}>
                  <Typography variant="subtitle1" component="div">
                    {agent.agent_info.agent_name}
                  </Typography>
                  <Typography variant="subtitle2" component="div">
                    {agent.agent_id}
                  </Typography>
                </Stack>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  };

  return (
    <div>
      <div>
        <h1>Agent Telemetry Page</h1>
      </div>
      <Stack spacing={2}>
        {displayAgentsForSelection()}
        <DisplayTelemetry agentId={selectedAgent} />
      </Stack>
    </div>
  );
};
