/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AttendeeIdentifier = {
    id?: string;
    email: string;
    displayName?: string;
    organizer?: boolean;
    self?: boolean;
    resource?: boolean;
    optional?: boolean;
    responseStatus: AttendeeIdentifier.responseStatus;
    comment?: string;
    additionalGuests?: number;
};

export namespace AttendeeIdentifier {

    export enum responseStatus {
        NEEDS_ACTION = 'needsAction',
        DECLINED = 'declined',
        TENTATIVE = 'tentative',
        ACCEPTED = 'accepted',
    }


}

