/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ApplicationType {
    FOUNDATION = 'Foundation',
    CUBE = 'Cube',
    AGENT = 'Agent',
}
