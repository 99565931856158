import {Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup, Stack, Switch, TextField, Typography} from "@mui/material";
import {useState} from "react";

export type NewAgentInfo = {
  name: string;
  description: string;
  public?: boolean;
};

export type NewAgentDialogProps = {
  title: string;
  open: boolean;
  onCreate: (info: NewAgentInfo) => void;
  onCancel: () => void;
  actionVerb?: string;
  initName?: string;
  initDescription?: string;
  initPublic?: boolean;
};

export const NewAgentDialog = ({title, open, onCreate, onCancel, initName="", initDescription="", actionVerb="Save", initPublic=false}: NewAgentDialogProps) => {
  const [draftInfo, setDraftInfo] = useState({
    name: initName,
    description: initDescription,
    public: initPublic,
  });

  return (
    <Dialog
      open={open}
      onClose={() => onCancel()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        <Typography fontWeight="bold" fontSize="larger">{title}</Typography>
      </DialogTitle>
      <DialogContent sx={{width: 400}}>
        <Stack spacing={3} useFlexGap={true}>
          <TextField
            margin="dense"
            id="name"
            variant="standard"
            fullWidth
            label="Name"
            value={draftInfo.name}
            onChange={(event) => setDraftInfo({...draftInfo, name: event.target.value})}
          />
          <TextField
            margin="dense"
            multiline
            minRows={2}
            id="description"
            variant="standard"
            fullWidth
            label="Describe your agent"
            value={draftInfo.description}
            onChange={(event) => setDraftInfo({...draftInfo, description: event.target.value})}
          />
          <FormGroup>
            <FormControlLabel label="Public?" control={
              <Switch
                checked={draftInfo.public}
                value={draftInfo.public}
                onChange={(event) => {
                  setDraftInfo({...draftInfo, public: event.target.checked})
                }}
              />
            }/>
          </FormGroup>
        </Stack>
      </DialogContent>
      <DialogActions>
        <ButtonGroup>
          <Button onClick={() => onCancel()} color="warning" variant="outlined">Cancel</Button>
          <Button onClick={() => onCreate(draftInfo)} autoFocus variant="contained">
            {actionVerb}
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
}
