import {Button, ButtonProps, SxProps, Theme, Tooltip, Typography} from "@mui/material";
import {PropsWithChildren} from "react";

export type TooltipButtonProps = PropsWithChildren & ButtonProps & {
  tooltip?: string;
  tooltipOnlyWhenDisabled?: boolean;
};

export const TooltipButton = ({tooltip, tooltipOnlyWhenDisabled=false, ...buttonProps}: TooltipButtonProps) => {
  let title: string | undefined = undefined;
  if (tooltipOnlyWhenDisabled) {
    if (buttonProps.disabled) {
      title = tooltip;
    }
  } else {
    title = tooltip;
  }

  return (
    <Tooltip title={title}>
      <div>
        <Button {...buttonProps} />
      </div>
    </Tooltip>
  );
}
