/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ZoomApiMeetingInfoResponse } from '../models/ZoomApiMeetingInfoResponse';
import type { ZoomParticipantModel } from '../models/ZoomParticipantModel';
import type { ZoomParticipantResponse } from '../models/ZoomParticipantResponse';
import type { ZoomUserModel } from '../models/ZoomUserModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ZoomService {

    /**
     * Get User
     * Returns the current user
     * @returns ZoomUserModel Successful Response
     * @throws ApiError
     */
    public static getUser(): CancelablePromise<ZoomUserModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/zoom/user',
        });
    }

    /**
     * Get Meeting
     * Returns the meeting info
     * @param meetingId
     * @param isWebinar
     * @returns ZoomApiMeetingInfoResponse Successful Response
     * @throws ApiError
     */
    public static getMeeting(
        meetingId: string,
        isWebinar: boolean = false,
    ): CancelablePromise<ZoomApiMeetingInfoResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/zoom/meeting/{meeting_id}',
            path: {
                'meeting_id': meetingId,
            },
            query: {
                'is_webinar': isWebinar,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Zoom Get Participations
     * Gets past meeting participations for the current user
     * @returns ZoomParticipantModel Successful Response
     * @throws ApiError
     */
    public static zoomGetParticipations(): CancelablePromise<Array<ZoomParticipantModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/zoom/participations',
        });
    }

    /**
     * Zoom Get Participants For Meeting
     * Gets a list of current participants for a given meeting_id
     * @param meetingId
     * @returns ZoomParticipantResponse Successful Response
     * @throws ApiError
     */
    public static zoomGetParticipantsForMeeting(
        meetingId: string,
    ): CancelablePromise<Array<ZoomParticipantResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/zoom/participants/{meeting_id}',
            path: {
                'meeting_id': meetingId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Zoom Get Active Meeting
     * Returns the meeting info for the meeting the user is currently in or `null` if the user is not in a meeting
     * @returns ZoomApiMeetingInfoResponse Successful Response
     * @throws ApiError
     */
    public static zoomGetActiveMeeting(): CancelablePromise<ZoomApiMeetingInfoResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/zoom/get_active_meeting',
        });
    }

}
