/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type OpenAIChatMessage = {
    role: OpenAIChatMessage.role;
    content: string;
};

export namespace OpenAIChatMessage {

    export enum role {
        SYSTEM = 'system',
        USER = 'user',
        ASSISTANT = 'assistant',
    }


}

