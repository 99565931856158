/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CE_PaperQuestionAnswer } from '../models/CE_PaperQuestionAnswer';
import type { DocumentSnippet } from '../models/DocumentSnippet';
import type { DocumentSnippetWithScore } from '../models/DocumentSnippetWithScore';
import type { GetRawContentResponse } from '../models/GetRawContentResponse';
import type { ListNamespacesResponse } from '../models/ListNamespacesResponse';
import type { ListPaperDocumentsResponse } from '../models/ListPaperDocumentsResponse';
import type { MessageResponse } from '../models/MessageResponse';
import type { PaperDocInfo } from '../models/PaperDocInfo';
import type { PaperTroubleshootingInfo } from '../models/PaperTroubleshootingInfo';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PaperService {

    /**
     * List All Paper Documents
     * Returns a list of the paper docs the user has access to. This includes:
     * - Docs in the user's home namespace
     * - Docs from shared folders/team folders
     * - Docs directly shared with the user
     *
     * Paging:
     * Pass in the returned cursor to get the next page of results
     * @param cursor
     * @returns ListPaperDocumentsResponse Successful Response
     * @throws ApiError
     */
    public static listAllPaperDocuments(
        cursor?: string,
    ): CancelablePromise<ListPaperDocumentsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/paper/list',
            query: {
                'cursor': cursor,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * List All Paper Documents For Namespace
     * Returns a page of 50 paper docs in the specified namespace.
     *
     * Paging:
     * Pass in the returned cursor to get the next page of results
     * @param nsId
     * @param cursor An optional cursor returned by the previous page of results. Pass it in to get the next page of results.
     * @returns ListPaperDocumentsResponse Successful Response
     * @throws ApiError
     */
    public static listAllPaperDocumentsForNamespace(
        nsId: number,
        cursor?: string,
    ): CancelablePromise<ListPaperDocumentsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/paper/list_in_namespace',
            query: {
                'ns_id': nsId,
                'cursor': cursor,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * List Paper Documents Shared With Me
     * Returns a page of 50 paper docs that have been shared with the user.
     * This is for docs directly shared with you, not docs from shared folders.
     *
     * Paging:
     * Pass in the returned cursor to get the next page of results
     * @param cursor An optional cursor returned by the previous page of results. Pass it in to get the next page of results.
     * @returns ListPaperDocumentsResponse Successful Response
     * @throws ApiError
     */
    public static listPaperDocumentsSharedWithMe(
        cursor?: string,
    ): CancelablePromise<ListPaperDocumentsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/paper/list_shared_with_me',
            query: {
                'cursor': cursor,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * List All Paper Documents In Home
     * Returns a page of 50 paper docs in the user's home namespace.
     * @param cursor An optional cursor returned by the previous page of results. Pass it in to get the next page of results.
     * @returns ListPaperDocumentsResponse Successful Response
     * @throws ApiError
     */
    public static listAllPaperDocumentsInHome(
        cursor?: string,
    ): CancelablePromise<ListPaperDocumentsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/paper/list_in_home',
            query: {
                'cursor': cursor,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * List Namespaces
     * Returns a list of all the namespace ids that the user has access to.
     * @returns ListNamespacesResponse Successful Response
     * @throws ApiError
     */
    public static listNamespaces(): CancelablePromise<ListNamespacesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/paper/list_namespaces',
        });
    }

    /**
     * Get Snippets From File Id
     * Returns snippets for the specified file id.
     * @param fileId
     * @returns DocumentSnippet Successful Response
     * @throws ApiError
     */
    public static getSnippetsFromFileId(
        fileId: string,
    ): CancelablePromise<Array<DocumentSnippet>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/paper/snippets/file_id',
            query: {
                'file_id': fileId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Snippets From Url
     * Returns snippets for the specified url.
     * @param url
     * @returns DocumentSnippet Successful Response
     * @throws ApiError
     */
    public static getSnippetsFromUrl(
        url: string,
    ): CancelablePromise<Array<DocumentSnippet>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/paper/snippets/url',
            query: {
                'url': url,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Snippets From Path
     * Returns snippets for the specified. Acceptable formats are:
     * - a case-insensitive path relative to the user's home folder, e.g. `/paper docs/my doc.paper`
     * - a namespace path in the form `ns:123456/cupcake.png`
     * @param path
     * @returns DocumentSnippet Successful Response
     * @throws ApiError
     */
    public static getSnippetsFromPath(
        path: string,
    ): CancelablePromise<Array<DocumentSnippet>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/paper/snippets/path',
            query: {
                'path': path,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Raw Content From File Id
     * Returns html contents of a paper document given a file_id.
     * @param fileId
     * @returns GetRawContentResponse Successful Response
     * @throws ApiError
     */
    public static getRawContentFromFileId(
        fileId: string,
    ): CancelablePromise<GetRawContentResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/paper/raw_content/file_id',
            query: {
                'file_id': fileId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Raw Content From Path
     * Returns html contents of a paper document given a path. Acceptable formats are:
     * - a case-insensitive path relative to the user's home folder, e.g. `/paper docs/my doc.paper`
     * - a namespace path in the form `ns:123456/cupcake.png`
     * @param path
     * @returns GetRawContentResponse Successful Response
     * @throws ApiError
     */
    public static getRawContentFromPath(
        path: string,
    ): CancelablePromise<GetRawContentResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/paper/raw_content/path',
            query: {
                'path': path,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Raw Content From Url
     * Returns html contents of a paper document given a url.
     * @param url
     * @returns GetRawContentResponse Successful Response
     * @throws ApiError
     */
    public static getRawContentFromUrl(
        url: string,
    ): CancelablePromise<GetRawContentResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/paper/raw_content/url',
            query: {
                'url': url,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Search Paper Documents By Title
     * Returns a paper docs whose title match the query regex
     * @param query
     * @returns PaperDocInfo Successful Response
     * @throws ApiError
     */
    public static searchPaperDocumentsByTitle(
        query: string,
    ): CancelablePromise<Array<PaperDocInfo>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/paper/search_by_title',
            query: {
                'query': query,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Search Paper Documents By Content
     * Returns a ranked list of paper docs that contain the query.
     * Performs a semantic search.
     * @param query
     * @param numResults
     * @param includeEmbedding Include the text embeddings generated by OpenAI
     * @param boostTitle Boost results if the doc title matches the query
     * @returns DocumentSnippetWithScore Successful Response
     * @throws ApiError
     */
    public static searchPaperDocumentsByContent(
        query: string,
        numResults: number = 10,
        includeEmbedding: boolean = false,
        boostTitle: boolean = false,
    ): CancelablePromise<Array<DocumentSnippetWithScore>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/paper/search_by_content',
            query: {
                'query': query,
                'num_results': numResults,
                'include_embedding': includeEmbedding,
                'boost_title': boostTitle,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Answer Question From Paper
     * Returns a list of possible answers for the question asked, by searching through the paper contents.
     * The first answer is the most likely based on the most relevant search result.
     * @param query
     * @param numResults
     * @returns CE_PaperQuestionAnswer Successful Response
     * @throws ApiError
     */
    public static answerQuestionFromPaper(
        query: string,
        numResults: number = 1,
    ): CancelablePromise<Array<CE_PaperQuestionAnswer>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/paper/answer_question',
            query: {
                'query': query,
                'num_results': numResults,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Paper Delete Content
     * Drops the users's search index and deletes all private paper docs from the database.
     * Your paper docs will no longer be synced, unless you manually re-enable it.
     * @returns MessageResponse Successful Response
     * @throws ApiError
     */
    public static paperDeleteContent(): CancelablePromise<MessageResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v3/datasource/paper/delete_content',
        });
    }

    /**
     * Paper Troubleshooting
     * If things doesn't seem to be working, try this endpoint first to check for common issues
     * @returns PaperTroubleshootingInfo Successful Response
     * @throws ApiError
     */
    public static paperTroubleshooting(): CancelablePromise<PaperTroubleshootingInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/paper/troubleshooting',
        });
    }

}
