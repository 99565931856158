import { MarkdownLoader } from '../../components/Markdown';

import overviewDocUrl from '../../docs/overview.md';
import gettingStartedDocUrl from '../../docs/sections/getting-started.md'
import AuthenticationDoc from '../../docs/sections/authentication.md';
import PythonSDKDoc from '../../docs/sections/python-sdk.md';
import DSAPIsDoc from '../../docs/sections/datasources-and-APIs.md';
import JupyterHubDoc from '../../docs/sections/jupyter-hub.md';
import WorkingWithLLMsDoc from '../../docs/sections/working-with-llms.md';
import ContextEngineDoc from '../../docs/sections/context-engine.md';
import FAQDoc from '../../docs/sections/faq.md';
import mlCapabilitiesDocUrl from '../../docs/sections/ml-capabilities.md';
import slackApp from '../../docs/sections/making-a-slack-app.md';
import nodeSdk from '../../docs/sections/nodejs-sdk.md';

import AgentGettingStartedMd from '../../docs/sections/agents_documentation/getting-started.md';
import AgentCeCliMd from '../../docs/sections/agents_documentation/ce-cli.md';
import AgentExamplesMd from '../../docs/sections/agents_documentation/examples.md';
import AgentFaqMd from '../../docs/sections/agents_documentation/faq.md';
import AgentPythonSdkMd from '../../docs/sections/agents_documentation/python-sdk.md';
import AgentTipsAndTricksMd from '../../docs/sections/agents_documentation/tips_and_tricks.md';


export { OpenAIPage } from './OpenAIPage';

export const AgentGettingStartedPage = () => <MarkdownLoader contentUrl={AgentGettingStartedMd} />;
export const AgentCeCliPage = () => <MarkdownLoader contentUrl={AgentCeCliMd} />;
export const AgentExamplesPage = () => <MarkdownLoader contentUrl={AgentExamplesMd} />;
export const AgentFaqPage = () => <MarkdownLoader contentUrl={AgentFaqMd} />;
export const AgentPythonSdkPage = () => <MarkdownLoader contentUrl={AgentPythonSdkMd} />;
export const AgentTipsAndTricksPage = () => <MarkdownLoader contentUrl={AgentTipsAndTricksMd} />;

export const OverviewPage = () => <MarkdownLoader contentUrl={overviewDocUrl} />
export const GettingStartedPage = () => <MarkdownLoader contentUrl={gettingStartedDocUrl} />;
export const AuthenticationPage = () => <MarkdownLoader contentUrl={AuthenticationDoc} />;
export const PythonSDKPage = () => <MarkdownLoader contentUrl={PythonSDKDoc} />;
export const NodeJSSDKPage = () => <MarkdownLoader contentUrl={nodeSdk} />;
export const DataSourcesPage = () => <MarkdownLoader contentUrl={DSAPIsDoc} />;
export const JupyterHubPage = () => <MarkdownLoader contentUrl={JupyterHubDoc} />;
export const WorkingWithLLMsPage = () => <MarkdownLoader contentUrl={WorkingWithLLMsDoc} />;
export const ContextEnginePage = () => <MarkdownLoader contentUrl={ContextEngineDoc} />;
export const FAQPage = () => <MarkdownLoader contentUrl={FAQDoc} />;
export const MlCapabilitiesPage = () => <MarkdownLoader contentUrl={mlCapabilitiesDocUrl} />
export const MakingASlackApp = () => <MarkdownLoader contentUrl={slackApp} />
