/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetMyOpenAIBudgetResponse } from '../models/GetMyOpenAIBudgetResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OpenaiService {

    /**
     * Openai Proxy Get
     * Proxies GET requests to OpenAI, providing the correct OpenAI API key and enforcing per-user budgets.
     * @param endpoint
     * @returns any Successful Response
     * @throws ApiError
     */
    public static openaiProxyGet(
        endpoint: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/openai/proxy/{endpoint}',
            path: {
                'endpoint': endpoint,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Openai Proxy Post
     * @param endpoint
     * @returns any Successful Response
     * @throws ApiError
     */
    public static openaiProxyPost(
        endpoint: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v3/openai/proxy/{endpoint}',
            path: {
                'endpoint': endpoint,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Openai Get My Budget
     * @returns GetMyOpenAIBudgetResponse Successful Response
     * @throws ApiError
     */
    public static openaiGetMyBudget(): CancelablePromise<GetMyOpenAIBudgetResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/openai/budget/me',
        });
    }

}
