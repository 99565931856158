import {Drawer, Toolbar, Box, List, Divider} from "@mui/material"

const drawerWidth = 240;

export const SideBarDrawer = ({children}: any) => {
  return (
    <Drawer
      variant="permanent"
      PaperProps={{
        elevation: 2,
        sx: {
          backgroundColor: "info.light",
        }
      }}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {width: drawerWidth, boxSizing: 'border-box'},
      }}
    >
      <Toolbar />
      <Box sx={{overflow: 'auto', pl: 2}}>
        <List>
          {children}
        </List>
      </Box>
    </Drawer>
  );
}
