import {Typography} from "@mui/material";
import {SideBarNavLink} from "../SideBarNavLink";

export type AgentDocumentationSidebarSectionProps = {
};

export const AgentDocumentationSidebarSection = ({}: AgentDocumentationSidebarSectionProps) => {
  const pages: [string, string][] = [
      [ "/agent/contexts", "Context Reference" ],
      [ "/documentation/agent/getting-started", "Getting Started" ],
      [ "/documentation/agent/examples", "Examples" ],
      [ "/documentation/agent/ce-cli", "CE CLI" ],
      [ "/documentation/agent/faq", "FAQs" ],
      [ "/api-reference/agents", "API Reference" ],
      [ "/documentation/agent/python-sdk", "Python SDK" ],
      [ "/documentation/agent/tips-and-tricks", "Tips & Tricks" ],
  ];

  return (
    <>
      {pages.map(([toPath, label]) =>
        <SideBarNavLink key={`${toPath}-${label}`} to={toPath}>{label}</SideBarNavLink>
      )}
    </>
  );
}
