import {LoadingButton} from "@mui/lab";
import {
  Alert, ButtonGroup, Card, CardContent, CardHeader, Stack, Typography, useTheme,
} from "@mui/material";
import {dark} from "react-syntax-highlighter/dist/esm/styles/hljs";

import {useState} from "react";
import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/default-highlight";
import {ResponseVisualizer} from "./ResponseVisualizer";
import {getContextEngineDefaultService} from "../../utilities";
import {ExecutionRequestInput} from "../../context_engine_client";
import ReactJson from "react-json-view";

export type TestInstructionExecutorProps = {
  dslId: string;
};


type InstructionExecutionResult = {
  output?: any;
  errorMessage?: string;
  errorObj?: any;
  rawResponse?: any;
};

function executeInstructions(dslId: string): Promise<InstructionExecutionResult> {
  const result: Promise<InstructionExecutionResult> = new Promise((resolve, reject) => {
    const contextEngineDefaultService = getContextEngineDefaultService();

    const execResult: InstructionExecutionResult = {};

    const request: ExecutionRequestInput = {
      dsl_id: dslId,
      options: {
        code_generation: true,
        legacy_output_format: false,
      },
    };
    contextEngineDefaultService
      .executeDslWithOption(request)
      .then((result) => {
        console.log(result);
        execResult.rawResponse = result;

        const length = result.execution_steps?.length;
        if (
          length !== undefined &&
          length > 0 &&
          result.execution_steps !== undefined
        ) {
          execResult.output = result.execution_steps[length - 1].output?.value
        }

        resolve(execResult);
      })
      .catch((error) => {
        console.log(error);
        execResult.rawResponse = error.body;
        execResult.errorMessage = "An error occurred";

        if (error.response?.status === 400) {
          execResult.errorMessage = error.response.data;
        } else if (error.body?.detail) {
          const detail: string = error.body.detail;
          if (detail.includes("Error executing generated function") && detail.includes("Traceback (most recent call last):")) {
            const lines = detail.trim().split("\n");
            const exceptionLine = lines[lines.length - 1].trim();

            execResult.errorMessage = exceptionLine;
          } else {
            execResult.errorMessage = detail;
          }
        } else {
          execResult.errorMessage = "ERROR: " + error;
        }

        resolve(execResult);
      })
  });

  return result;
};

export const TestInstructionExecutor = ({dslId}: TestInstructionExecutorProps) => {
  const theme = useTheme();
  const [execResult, setExecResult] = useState<InstructionExecutionResult | undefined>(undefined);
  const [waitingForResponse, setWaitingForResponse] = useState(false);

  const reset = () => setExecResult({});

  let finalResult = <></>;

  if (execResult?.output === undefined) {
    finalResult = <></>;
  } else if (typeof execResult.output === "object") {
    finalResult = (
      <ReactJson
        src={execResult.output}
        theme="monokai"
        displayDataTypes={false}
        displayObjectSize={false}
        style={{width: "100%", height: "100%"}}
        collapsed={true}
      />
    );
  } else {
    finalResult = (
      <SyntaxHighlighter
        language="text"
        style={dark}
        customStyle={{margin: 0}}
        wrapLines={true}
      >
        {execResult.output.toString()}
      </SyntaxHighlighter>
    );
  }

  return (
    <Stack spacing={3} sx={{backgroundColor: "augment"}}>
      <ButtonGroup variant="outlined" sx={{flexGrow: 0, gap: 2}}>
        <LoadingButton
          variant="contained"
          loading={waitingForResponse}
          sx={{alignSelf: "start", flexShrink: 0}}
          onClick={() => {
            reset();
            setWaitingForResponse(true);
            executeInstructions(dslId).then((execResult) => {
              setExecResult(execResult);
              setWaitingForResponse(false);
            });
          }}
        >
          Do the Action!
        </LoadingButton>

        {execResult?.errorMessage &&
          <Alert severity="error">{execResult.errorMessage}</Alert>
        }
      </ButtonGroup>

      {execResult?.rawResponse &&
        <Card raised={true} elevation={2} sx={{borderRadius: theme.shape.borderRadius}}>
          <CardHeader
            title={<Typography>
              { execResult?.errorMessage === undefined
                  ? "Result from doing planned instructions"
                  : "No results, see error message"
              }
            </Typography>}
          />
          <CardContent sx={{paddingTop: 0}}>
            <Stack
              spacing={2}
              useFlexGap={true}
            >
              {finalResult}

              <ResponseVisualizer
                title="Debug Info"
                apiResponse={execResult?.rawResponse}
                success={execResult.errorMessage === undefined}
              />
            </Stack>
          </CardContent>
        </Card>
      }

    </Stack>
  );
}
