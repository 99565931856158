import {LoadingButton} from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box, Button, ButtonGroup,Card,CardContent,CardHeader,Divider,Paper,Stack,TextField, Typography, useTheme,
} from "@mui/material";
import { github } from "react-syntax-highlighter/dist/esm/styles/hljs";

import { ChangeEvent, useState } from "react";
import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/default-highlight";
import {ResponseVisualizer} from "./ResponseVisualizer";
import {getContextEngineDefaultService} from "../../utilities";

export type InstructionGenerationResult = {
  success?: boolean;
  dslId?: string,
  instructions?: string[];
  errorMessage?: string;
  errorObj?: any;
  rawResponse?: any;
};

export type TestInstructionGeneratorProps = {
  agentId: string;
  onNewInstructions: (result: InstructionGenerationResult) => void;
};

function generateInstructionsFromPrompt(selectedAgentId: string, prompt: string): Promise<InstructionGenerationResult> {
  const result: Promise<InstructionGenerationResult> = new Promise((resolve, reject) => {
    const contextEngineDefaultService = getContextEngineDefaultService();
    const genResult: InstructionGenerationResult = {};

    contextEngineDefaultService
      .generateDsl({
        agent_id: selectedAgentId,
        prompt: prompt,
      })
      .then((result) => {
        console.log(result);
        genResult.success = true;
        genResult.rawResponse = result;
        genResult.dslId = result.dsl_id;

        const instructions: string[] | undefined = result.output?.raw_text?.split("\n");
        genResult.instructions = instructions;

        if (result.error_message) {
          genResult.success = false;
          genResult.errorMessage = `${result.result_status} @ ${result.stage}: ${result.error_message}`;
        }

        resolve(genResult);
      })
      .catch((error) => {
        console.log(error);
        genResult.success = false;
        genResult.rawResponse = error;
        genResult.errorMessage = JSON.stringify(error);
        genResult.errorObj = error;

        resolve(genResult);
      });
  });

  return result;
};

export const TestInstructionGenerator = ({agentId, onNewInstructions}: TestInstructionGeneratorProps) => {
  const theme = useTheme();
  const [prompt, setPrompt] = useState("");
  const [genResult, setGenResult] = useState<InstructionGenerationResult | undefined>(undefined);
  const [waitingForResponse, setWaitingForResponse] = useState(false);

  // const showResults = genResult?.instructions !== undefined;
  const reset = () => {
    const newGenResult = {}
    setGenResult(newGenResult);
    onNewInstructions(newGenResult);
  };

  return (
    <Stack spacing={3} sx={{backgroundColor: "augment"}}>
      <TextField
        label="Describe an action that your agent should be able to plan and do."
        id="dsl_prompt"
        onChange={(e: any) => setPrompt(e.target.value)}
      />
      <ButtonGroup variant="outlined" sx={{flexGrow: 0, gap: 2}}>
        <LoadingButton
          variant="contained"
          loading={waitingForResponse}
          sx={{alignSelf: "start", flexShrink: 0}}
          onClick={() => {
            reset();
            setWaitingForResponse(true);
            generateInstructionsFromPrompt(agentId, prompt).then((genResult) => {
              setGenResult(genResult);
              setWaitingForResponse(false);
              if (genResult.instructions) {
                onNewInstructions(genResult);
              }
            });
          }}
        >
          Plan the Action!
        </LoadingButton>

        {genResult?.errorMessage &&
          <Alert severity="error">{genResult.errorMessage}</Alert>
        }
      </ButtonGroup>

      { genResult?.rawResponse &&
        <Card raised={true} elevation={2} sx={{borderRadius: theme.shape.borderRadius}}>
          <CardHeader
            title={<Typography>Planned instructions for the action</Typography>}
          />
          <CardContent sx={{paddingTop: 0}}>
            <Stack
              spacing={2}
              useFlexGap={true}
            >
              { genResult.instructions &&
                <Paper
                  variant="elevation"
                  sx={{ margin: 0 }}
                  elevation={1}
                >
                  <SyntaxHighlighter
                    language="python"
                    style={github}
                    customStyle={{margin: 0, fontSize: "smaller"}}
                    wrapLines={true}
                  >
                    {genResult?.instructions ? genResult.instructions.join("\n") : ""}
                  </SyntaxHighlighter>
                </Paper>
              }

              <ResponseVisualizer
                title="Debug Info"
                apiResponse={genResult?.rawResponse}
                success={genResult.errorMessage === undefined}
              />
            </Stack>
          </CardContent>
        </Card>
      }

    </Stack>
  );
}
