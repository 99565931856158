import {Box, Button, ButtonGroup, Card, CardContent, CardHeader, Collapse, Container, IconButton, Paper, Stack, TextField, Tooltip, Typography, useMediaQuery, useTheme} from "@mui/material";
import {PropsWithChildren, useContext, useState} from "react";
import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/default-highlight";
import { github } from "react-syntax-highlighter/dist/esm/styles/hljs";
import {AgentDSLDefinition} from "../../context_engine_client";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {ExpandMore} from "@mui/icons-material";
import {ExpandLess} from "@mui/icons-material";
import {InstructionDeleteHandler, InstructionEventHandler, InstructionGenerateHandler, InstructionSaveHandler} from "./AgentEvents";
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import {AgentPermissionsContext} from "../../contexts/AgentPermissions";
import {TooltipButton} from "../TooltipButton";
import {InstructionsEditor} from "./InstructionsEditor";


export type AgentInstructionProps = {
  instruction: AgentDSLDefinition;
  startEditing?: boolean;
  onSave?: InstructionSaveHandler;
  onGenerate?: InstructionGenerateHandler;
  onDelete?: InstructionDeleteHandler;
  onCancel?: () => void;
  canCollapse?: boolean;
};

const DivWithTabIndex = ({children}: PropsWithChildren) => <div tabIndex={0}>{children}</div>;

export const AgentInstruction = ({instruction, startEditing, onSave, onGenerate, onCancel, onDelete, canCollapse = true}: AgentInstructionProps) => {
  const [isEditing, setIsEditing] = useState(!!startEditing);
  const [isExpanded, setIsExpanded] = useState(!!startEditing);
  const [draftInstructions, setDraftInstructions] = useState(instruction.ai.join("\n"));
  const [draftDescription, setDraftDescription] = useState("");

  const noHover = useMediaQuery("@media(hover: none)")
  const theme = useTheme();

  const perms = useContext(AgentPermissionsContext);

  const doEdit = () => {
    if (!perms.instructions.modify.can) {
      return;
    }

    setDraftDescription(instruction.user);
    setDraftInstructions(instruction.ai.join("\n"));
    setIsEditing(true);
    setIsExpanded(true);
  };

  const cancelEdit = () => {
    setIsEditing(false);
  };

  const descriptionField = (
    (isExpanded && isEditing)
    ? <TextField
        autoFocus
        variant="standard"
        label="Action description"
        value={draftDescription}
        fullWidth={true}
        onChange={(event) => setDraftDescription(event.target.value)}
      />
    : <Typography>
        {instruction.user}
      </Typography>
  );
  
  return (
    <Card
      variant={ isExpanded ? "elevation" : "outlined" }
      elevation={ isExpanded ? 4 : 0}

      // so it can accept focus (and keyboard events)
      tabIndex={0}

      // edit on double click
      // onClick={(event) => event.detail == 2 && doEdit()}

      // cancel edit on escape
      onKeyDown={(event) => event.key === "Escape" && cancelEdit()}
    >
      <CardContent
        sx={{position: "relative"}}
      >
        <Stack spacing={3} useFlexGap={true}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              cursor: isEditing ? "inherit" : "pointer",
            }}
            onClick={() => (!isExpanded || !isEditing) && setIsExpanded(!isExpanded)}
          >
            <div style={{flexGrow: 1, flexShrink: 0}}>
              {descriptionField}
            </div>

            { canCollapse &&
              <IconButton
                sx={{flexGrow: 0, flexShrink: 0, margin: 0, padding: 0}}
                onClick={() => setIsExpanded(!isExpanded)}
              >
                { isExpanded
                  ? <ExpandLess fontSize="small"/>
                  : <ExpandMore fontSize="small"/>
                }
              </IconButton>
            }
          </Box>

          {isExpanded &&
            <Collapse
              easing={theme.transitions.easing.easeIn}
              in={isExpanded}
            >
              <Stack spacing={3} useFlexGap={true}>
                <InstructionsEditor
                  instructions={draftInstructions}
                  onChange={(newValue) => setDraftInstructions(newValue)}
                  editable={isEditing}
                />

                { perms.admin.modify.can &&
                  <Box sx={{display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>
                    { isEditing
                      ? <>
                          <ButtonGroup sx={{flexGrow: 1, flexShring: 0}}>
                            <TooltipButton
                              tooltip="Generate new instructions from your description"
                              disabled={draftDescription.trim().split(/\s/).length < 3}
                              variant="outlined"
                              color="secondary"
                              size="small"
                              onClick={() => {
                                onGenerate && onGenerate({
                                  type: "generate",
                                  onSuccess: (newInstrs: string[]) => setDraftInstructions(newInstrs.join("\n")),
                                  instructionId: instruction.dsl_id,
                                  user: draftDescription,
                                })
                              }}
                            >
                              <SmartToyOutlinedIcon fontSize="small"/>
                              <Typography fontSize="small" sx={{marginLeft: 0.5}}>
                                Generate
                              </Typography>
                            </TooltipButton>
                          </ButtonGroup>

                          <ButtonGroup sx={{justifyContent: "right", flexGrow: 0, flexShrink: 0}}>
                            <Button
                              variant="outlined"
                              color="error"
                              size="small"
                              onClick={() => {
                                onCancel && onCancel();
                                cancelEdit();
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={() => onSave && onSave({
                                type: "save",
                                onSuccess: () => cancelEdit(),
                                instructionId: instruction.dsl_id,
                                user: draftDescription,
                                ai: draftInstructions.split("\n"),
                              })}
                            >
                              Save
                            </Button>
                          </ButtonGroup>
                        </>
                      : <Tooltip title={perms.instructions.modify.can ? undefined : perms.instructions.modify.reason}>
                          <div>
                            <ButtonGroup sx={{gap: 2}}>
                              <Button
                                disabled={!perms.instructions.modify.can}
                                variant="text"
                                size="small"
                                aria-label="delete"
                                onClick={(e) => {
                                  onDelete && onDelete({
                                    type: "delete",
                                    instructionId: instruction.dsl_id,
                                  });
                                }}
                              >
                                <DeleteIcon fontSize="small"/>
                                <Typography fontSize="small" marginLeft={1}>Delete</Typography>
                              </Button>
                              <Button
                                disabled={!perms.instructions.modify.can}
                                variant="text"
                                size="small"
                                aria-label="edit"
                                onClick={() => doEdit()}
                              >
                                <EditIcon fontSize="small" />
                                <Typography fontSize="small" marginLeft={1}>Edit</Typography>
                              </Button>
                            </ButtonGroup>
                          </div>
                        </Tooltip>
                    }
                  </Box> }
              </Stack>
            </Collapse>
          }

        </Stack>
      </CardContent>
    </Card>
  );
}
