// import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import {Header} from '../../Header';
import {Outlet} from 'react-router-dom';
import {SideBarDrawer} from '../../components/SideBarDrawer';
import {SideBarNavLink} from '../../components/SideBarNavLink';
import {Divider, ListItem, Typography} from '@mui/material';
import {BaseLayout} from '../../components/BaseLayout';
import {AgentDocumentationSidebarSection} from '../../components/agents/AgentDocumentationSidebarSection';
import {useCurrentUser} from '../../contexts/UserProvider';


export const AgentBasePage = () => {
  const curUser = useCurrentUser();

  return (
    <BaseLayout
      childrenLeft={
        <SideBarDrawer>
          <Typography variant="h3">AI Agents</Typography>
          <Divider />

          <SideBarNavLink to="/agent" end>Agent Builder</SideBarNavLink>
          <SideBarNavLink to="/agent/search">Agents</SideBarNavLink>

          <Divider textAlign="left" sx={{marginTop: 3}}>
            <Typography
              variant="subtitle1"
              fontStyle="italic"
              fontSize="small"
              sx={{color: "info.dark"}}
            >
              Documentation Links
            </Typography>
          </Divider>

          <AgentDocumentationSidebarSection />
        </SideBarDrawer>
      }
    >
      <Outlet />
    </BaseLayout>
  );
}
