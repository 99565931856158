import {Context} from "vm";
import {AMPPlatUser} from "../contexts/UserProvider";
import {ContextEngineAgent} from "../context_engine_client";

/**
 * Extract the agent id from the specified path
 * E.g.
 *   /path/to/<AGENT_ID>/
 */
export const agentIdFromPath = (path: string): string | undefined => {
  const idRegex = "[a-f0-9]{8}(-[a-f0-9]{4}){3}-[a-f0-9]{12}";
  const pathRegex = new RegExp(`^/.*/(?<agentId>${idRegex})/`, "i");
  const agentIdMatch = window.location.pathname.match(pathRegex);
  if (!agentIdMatch || !agentIdMatch[1]) {
    return undefined;
  }
  return agentIdMatch?.groups?.agentId;
}

/**
 * Return if the provided user is an owner or co-owner of the provided
 * agent
 */
export const userOwnsAgent = (user: AMPPlatUser, agent: ContextEngineAgent): boolean => {
  if (user.email == agent.agent_info.created_by) {
    return true;
  }

  const coowners = agent.agent_info.coowners;
  if (coowners === undefined) {
    return false;
  }

  if (coowners.findIndex((owner) => owner === user.email) != -1) {
    return true;
  }

  return false;
}

export type SearchTextParseResult = {
  words: string[];
  taggedValues: {[key: string]: string};
};

export const parseSearchText = (text: string): SearchTextParseResult => {
  // words that aren't part of a tagged search
  let words: string[] = []
  let taggedValues: {[key: string]: string} = {};

  let curPos = 0;
  while (curPos < text.length) {
    const curText = text.substring(curPos);
    const taggedMatch = curText.match(
      /^\s*(?<tag>[a-zA-Z0-9_-]+):('(?<singleQuotedVal>[^']*)'|"(?<quotedVal>[^"]*)"|(?<unquotedVal>[^ ]*))/
    );

    if (taggedMatch && taggedMatch.groups && taggedMatch.index !== undefined) {
      const tag = taggedMatch.groups.tag;
      const val = taggedMatch.groups.quotedVal || taggedMatch.groups.unquotedVal || taggedMatch.groups.singleQuotedVal;
      taggedValues[tag] = val;

      // the first element in the match array is the total matched text
      curPos += taggedMatch[0].length;
    } else {
      const wordMatch = curText.match(/^\s*('(?<single>[^']+)'|"(?<quoted>[^"]+)"|(?<unquoted>[^\s]+))/);
      if (!wordMatch || !wordMatch.groups) { break; }
      const wordVal = wordMatch.groups.quoted || wordMatch.groups.single || wordMatch.groups.unquoted;
      words.push(wordVal);
      curPos += wordMatch[0].length;
    }
  }

  return {
    words,
    taggedValues,
  };
}

export type AgentSearchFilter = {
  searchString?: string;
  keyTerms?: string[];
  ownerish?: string;
  isPublic?: boolean;
  isDeployed?: boolean;
  isPublished?: boolean;
};

export const parseAgentSearchText = (text: string): AgentSearchFilter => {
  const info = parseSearchText(text);
  const vals = info.taggedValues;

  const res: AgentSearchFilter = {
    searchString: text,
    ownerish: vals.owner || vals.coowners,
    keyTerms: info.words,
  };

  if (vals.public !== undefined) {
    res.isPublic = !!(vals.public.toLowerCase() === "true");
  }
  if (vals.private !== undefined) {
    res.isPublic = !(vals.private.toLowerCase() === "true");
  }
  if (vals.deployed !== undefined) {
    res.isDeployed = !!(vals.deployed.toLowerCase() === "true");
  }
  if (vals.published !== undefined) {
    res.isPublished = !!(vals.published.toLowerCase() === "true");
  }

  return res;
};

const agentMatchesOwnerish = (agent: ContextEngineAgent, ownerish?: string): boolean => {
  if (ownerish === undefined) { return true; }

  const possibleOwners = [
    agent.agent_info.created_by,
    ...(agent.agent_info.coowners || [])
  ].join(", ").toLowerCase();

  if (possibleOwners.indexOf(ownerish) === -1) {
    return false;
  }

  return true;
}

const agentMatchesKeyTerms = (agent: ContextEngineAgent, keyTerms?: string[]): boolean => {
  if (keyTerms === undefined || keyTerms.length === 0) { return true; }

  const totalText = [
    agent.agent_info.agent_name,
    agent.agent_info.agent_description,
    agent.agent_info.created_by,
    agent.agent_id,
    ...(agent.agent_info.coowners || []),
  ].join("\n").toLowerCase();

  for (let term of keyTerms) {
    // ignore terms that are:
    //   * only a single quote
    //   * only a double quote
    // 
    // also remove leading quotes when there isn't a final quote
    if (term.match(/^['"]|(".*[^"])|('.*[^'])$/)) {
      term = term.substring(1);
    }
    if (totalText.indexOf(term.toLowerCase()) === -1) {
      return false;
    }
  }

  return true;
}

const agentMatchesState = (agent: ContextEngineAgent, filter: AgentSearchFilter): boolean => {
  if (filter.isPublic !== undefined && filter.isPublic !== agent.agent_info.public) {
    return false;
  }

  if (filter.isDeployed !== undefined && filter.isDeployed !== agent.deployment_info.is_deployed) {
    return false;
  }

  if (filter.isPublished !== undefined && filter.isPublished !== agent.deployment_info.is_published) {
    return false;
  }

  return true;
};

export const filterAgents = (agents: ContextEngineAgent[], filter: AgentSearchFilter): ContextEngineAgent[] => {
  return agents.filter((agent) => {
    return (
      agentMatchesOwnerish(agent, filter.ownerish)
      && agentMatchesKeyTerms(agent, filter.keyTerms)
      && agentMatchesState(agent, filter)
    );
  });
}
