import {createContext} from "react";
import {ContextEngineAgent} from "../context_engine_client";
import {userOwnsAgent} from "../utilities/agent";
import {AMPPlatUser, useCurrentUser} from "./UserProvider";

export type PermissionInfo = {
  can: boolean;
  reason?: string;
};

export type AgentPermissions = {
  instructions: {
    modify: PermissionInfo,
  },
  admin: {
    modify: PermissionInfo,
  },
  test: PermissionInfo,
};

export const AgentPermissionsContext = createContext<AgentPermissions>({
  instructions: {
    modify: {can: false}
  },
  admin: {
    modify: {can: false},
  },
  test: {can: false},
});
export const AgentPermissionsProvider = AgentPermissionsContext.Provider;

const setAdminPerms = (agent: ContextEngineAgent, user: AMPPlatUser, perms: AgentPermissions) => {
  if (userOwnsAgent(user, agent)) {
    perms.admin.modify.can = true;
    perms.admin.modify.reason = "You are an owner of this agent";
  } else {
    perms.admin.modify.can = false;
    perms.admin.modify.reason = "You are not an owner of this agent";
  }
}

/**
 * Set the instruction-specific permissions for the provided user and agent.
 *
 * NOTE: This *MUST* be called *after* the admin permissions have been determined.
 */
const setInstructionPerms = (agent: ContextEngineAgent, user: AMPPlatUser, perms: AgentPermissions) => {
  if (!perms.admin.modify.can) {
    perms.instructions.modify.can = false;
    perms.instructions.modify.reason = perms.admin.modify.reason;
    return;
  }

  if (agent.deployment_info.is_deployed || agent.deployment_info.is_published) {
    perms.instructions.modify.can = false;
    perms.instructions.modify.reason = "Cannot modify agent while it is deployed or published";
    return;
  }

  perms.instructions.modify.can = true;
  perms.instructions.modify.reason = perms.admin.modify.reason;
}

/**
 * Set the testing-specific permissions for this user and agent.
 *
 * NOTE: This *MUST* be called *after* the admin permissions have been determined
 */
const setTestPerms = (agent: ContextEngineAgent, user: AMPPlatUser, perms: AgentPermissions) => {
  if (perms.admin.modify.can) {
    if (agent.deployment_info.is_deployed) {
      perms.test.can = true;
      perms.test.reason = "You are an owner of the agent and it has been deployed";
    } else {
      perms.test.can = false;
      perms.test.reason = "Cannot test agents you own that have not been deployed";
    }
    return;
  }

  if (!agent.deployment_info.is_published) {
    perms.test.can = false;
    perms.test.reason = "Cannot test someone else's agent that hasn't been published";
    return;
  } else {
    perms.test.can = true;
    perms.test.reason = "Third-party agents can be tested if they are published";
  }
}

export const createAgentPerms = (
  agent: null | ContextEngineAgent,
  user: null | AMPPlatUser
): AgentPermissions => {
  const perms: AgentPermissions = {
    instructions: {
      modify: {can: false}
    },
    admin: {
      modify: {can: false}
    },
    test: {can: false},
  };

  if (!agent || !user) {
    return perms;
  }

  setAdminPerms(agent, user, perms);
  setInstructionPerms(agent, user, perms);
  setTestPerms(agent, user, perms);

  return perms;
}
