import {Typography} from '@mui/material';
import {ContextDocumentation} from '../../components/agents/ContextDocumentation';
import {Title} from '../../components/Title';

export type AgentContextsProps = {
};

export const AgentContexts = ({}: AgentContextsProps) => {
  return (
    <>
      <Title value="Context Reference" />

      <Typography paragraph>
        View reference documentation and examples about available contexts.
      </Typography>

      <ContextDocumentation />
    </>
  );
}
