import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {AgentListStandalone} from "../../components/agents/AgentListStandalone";
import {AgentSearchBar} from "../../components/agents/AgentSearchBar";
import {Title} from "../../components/Title";
import {useCurrentUser} from "../../contexts/UserProvider";
import {AgentSearchFilter} from "../../utilities/agent";


const getQParam = (): string => {
  let searchString = window.location.hash || "";
  if (searchString.indexOf("#") == 0) {
    searchString = searchString.substring(1);
  }
  const params = new URLSearchParams(searchString);

  return params.get("q") || "";
}

export const AgentSearchPage = () => {
  const navigate = useNavigate();
  const curUser = useCurrentUser();

  const [agentFilter, setAgentFilter] = useState<AgentSearchFilter>({});

  const initText = getQParam();

  return (
    <>
      <Title value="Agents" />

      <AgentSearchBar
        initText={initText}
        onChange={(newFilter) => {
          setAgentFilter({...newFilter});
          window.location.hash = "q=" + newFilter.searchString;
        }}
        commonLinks={[
          {label: "My Agents", link: `#q=owner:${curUser.curUser?.email}`},
          {label: "Deployed Agents", link: `#q=deployed:true` },
          {label: "Published Agents", link: `#q=published:true` },
        ]}
      />

      <AgentListStandalone
        showNewButton={true}
        agentsClickable={true}
        onAgentSelected={(agentEvent) => {
          navigate(`../${agentEvent.agent.agent_id}/`);
        }}
        filter={agentFilter}
      />
    </>
  );
};
