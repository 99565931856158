import {createTheme, darken} from '@mui/material/styles';
import {
  lightBlue as main,
  cyan as secondary,
  grey,
  common,
} from '@mui/material/colors';
import { accordionOverrides, paperOverrides, tableOverrides } from './overrides';

const defaultTheme = createTheme();

const typography = {
  h1: {
    fontSize: defaultTheme.typography.pxToRem(36),
    fontWeight: 600,
    lineHeight: 1.5,
    margin: "1rem 0"
  },
  h2: {
    fontSize: defaultTheme.typography.pxToRem(24),
    fontWeight: 600,
    lineHeight: 1.25,
    margin: "1rem 0"
  },
  h3: {
    fontSize: defaultTheme.typography.pxToRem(20),
    fontWeight: 500,
    lineHeight: 1.125,
    margin: "1rem 0"
  },
  h4: {
    fontSize: defaultTheme.typography.pxToRem(16),
    fontWeight: 500,
    margin: "1rem 0"
  },
  h5: {
    fontSize: defaultTheme.typography.pxToRem(16),
    fontWeight: 600,
    margin: "1rem 0"
  },
  h6: {
    fontSize: defaultTheme.typography.pxToRem(16),
    fontWeight: 400,
    margin: "1rem 0"
  },

  /**
  * This is a custom variant for which we are also declaring TypographyVariant
  * typing
  */
  inlineCode: {
    fontSize: defaultTheme.typography.pxToRem(14),
    fontFamily: "source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace",
  },
}

declare module '@mui/material/styles' {
    interface TypographyVariants {
        inlineCode: React.CSSProperties;
    }

    interface TypographyVariantOptions {
        inlineCode?: React.CSSProperties;
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
      inlineCode: true;
    }
}


export const blueTheme = createTheme({
  typography,
  palette: {
    secondary: {
      main: secondary[400],
      contrastText: common.white,
    },
    primary: {
      main: darken(main[900], 0.5),
      contrastText: common.white,
    },
    info: {
      main: grey[200],
      dark: grey[400],
      light: grey[50],
    },
    
    contrastThreshold: 2.5,
    mode: 'light',
  },
  shape: {
    borderRadius: 2,
  },
  components: {
    ...accordionOverrides,
    ...paperOverrides,
    ...tableOverrides,
  },
});
