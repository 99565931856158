import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import CopyToClipboard from "react-copy-to-clipboard";
import { IoIosCopy } from "react-icons/io";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import remarkSlug from "remark-slug";
import remarkToc from "remark-toc";
import remarkEmoji from 'remark-emoji';

import { getLoginUrl, getGoogleLoginUrl, getSlackLoginUrl } from "../utilities";
import { ReactNode, useEffect, useState } from "react";
import { HeadingProps } from "react-markdown/lib/ast-to-react";
import { Link } from "react-router-dom";
import { CopyTokenToClipboard } from "./CopyTokenToClipboard";
import { ConnectToDropbox, ConnectToGoogle, ConnectToSlack, ConnectToZoom } from "./Connectors";

export const MarkdownLoader = ({ contentUrl }: { contentUrl: string }) => {
    const [content, setContent] = useState('');

    useEffect(() => {
        fetch(contentUrl).then(rest => rest.text()).then(text => setContent(text))
    }, [contentUrl]);

    return <Markdown content={content} />
};

const TablePaper = ({children, ...props}: any) => <Paper elevation={3} {...props}>{children}</Paper>

function MarkdownTable(props: { children: ReactNode }) {
    return (
        <TableContainer component={TablePaper}>
            <Table size="small">{props.children}</Table>
        </TableContainer>
    );
}

function MarkdownTableCell(props: { children: ReactNode }) {
    return <TableCell><Typography>{props.children}</Typography></TableCell>
}

function MarkdownTableRow(props: { children: ReactNode }) {
    return <TableRow>{props.children}</TableRow>
}

function MarkdownTableBody(props: { children: ReactNode }) {
    return <TableBody>{props.children}</TableBody>
}

function MarkdownTableHead(props: { children: ReactNode }) {
    return <TableHead>{props.children}</TableHead>
}

function MarkdownTableHeadCell(props: { children: ReactNode }) {
    return <TableCell component="th"><Typography fontWeight="bold">{props.children}</Typography></TableCell>
}

const MarkdownHeading = ({children, id, ...props}: { children: ReactNode } & HeadingProps) => {
    const variant: any = `h${props.level}`;
    return <Typography variant={variant} id={id}>{children}</Typography>
};

const CodeSwitcher = ({children, ...props}: any) => {
    const languages: string[] = props["dbx-code-switcher-languages"].split(',')
    const highlighers = languages.map((language, i) => {
        return <SyntaxHighlighter language={language}>{children[i]}</SyntaxHighlighter>
    });
    return <div>
        Look at all of these!!!
        { highlighers }
    </div>  
    
};

const ConnectDatasource = (props: any) => {
    const type: string = props["dbx-type"];
    switch(type) {
        case "google":
            return <ConnectToGoogle />;
        case "slack":
            return <ConnectToSlack />;
        case "dropbox":
            return <ConnectToDropbox />;
        case "zoom":
            return <ConnectToZoom />;
        default:
            return <></>
    }
}

// a mapping of `dbx-custom-component` values to a custom rendered component
const customDivComponents: { [key: string]: any }= {
    'copy-token': CopyTokenToClipboard,
    'code-switcher': CodeSwitcher,
    'connect-datasource': ConnectDatasource,
}



export const Markdown = ({ content }: { content: string }) => {
    return (<ReactMarkdown
        rehypePlugins={[rehypeRaw]}
        remarkPlugins={[remarkGfm, remarkEmoji, remarkToc, remarkSlug]}


        components={{
            h1: MarkdownHeading,
            h2: MarkdownHeading,
            h3: MarkdownHeading,
            h4: MarkdownHeading,
            h5: MarkdownHeading,
            h6: MarkdownHeading,
            table: MarkdownTable,
            thead: MarkdownTableHead,
            tbody: MarkdownTableBody,
            tr: MarkdownTableRow,
            td: MarkdownTableCell,
            th: MarkdownTableHeadCell,
            img: ({ node, ...props }) => {
                return (
                    <img
                        src={props.src}
                        alt={props.alt}
                        style={{ width: '1000px' }}
                    />
                );
            },
            a: ({ node, className, children, ...props }) => {
                // the default link in the Markdown document points to the prod URL
                // if the frontend is running on a dev machine, it should change to the local URL
                if (props.href?.includes('https://api.dbxos.com/v3/auth')) {
                    if (children.toString().includes('Sign in with Dropbox')) {
                        props.href = getLoginUrl();
                    } else if (children.toString().includes('Sign in with Google')) {
                        props.href = getGoogleLoginUrl();
                    } else if (children.toString().includes('Sign in with Slack')) {
                        props.href = getSlackLoginUrl();
                    }
                    return (
                        <Button variant='contained' href={props.href}>{children}</Button>
                    );
                } else if (props.href?.includes('capture.dropbox.com/embed')) {
                    // Markdown doesn't support embedding video natively, so we create an anchor element in Markdown
                    // and convert the anchor to an iFrame here
                    return (
                        <div>
                            {props.title && <div>{props.title}</div>}
                            <iframe title={props.title} src={props.href} width="560" height="315" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                    );
                } else if (props.href?.startsWith('/') && !props.href?.startsWith('/assets/')) { // allow react router to handle nav links
                    return <Link to={props.href}>{children}</Link>
                }
                return (
                    <a href={props.href}>{children}</a>
                );
            },
            div: (props) => {
                // using `<div dbx-custom-component="component-type-name"></div>` to create custom properties
                const customComponentType = (props as any)["dbx-custom-component"];
                const Component = customDivComponents[customComponentType];
                if(Component) {
                    return <Component {...props} />;
                }

                return <div {...props}></div>;
            },
            code({ node, inline, className, children, ...props }) {
                const match = /language-(\w+)/.exec(className || '')
                return !inline && match ? (
                    <div className='relative'>
                        <button className="absolute flex flex-row  top-0 right-0 p-2">
                            <CopyToClipboard
                                text={match[1] === 'bash' ? String(children).substring(1).replace(/\n$/, '') : String(children).replace(/\n$/, '')}
                            >
                                <span className='m-1 pb-1 basis-3/4 text-xs'>Copy <IoIosCopy className="text-lg m-1 basis-1/4 hover:text-white" /></span>
                            </CopyToClipboard>
                        </button>
                        <SyntaxHighlighter
                            {...props}
                            children={String(children).replace(/\n$/, '')}
                            style={darcula}
                            language={match[1]}
                            showLineNumbers={match[1] === 'bash' ? false : true}
                            PreTag="div"
                        />
                    </div>
                ) : (
                    <code {...props} className={className}>
                        {children}
                    </code>
                )
            }
        }}
    >
        {
            content
        }
    </ReactMarkdown>)
}
