import {Box, Button, Collapse, Drawer, Paper, TextField, Typography, useTheme} from "@mui/material";
import {forwardRef, HTMLProps, PropsWithoutRef, useRef, useState} from "react";
import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/default-highlight";
import { github } from "react-syntax-highlighter/dist/esm/styles/hljs";
import {ContextDocumentation} from "./ContextDocumentation";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CloseIcon from '@mui/icons-material/Close';

export type ContextHelpDrawerProps = {
};

export const ContextHelpDrawer = ({}: ContextHelpDrawerProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const theme = useTheme();

  return (
    <Box>
      <Button
        size="small"
        onClick={() => setIsExpanded(!isExpanded)}
        color={isExpanded ? "error" : undefined }
        variant={isExpanded ? "outlined" : "text" }
        sx={{
          borderTop: isExpanded ? "none" : undefined,
        }}
      >
        { !isExpanded && <HelpOutlineIcon fontSize="small" color="warning" /> }
        { isExpanded && <CloseIcon fontSize="small"/> }
        <Typography marginLeft={1} fontSize="small">Context Reference</Typography>
      </Button>

      <Collapse
        easing={theme.transitions.easing.easeIn}
        in={isExpanded}
      >
        <ContextDocumentation />
      </Collapse>
    </Box>
  );
}

export type InstructionsEditorProps = {
  instructions: string;
  onChange: (newValue: string) => void,
  editable?: boolean;
};

export const InstructionsEditor = ({instructions, onChange, editable=false}: InstructionsEditorProps) => {
  return (
    editable
    ? <Box
        sx={{
          width: 1.0,
        }}
      >
        <TextField
          multiline
          label="Instructions"
          minRows={instructions.split("\n").length}
          value={instructions}
          onChange={(event) => onChange(event.target.value)}
          fullWidth={true}
          inputProps={{
            style: {
              fontFamily: "monospace",
              fontSize: "smaller",
              whiteSpace: "nowrap",
            },
            spellCheck: false,
          }}
        />
        <ContextHelpDrawer />
      </Box>
    : <Paper
        variant="elevation"
        sx={{ margin: 0 }}
        elevation={editable ? 0 : 1}
      >
        <SyntaxHighlighter
          language="python"
          style={github}
          customStyle={{margin: 0, fontSize: "0.9rem"}}
          wrapLines={false}
        >
          {instructions}
        </SyntaxHighlighter>
      </Paper>
  );
}
