import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { MarkdownLoader } from '../../components/Markdown';
import CallingOpenAIDoc from '../../docs/sections/openai.md';
import { OpenaiService } from '../../ampplat_client';

export const OpenAIPage = () => {
    return (
        <div>
            <BudgetSection />
            <MarkdownLoader contentUrl={CallingOpenAIDoc} />
        </div>
    );
};

const BudgetSection = () => {
    const [budget, setBudget] = useState<any>(null);
    useEffect(() => {
        OpenaiService.openaiGetMyBudget().then(setBudget);
    }, []);

    if(!budget) {
        return <></>;
    }

    return (
        <Card variant="outlined">
            <CardContent>
                <Typography variant='h1'>Your OpenAI monthly budget</Typography>
                <Typography>You've used ${budget.current_monthly_spend.toFixed(2)} of your ${budget.monthly_budget.toFixed(2)} monthly budget.</Typography>
            </CardContent>
        </Card>
    )
}
