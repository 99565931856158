import {Accordion, AccordionSummary, Box, Button, Collapse, darken, List, ListItem, ListItemButton, ListItemText, MenuItem, Paper, Select, Stack, Typography} from "@mui/material";
import {Fragment, PropsWithChildren, useEffect, useState} from 'react';
import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/default-highlight";
import {getContextEngineAgentHelperService} from "../../utilities";
import {github} from "react-syntax-highlighter/dist/esm/styles/hljs";
import {useSnackbar} from "./SnackMessage";
import {ContextEngineContextDetails, FunctionDetails} from "../../context_engine_client/services/HelperService";
import {GridExpandMoreIcon} from "@mui/x-data-grid";
import {ExpandLess, ExpandMore} from "@mui/icons-material";

export type ContextDocumentationProps = PropsWithChildren & {
};

const CodeSnippet = (props: {tool: string}) => {
  return (
    <SyntaxHighlighter
      language="python"
      style={github}
      customStyle={{margin: 0, fontSize: "0.9rem"}}
      wrapLines={false}
    >
      {props.tool}
    </SyntaxHighlighter>
  );
};

const ListTypes = ({ selectedContext }: { selectedContext: string }) => {
  const [types, setTypes] = useState([] as string[]);

  useEffect(() => {
    if (selectedContext === "") {
      setTypes([] as string[]);
      return;
    }
    const helperService = getContextEngineAgentHelperService();
    helperService.getTypesInContext(selectedContext).then((types) => {
      setTypes(types);
    });
  }, [selectedContext]);

  return (
    <List>
      <Typography variant="h3">Types</Typography>
      <Box
        sx={{
          p: 2,
          border: "1px solid #ccc",
          borderRadius: "5px",
          backgroundColor: "#eee",
        }}
      >
        {types &&
          types.map((type) => (
            <ListItem key={type} disablePadding>
              <ListItemButton>
                <ListItemText
                  primary={
                    <CodeSnippet tool={type} />
                  }
                />
              </ListItemButton>
            </ListItem>
          ))}
      </Box>
    </List>
  );
};

export type ContextToolProps = {
  contextName: string;
  func: FunctionDetails;
};

export const ContextTool = ({contextName, func}: ContextToolProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const paramsStr = func.params.map(
    (param) => param.name + (param.type ? ":" + param.type : "")
  ).join(", ");
  const funcSignature = `${func.name}(${paramsStr})`;

  return (
    <Paper
      variant="outlined"
    >
      <Stack
        sx={{
          width: 1.0,
          backgroundColor: isExpanded ? "info.main" : "info.light",
        }}
      >
        <Button
          variant="text"
          sx={{
            textTransform: "none",
            alignSelf: "start",
            overflow: "clip",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            maxWidth: 1.0,
            padding: 0.5,
          }}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          { isExpanded 
            ? <ExpandLess fontSize="small"/>
            : <ExpandMore fontSize="small"/>
          }

          <SyntaxHighlighter
            language="python"
            style={github}
            customStyle={{
              margin: 0,
            }}
            wrapLongLines={false}
          >
            {funcSignature}
          </SyntaxHighlighter>
        </Button>

        <Collapse
          in={isExpanded}
        >
          <Stack
            padding={2}
          >
            <Typography>{func.doc || "..."}</Typography>
          </Stack>
        </Collapse>
      </Stack>
    </Paper>
  );
}

const ListTools = ({ selectedContext }: { selectedContext: string }) => {
  const [tools, setTools] = useState([] as string[]);

  useEffect(() => {
    if (selectedContext === "") {
      setTools([] as string[]);
      return;
    }

    const helperService = getContextEngineAgentHelperService();
    helperService.getToolsInContext(selectedContext).then((tools) => {
      setTools(tools);
    });
  }, [selectedContext]);

  return (
    <List>
      <Typography variant="h3">Tools</Typography>
      <Box
        sx={{
          p: 2,
          border: "1px solid #ccc",
          borderRadius: "5px",
          backgroundColor: "#eee",
          minWidth: "800px",
          maxWidth: "1200px",
        }}
      >
        {tools &&
          tools.map((tool) => (
            <ListItem key={tool} disablePadding>
              <ListItemButton>
                <ListItemText
                  primary={
                    <CodeSnippet tool={tool} />
                  }
                />
              </ListItemButton>
            </ListItem>
          ))}
      </Box>
    </List>
  );
};

const ListContexts = ({
  handleContextChange,
}: {
  handleContextChange: (context: string) => void;
}) => {
  const [contexts, setContexts] = useState([] as string[]);

  const getContexts = async () => {
    const helperService = getContextEngineAgentHelperService();
    const contexts = await helperService.getBuiltInContexts();
    setContexts(contexts);
  };

  useEffect(() => {
    getContexts();
  }, []);

  return (
    <List>
      <Typography variant="h3">Built-in Contexts</Typography>
      <Box
        sx={{
          p: 2,
          border: "1px solid #ccc",
          borderRadius: "5px",
          backgroundColor: "#eee",
        }}
      >
        {contexts &&
          contexts.map((context) => (
            <ListItem key={context} disablePadding>
              <ListItemButton>
                <ListItemText
                  primary={context}
                  onClick={() => handleContextChange(context)}
                />
              </ListItemButton>
            </ListItem>
          ))}
      </Box>
    </List>
  );
};

export const ContextDocumentation = ({}: ContextDocumentationProps) => {
  const [contexts, setContexts] = useState<string[] | null>(null);
  const [contextName, setContextName] = useState("");

  const handleContextChange = (context: string) => {
    console.log("Selected Context changed: " + context);
    setContextName(context);
  };

  const snackbar = useSnackbar();

  useEffect(() => {
    const platformService = getContextEngineAgentHelperService();
    platformService
      .getBuiltInContexts()
      .then((fetchedContexts) => {
        setContexts(fetchedContexts);
      })
      .catch((error) => {
        console.error("Could not fetch contexts", error);
        snackbar?.error("Could not fetch contexts");
      });
  }, []);

  return (
    <Stack direction="column" spacing={2} padding={2}>
      { contexts
        ? <Select
            fullWidth={false}
            size="small"
            onChange={(event) => setContextName(event.target.value as string)}
            value={contextName}
            label="Choose a context"
            sx={{
              alignSelf: "start"
            }}
            displayEmpty
          >
            <MenuItem value="">Choose a context</MenuItem>
            {contexts.map((context) =>
              <MenuItem key={context} value={context}>{context}</MenuItem>
            )}
          </Select>
        : <></>
      }

      <ListTools selectedContext={contextName} />
      <ListTypes selectedContext={contextName} />
    </Stack>
  );
}
