/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CubeAuthRequest } from '../models/CubeAuthRequest';
import type { DropboxUserResponse } from '../models/DropboxUserResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthService {

    /**
     * Authroot
     * @returns any Successful Response
     * @throws ApiError
     */
    public static authroot(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/auth/',
        });
    }

    /**
     * Auth Redirect
     * @returns any Successful Response
     * @throws ApiError
     */
    public static authRedirect(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/auth/auth_redirect',
        });
    }

    /**
     * Login
     * Primary login endpoint for Dropbox auth. To log in, visit this endpoint in your browser.
     * @param redirectUri
     * @param sync If true, enables automatic paper sync
     * @returns any Successful Response
     * @throws ApiError
     */
    public static login(
        redirectUri?: string,
        sync: boolean = false,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/auth/login',
            query: {
                'redirect_uri': redirectUri,
                'sync': sync,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get command and token to add to slack bot
     * @returns any Successful Response
     * @throws ApiError
     */
    public static addToSlack(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/auth/add_to_slack',
        });
    }

    /**
     * Logout Endpoint
     * @param postLogoutRedirectUri
     * @returns any Successful Response
     * @throws ApiError
     */
    public static routeLogoutAndRemoveCookie(
        postLogoutRedirectUri?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/auth/logout',
            query: {
                'post_logout_redirect_uri': postLogoutRedirectUri,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Cube Auth Endpoint
     * @param requestBody
     * @returns string Successful Response
     * @throws ApiError
     */
    public static cubeAuth(
        requestBody: CubeAuthRequest,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v3/auth/cube_auth',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Current User Endpoint
     * @returns DropboxUserResponse Successful Response
     * @throws ApiError
     */
    public static readUsersMe(): CancelablePromise<DropboxUserResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/auth/users/me',
        });
    }

    /**
     * Read Users Google Me
     * @returns any Successful Response
     * @throws ApiError
     */
    public static readUsersGoogleMe(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/auth/users/google/me',
        });
    }

    /**
     * Read Users Slack Me
     * @returns any Successful Response
     * @throws ApiError
     */
    public static readUsersSlackMe(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/auth/users/slack/me',
        });
    }

    /**
     * Google Login Endpoint
     * @param postLoginRedirectUri
     * @returns any Successful Response
     * @throws ApiError
     */
    public static googleLogin(
        postLoginRedirectUri?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/auth/google/login',
            query: {
                'post_login_redirect_uri': postLoginRedirectUri,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Google Logout Endpoint
     * @returns any Successful Response
     * @throws ApiError
     */
    public static googleLogout(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v3/auth/google/logout',
        });
    }

    /**
     * Authorize Google
     * @returns any Successful Response
     * @throws ApiError
     */
    public static authorizeGoogle(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/auth/google/callback',
        });
    }

    /**
     * Slack Login Endpoint
     * @param postLoginRedirectUri
     * @returns any Successful Response
     * @throws ApiError
     */
    public static slackLogin(
        postLoginRedirectUri?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/auth/slack/login',
            query: {
                'post_login_redirect_uri': postLoginRedirectUri,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Slack Logout Endpoint
     * @returns any Successful Response
     * @throws ApiError
     */
    public static slackLogout(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v3/auth/slack/logout',
        });
    }

    /**
     * Slack Auth Redirect
     * @param code
     * @returns any Successful Response
     * @throws ApiError
     */
    public static slackAuthRedirect(
        code: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/auth/slack_auth_redirect',
            query: {
                'code': code,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get DBXOS Token Endpoint
     * Gets token for use with DBXOS API
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getToken(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/auth/dbxos/token',
        });
    }

    /**
     * Auth Get Dropbox Token
     * Gets token for use with Dropbox API associated with the DBXOS app client
     * @returns string Successful Response
     * @throws ApiError
     */
    public static authGetDropboxToken(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/auth/dropbox/token',
        });
    }

    /**
     * Delete All Content
     * Deletes all stored content for the user and any linked accounts.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteAllContent(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v3/auth/delete_all_content',
        });
    }

}
