import {Box, Button, Card, CardContent, Divider, Link, Paper, Stack, Typography} from "@mui/material";
import { getLoginUrl,getPrivacyPolicyUrl } from "./utilities";
import { Replay } from "@mui/icons-material";
import { ConnectToDropbox, ConnectToGoogle, ConnectToSlack, ConnectToZoom } from "./components/Connectors";
import {BaseLayout} from "./components/BaseLayout";
import { useCurrentUser } from "./contexts/UserProvider";

export const LoginPage = () => {
  const { curUser } = useCurrentUser();
  const loginUrl = getLoginUrl();
  const isUserLoggedInDBX = () => {
    if (!curUser) {
      return (
        <Link href={loginUrl}>
          <Button variant="contained" size="small" color="secondary">
            Login with Dropbox
          </Button>
        </Link>
      );
    } else {
      return (
        <ConnectToDropbox></ConnectToDropbox>
      );
    }
  };
  

  const loggedInUserBackButton = () => {
    if (!curUser) {
      return <></>
    } else {
      return (
        <Box sx={{pt:5}}>
          <Link href={"/"}>
            <Button variant="contained" size="medium" color="primary">
              <Replay />&nbsp;&nbsp;Go Back
            </Button>
          </Link>
        </Box>
      )
    };
  };

  
  return (
    <BaseLayout>
      <Box sx={{ minWidth: 400, display:'flex', flexDirection: "column", alignItems: "center"}}>
        <Card variant="outlined">
          <CardContent>
            <Stack spacing={2} sx={{ display:'flex', flexDirection: "column", alignItems: "center"}}>
              <Box>{isUserLoggedInDBX()}</Box>
              <Divider/>
              {curUser ? (<Box><ConnectToGoogle /></Box>) : <></> }
              {curUser ? (<Box><ConnectToSlack /></Box>) : <></> }
              {curUser ? (<Box><ConnectToZoom /></Box>) : <></> }
              <Link href={getPrivacyPolicyUrl()} target="_blank" rel="noopener" color="inherit">FAQ & Privacy Policy</Link>
            </Stack>
          </CardContent>
        </Card>
        {loggedInUserBackButton()}
      </Box>
    </BaseLayout>
  )
}
