import {ListItem, ListItemButton, useTheme} from "@mui/material";
import {useContext, useEffect} from "react";
import {matchPath, NavLink} from "react-router-dom";
import {NavSectionContext} from "./SideBarNavSection";

export const SideBarNavLink = ({to, children, end}: {to: string, children: string, end?: boolean}) => {
  const sectionInfo = useContext(NavSectionContext);
  const theme = useTheme();

  useEffect(() => {
    const isActive = !!matchPath(to, window.location.pathname + "?" + window.location.search);
    console.log("path: ", window.location.pathname, " isactive: ", isActive);
    if (isActive) {
      sectionInfo?.setIsActive(true);
    }
  }, []);

  return (
    <ListItem key={to} disablePadding>
      <NavLink
        to={to}
        end={end}
        style={({isActive}) => {
          const color = isActive ? theme.palette.getContrastText(theme.palette.info.dark) : 'inherit';
          const backgroundColor = isActive ? theme.palette.info.dark : 'inherit';
          return {
            display: "inline-flex",
            flexGrow: 1,
            textDecoration: 'none',
            backgroundColor,
            color
          };
        }}
      >
        <ListItemButton>
          {children}
        </ListItemButton>
      </NavLink>
    </ListItem>
  );
}
