/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UserSyncSubscription = {
    id: string;
    user_id: string;
    sync_type: UserSyncSubscription.sync_type;
    active: boolean;
    last_sync_ts?: string;
    next_sync_ts?: string;
};

export namespace UserSyncSubscription {

    export enum sync_type {
        PAPER = 'paper',
        MEETING = 'meeting',
        SLACK = 'slack',
        GCAL = 'gcal',
    }


}

