import "./App.css";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { RouterProvider } from "react-router-dom";
import { router } from "./routes";
import { blueTheme } from "./theme";
import { ProvideUser } from './contexts/UserProvider';

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

function App() {

  return (
    <ThemeProvider theme={blueTheme}>
      <CssBaseline />
      <div className="App">
        <ProvideUser>
          <div>
            <RouterProvider router={router}/>
          </div>
        </ProvideUser>
      </div>
    </ThemeProvider>
  );
}

export default App;
