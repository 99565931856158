// import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import {Header} from './Header';
import {Outlet} from 'react-router-dom';
import {SideBarNavLink} from './components/SideBarNavLink';
import {SideBarDrawer} from './components/SideBarDrawer';
import {SideBarNavSection} from './components/SideBarNavSection';
import {AgentDocumentationSidebarSection} from './components/agents/AgentDocumentationSidebarSection';
import {Divider, Typography} from '@mui/material';

export const DocumentationPage = () => {

  return (
    <>
      <Box sx={{display: 'flex'}}>
        <Header />
        <SideBarDrawer>
          <Typography variant="h3">Documentation</Typography>
          <Divider />

          <SideBarNavLink to="overview" >Overview</SideBarNavLink>
          <SideBarNavLink to="getting-started" >Getting Started</SideBarNavLink>

          <SideBarNavSection label="Agent Builder">
            <AgentDocumentationSidebarSection />
          </SideBarNavSection>

          <SideBarNavLink to="python-sdk">Python SDK</SideBarNavLink>
          <SideBarNavLink to="nodejs-sdk">NodeJS SDK</SideBarNavLink>
          <SideBarNavLink to="data-sources">Data Sources</SideBarNavLink>
          <SideBarNavLink to="ml-capabilities">ML Capabilities</SideBarNavLink>
          <SideBarNavLink to="openai">OpenAI</SideBarNavLink>
          <SideBarNavLink to="jupyterhub">Jupyterhub</SideBarNavLink>
          <SideBarNavLink to="slack-app">Slack Apps</SideBarNavLink>
          <SideBarNavLink to="faqs">FAQS</SideBarNavLink>
        </SideBarDrawer>
        <Box component="main" sx={{flexGrow: 1, p: 5}}>
          <Toolbar />
          <Outlet />
        </Box>
      </Box>
    </>
  );
}
