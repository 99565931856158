import {Box, Button, ButtonGroup, darken, Dialog, DialogContent, DialogTitle, Paper, Slide, Stack, Typography} from "@mui/material";
import {TransitionProps} from "@mui/material/transitions";
import {forwardRef} from "react";
import {ContextEngineAgent} from "../../context_engine_client";
import {AgentTester} from "./AgentTester";

export type AgentTestDialogProps = {
  agent: ContextEngineAgent;
  open: boolean;
  onClose: () => void;
};

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export const AgentTestDialog = ({agent, open, onClose}: AgentTestDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth
      TransitionComponent={Transition}
    >
      <DialogTitle
        sx={{
          backgroundColor: "info.light",
          marginTop: 0,
          paddingY: 4,
          borderBottomWidth: 1,
          borderBottomStyle: "solid",
          borderBottomColor: "info.main",
        }}
      >
        <Typography
          fontWeight="bold"
          fontSize="larger"
        >
          Testing {agent.agent_info.agent_name}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Stack paddingTop={2} gap={2} useFlexGap>
          <AgentTester
            agentId={agent.agent_id}
          />

          <ButtonGroup sx={{
            justifyContent: "end"
          }}>
            <Button onClick={() => onClose()} color="warning">
              Close
            </Button>
          </ButtonGroup>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
