/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum SyncStage {
    STARTED = 'Started',
    IN_PROGRESS = 'InProgress',
    COMPLETED = 'Completed',
    ERROR = 'Error',
}
