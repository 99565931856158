import { PropsWithChildren } from "react";
import { ButtonProps, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import CopyToClipboard from "react-copy-to-clipboard";
import { IoIosCopy as CopyIcon } from 'react-icons/io';
import { useCurrentUser } from "../contexts/UserProvider";


const CopyTokenButton = styled(Button)<ButtonProps>(({ theme }) => ({
    minWidth: '155px'
}));


export const CopyTokenToClipboard = ({children}: PropsWithChildren) => {
    const { curUser } = useCurrentUser();

    if (!curUser?.token) {
        return <></>;
    }

    return (
        <CopyToClipboard text={curUser.token || ''}>
            <CopyTokenButton color="secondary" variant="contained" startIcon={<CopyIcon />}>
                {children || 'Copy Token'}
            </CopyTokenButton>
        </CopyToClipboard>
    );
};