import { useEffect, useState } from "react";
import { getAuthService, getSyncService, getGoogleLoginUrl, getLoginUrl, getSlackLoginUrl, getZoomLoginUrl, getZoomService } from "../utilities";
import { Button, Link, Stack, Typography } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import { green } from "@mui/material/colors";
import { DropboxUserResponse, ZoomUserModel } from "../ampplat_client";
import { useCurrentUser } from "../contexts/UserProvider";
const loggedInGreen = green[500];

export const ConnectToGoogle = () => {
    const { curUser } = useCurrentUser();
    const [googleUser, setGoogleUser] = useState(null);
    const [syncLoading, setSyncLoading] = useState(false);
    const [synced, setSynced] = useState(false);
    const loginUrl = getGoogleLoginUrl();

    useEffect(() => {
        getAuthService().readUsersGoogleMe().then(setGoogleUser);
    }, [curUser]);

    const syncGoogle = async () => {
        try {
            setSyncLoading(true);
            await getSyncService().startGcalUserSync();
            setSynced(true);
        } finally {
            setSyncLoading(false);
        }
    };

    let syncText = "Sync GCal";
    if (syncLoading) {
        syncText = "Syncing..."
    }
    if (synced) {
        syncText = "Sync running!"
    }

    if (googleUser) {
        return (
            <Stack direction="row" spacing="8">
                <Typography color={loggedInGreen}>
                    <CheckCircle />
                </Typography>&nbsp;&nbsp;
                <Typography>
                    Connected to Google <Link href={loginUrl}>
                        <Button variant="outlined" size="small" color="secondary">
                            Reconnect
                        </Button>
                    </Link>
                    <Button variant="outlined" size="small" color="primary" sx={{ "marginLeft": 1 }} onClick={syncGoogle} disabled={syncLoading || synced}>
                        {syncText}
                    </Button>
                </Typography>

            </Stack>
        )
    } else {
        return (
            <Link href={loginUrl}>
                <Button variant="contained" size="small" color="secondary">
                    Connect to Google
                </Button>
            </Link>
        )
    }
}

export const ConnectToSlack = () => {
    const { curUser } = useCurrentUser();
    const [slackUser, setSlackUser] = useState(null);
    const [syncLoading, setSyncLoading] = useState(false);
    const [synced, setSynced] = useState(false);
    const loginUrl = getSlackLoginUrl();

    useEffect(() => {
        getAuthService().readUsersSlackMe().then(setSlackUser);
    }, [curUser]);

    const syncSlack = async () => {
        try {
            setSyncLoading(true);
            await getSyncService().startSlackUserSync();
            setSynced(true);
        } finally {
            setSyncLoading(false);
        }
    };

    let syncText = "Sync Slack";
    if (syncLoading) {
        syncText = "Syncing..."
    }
    if (synced) {
        syncText = "Sync running!"
    }

    if (slackUser) {
        return (
            <Stack direction="row" spacing="8">
                <Typography color={loggedInGreen}>
                    <CheckCircle />
                </Typography>&nbsp;&nbsp;
                <Typography>
                    Connected to Slack <Link href={loginUrl}>
                        <Button variant="outlined" size="small" color="secondary">
                            Reconnect
                        </Button>
                    </Link>
                    <Button variant="outlined" size="small" color="primary" sx={{ "marginLeft": 1 }} onClick={syncSlack} disabled={syncLoading || synced}>
                        {syncText}
                    </Button>
                </Typography>

            </Stack>
        )
    } else {
        return (
            <Link href={loginUrl}>
                <Button variant="contained" size="small" color="secondary">
                    Connect to Slack
                </Button>
            </Link>
        )
    }
};


export const ConnectToZoom = () => {
    const { curUser } = useCurrentUser();
    const [zoomUser, setZoomUser] = useState<ZoomUserModel>();
    const loginUrl = getZoomLoginUrl();

    useEffect(() => {
        getZoomService().getUser().then(setZoomUser);
    }, [curUser]);

    if (zoomUser) {
        return (
            <Stack direction="row" spacing="8">
                <Typography color={loggedInGreen}>
                    <CheckCircle />
                </Typography>&nbsp;&nbsp;
                <Typography>
                    Connected to Zoom <Link href={loginUrl}>
                        <Button variant="outlined" size="small" color="secondary">
                            Reconnect
                        </Button>
                    </Link>
                </Typography>

            </Stack>
        )
    } else {
        return (
            <Link href={loginUrl}>
                <Button variant="contained" size="small" color="secondary">
                    Connect to Zoom
                </Button>
            </Link>
        )
    }
};


export const ConnectToDropbox = () => {
    const { curUser } = useCurrentUser();
    const [user, setUser] = useState<DropboxUserResponse>();
    const [syncLoading, setSyncLoading] = useState(false);
    const [synced, setSynced] = useState(false);
    const loginUrl = getLoginUrl();

    useEffect(() => {
        getAuthService().readUsersMe().then(user => setUser(user));
    }, [curUser]);

    const syncPaper = async () => {
        try {
            setSyncLoading(true);
            await getSyncService().startPaperSync();
            setSynced(true);
        } finally {
            setSyncLoading(false);
        }
    };

    let syncText = "Sync Paper";
    if (syncLoading) {
        syncText = "Syncing..."
    }
    if (synced) {
        syncText = "Sync running!"
    }
    if (user) {
        return (
            <Stack direction="row" spacing="8">
                <Typography color={loggedInGreen}>
                    <CheckCircle />
                </Typography>&nbsp;&nbsp;
                <Typography>
                    Connected to Dropbox <Link href={loginUrl}>
                        <Button variant="outlined" size="small" color="secondary">
                            Reconnect
                        </Button>
                    </Link>
                    <Button variant="outlined" size="small" color="primary" sx={{ "marginLeft": 1 }} onClick={syncPaper} disabled={syncLoading || synced}>
                        {syncText}
                    </Button>
                </Typography>

            </Stack>
        )
    } else {
        return (
            <Link href={loginUrl}>
                <Button variant="contained" size="small" color="secondary">
                    Connect to Dropbox
                </Button>
            </Link>
        )
    }
};