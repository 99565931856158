import {Alert, AlertColor, Snackbar} from "@mui/material";
import {createContext, useContext, useState} from "react";

type SnackState = {
  isOpen: boolean;
  severity: AlertColor;
  message: string;
};

type SnackInterface = {
  info: (message: string) => void;
  success: (message: string) => void;
  warning: (message: string) => void;
  error: (message: string) => void;
};

type SnackInnerState = {
  state: SnackState;
  setState: (newState: SnackState) => void;
};

type SnackerState = SnackInterface & SnackInnerState;

export const SnackbarContext = createContext<SnackerState |null>(null);
export const SnackbarProvider = SnackbarContext.Provider;

export const useSnackbar = () => {
  return useContext(SnackbarContext);
};

export const InitSnackbar = (): SnackInterface & SnackInnerState => {
  const [state,setState] = useState<SnackState>({isOpen: false, severity: "info", message: ""});
  const newMsg = {isOpen: true};

  return {
    info: (message: string) => setState({...newMsg, severity: "info", message: message}),
    success: (message: string) => setState({...newMsg, severity: "success", message: message}),
    warning: (message: string) => setState({...newMsg, severity: "warning", message: message}),
    error: (message: string) => setState({...newMsg, severity: "error", message: message}),
    state: state,
    setState: setState,
  };
};

export type SnackMessageProps = {
  state: SnackInnerState;
};

export const SnackMessage = ({state}: SnackMessageProps) => {
  const handleSnackBarClose = (_: null | React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    state.setState({isOpen: false, severity: "info", message: ""});
  };

  return (
    <Snackbar
      open={state.state.isOpen}
      autoHideDuration={3000}
      onClose={handleSnackBarClose}
      anchorOrigin={{vertical: "bottom", horizontal: "right"}}
    >
      <Alert onClose={handleSnackBarClose} severity={state.state.severity} sx={{ width: '100%' }} elevation={3}>
        {state.state.message}
      </Alert>
    </Snackbar>
  );
};
