import {Box, Link, Paper, Stack, TextField, Typography} from '@mui/material';
import {useEffect, useState} from 'react';
import {AgentSearchFilter, parseAgentSearchText} from '../../utilities/agent';

export type AgentSearchBarProps = {
  initText: string;
  onChange: (searchInfo: AgentSearchFilter) => void;
  commonLinks?: {label: string, link: string}[];
  autoFocus?: boolean;
};

export const AgentSearchBar = ({initText, onChange, commonLinks = [], autoFocus = true}: AgentSearchBarProps) => {
  const [filter, setFilter] = useState<AgentSearchFilter>({});

  const changeHandler = (newValue: string) => {
    const agentFilter = parseAgentSearchText(newValue);
    onChange(agentFilter);
  };

  useEffect(() => {
    if (initText && initText.length > 0) {
      changeHandler(initText);
    }
  }, [initText]);

  return (
    <Paper
      variant="elevation"
      elevation={0}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TextField
          autoFocus={autoFocus}
          autoComplete="off"
          label="Text search"
          placeholder={"examples  -  owner:user@email   deployed:true   published:false   keyterm1  \"key term 2\""}
          fullWidth={true}
          value={initText}
          onChange={(event) => changeHandler(event.target.value)}
        />

        <Stack gap={2} direction="row" marginY={0} paddingY={0} justifyContent="flex-end">
          <Typography variant="subtitle2">Common searches:</Typography>

          {commonLinks.map(({label, link}, idx) =>
            <Link href={link} key={`${label}-${idx}`}>
              <Typography variant="subtitle2">{label}</Typography>
            </Link>
          )}
        </Stack>
      </Box>
    </Paper>
  );
}
