/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CE_EmployeeInfo } from '../models/CE_EmployeeInfo';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrgService {

    /**
     * Org Get Chart As Graph
     * Get the organizational chart as a networkx graph
     *
     * Serializable Json representation of a networkx graph to allow graph operations.
     * https://networkx.org/documentation/stable/reference/introduction.html
     * @returns any Successful Response
     * @throws ApiError
     */
    public static orgGetChartAsGraph(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/org/get_org_chart_as_graph',
        });
    }

    /**
     * Org Export Chart As Csv
     * CSV representation of the dropbox org chart for customizability
     * @returns any Successful Response
     * @throws ApiError
     */
    public static orgExportChartAsCsv(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/org/export_org_chart_as_csv',
        });
    }

    /**
     * Org List All Employees
     * List of CE_EmployeeInfo objects with details from orgchart.
     * @returns CE_EmployeeInfo Successful Response
     * @throws ApiError
     */
    public static orgListAllEmployees(): CancelablePromise<Array<CE_EmployeeInfo>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/org/employees',
        });
    }

    /**
     * Org Get Employee By Employee Id
     * Get info about an employee given an id
     * @param employeeId
     * @returns CE_EmployeeInfo Successful Response
     * @throws ApiError
     */
    public static orgGetEmployeeByEmployeeId(
        employeeId: string,
    ): CancelablePromise<CE_EmployeeInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/org/employees/{employee_id}',
            path: {
                'employee_id': employeeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Org Get Employees On My Team
     * List of CE_EmployeeInfo objects with details from orgchart.
     * @returns CE_EmployeeInfo Successful Response
     * @throws ApiError
     */
    public static orgGetEmployeesOnMyTeam(): CancelablePromise<Array<CE_EmployeeInfo>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/org/employees_on_my_team',
        });
    }

    /**
     * Org Search Employees By First Name
     * Returns a list of employees by first name
     * @param firstName
     * @returns CE_EmployeeInfo Successful Response
     * @throws ApiError
     */
    public static orgSearchEmployeesByFirstName(
        firstName: string,
    ): CancelablePromise<Array<CE_EmployeeInfo>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/org/employees/search/first_name/{first_name}',
            path: {
                'first_name': firstName,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Org Search Employees By Last Name
     * Returns a list of employees by last name
     * @param lastName
     * @returns CE_EmployeeInfo Successful Response
     * @throws ApiError
     */
    public static orgSearchEmployeesByLastName(
        lastName: string,
    ): CancelablePromise<Array<CE_EmployeeInfo>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/org/employees/search/last_name/{last_name}',
            path: {
                'last_name': lastName,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Org Search Employees By Email
     * Returns EmployeeInfo for the email
     * @param email
     * @returns CE_EmployeeInfo Successful Response
     * @throws ApiError
     */
    public static orgSearchEmployeesByEmail(
        email: string,
    ): CancelablePromise<CE_EmployeeInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/org/employees/search/email/{email}',
            path: {
                'email': email,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Org Search Employees By Full Name
     * Returns a list of employees by last name
     * @param fullName
     * @returns CE_EmployeeInfo Successful Response
     * @throws ApiError
     */
    public static orgSearchEmployeesByFullName(
        fullName: string,
    ): CancelablePromise<Array<CE_EmployeeInfo>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/org/employees/search/full_name/{full_name}',
            path: {
                'full_name': fullName,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Org Search Employees By Team
     * Returns a list of employees on the team
     * @param teamName
     * @returns CE_EmployeeInfo Successful Response
     * @throws ApiError
     */
    public static orgSearchEmployeesByTeam(
        teamName: string,
    ): CancelablePromise<Array<CE_EmployeeInfo>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/org/employees/search/team/{team_name}',
            path: {
                'team_name': teamName,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
