/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CalendarEvent } from '../models/CalendarEvent';
import type { CalendarSetting } from '../models/CalendarSetting';
import type { CurrentUserSyncState } from '../models/CurrentUserSyncState';
import type { GCalTroubleshootingInfo } from '../models/GCalTroubleshootingInfo';
import type { GoogleNotificationChannel } from '../models/GoogleNotificationChannel';
import type { MessageResponse } from '../models/MessageResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class GcalService {

    /**
     * Root
     * Root endpoint for the Google Calendar Data Source API
     * @returns any Successful Response
     * @throws ApiError
     */
    public static root(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/gcal',
        });
    }

    /**
     * Gcal List
     * Returns a list of calendar events for the user within the time range.
     * Provide datetime parameters in ISO string format ex: 2023-06-06 14:00:00-07:00.
     * If timezone is not provided, will default to timezone of user's calendar.
     * @param startDate
     * @param endDate
     * @returns CalendarEvent Successful Response
     * @throws ApiError
     */
    public static gcalList(
        startDate?: string,
        endDate?: string,
    ): CancelablePromise<Array<CalendarEvent>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/gcal/list',
            query: {
                'start_date': startDate,
                'end_date': endDate,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Gcal Settings
     * Returns the user's calendar settings
     * @returns CalendarSetting Successful Response
     * @throws ApiError
     */
    public static gcalSettings(): CancelablePromise<Array<CalendarSetting>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/gcal/settings',
        });
    }

    /**
     * Gcal Subscribe
     * Enables webhook notifications for the user's calendar to trigger syncs on changes.
     *
     * If a subscription already exists, replace it as they expire after 7 days.
     * @returns MessageResponse Successful Response
     * @throws ApiError
     */
    public static gcalSubscribe(): CancelablePromise<MessageResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v3/datasource/gcal/subscribe',
        });
    }

    /**
     * Gcal Unsubscribe
     * Disables webhook notifications for the user's calendar. If disabled, the user's calendar will only update when a sync is run
     * @returns MessageResponse Successful Response
     * @throws ApiError
     */
    public static gcalUnsubscribe(): CancelablePromise<MessageResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v3/datasource/gcal/unsubscribe',
        });
    }

    /**
     * Gcal Get Subscriptions
     * Returns a list of webhook subscriptions for the user's calendar
     * @returns GoogleNotificationChannel Successful Response
     * @throws ApiError
     */
    public static gcalGetSubscriptions(): CancelablePromise<Array<GoogleNotificationChannel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/gcal/subscriptions',
        });
    }

    /**
     * Gcal Sync Org To Snowflake
     * Syncs the entire org's calendar to snowflake
     * @returns MessageResponse Successful Response
     * @throws ApiError
     */
    public static gcalSyncOrgToSnowflake(): CancelablePromise<MessageResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v3/datasource/gcalorg/sync_to_snowflake',
        });
    }

    /**
     * Gcal Get Sync State
     * Returns the current sync state for the user's calendar
     * @returns CurrentUserSyncState Successful Response
     * @throws ApiError
     */
    public static gcalGetSyncState(): CancelablePromise<CurrentUserSyncState> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/gcal/status',
        });
    }

    /**
     * Gcal Get User Access Token
     * Returns the user's google access token
     * @returns string Successful Response
     * @throws ApiError
     */
    public static gcalGetUserAccessToken(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/gcal/user/access_token',
        });
    }

    /**
     * Gcal Delete Content
     * Deletes all gcal content for the user
     * @returns MessageResponse Successful Response
     * @throws ApiError
     */
    public static gcalDeleteContent(): CancelablePromise<MessageResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v3/datasource/gcal/delete_content',
        });
    }

    /**
     * Gcal Troubleshooting
     * If Google Calendar events do not seem to be updating, try this endpoint first to check for common issues
     * @returns GCalTroubleshootingInfo Successful Response
     * @throws ApiError
     */
    public static gcalTroubleshooting(): CancelablePromise<GCalTroubleshootingInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/gcal/troubleshooting',
        });
    }

}
