import {Typography} from "@mui/material";
import {Agent} from "http";
import {useEffect, useState} from "react";
import {AgentDSLDefinition} from "../../context_engine_client";
import {assertExhaustive, getContextEngineAgentPlatformService} from "../../utilities";
import {InstructionEvent} from "./AgentEvents";
import {AgentInstructionList} from "./AgentInstructionList";
import {useSnackbar} from "./SnackMessage";

export type AgentInstructionListStandaloneProps = {
  agentId: string;
};

export const AgentInstructionListStandalone = ({agentId}: AgentInstructionListStandaloneProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [instructions, setInstructions] = useState<null | AgentDSLDefinition[]>(null);

  const snackbar = useSnackbar();

  useEffect(() => {
    let ignore = false;

    const platformService = getContextEngineAgentPlatformService();
    platformService
      .getAllAgentDsl(agentId)
      .then((instrs: AgentDSLDefinition[]) => {
        setInstructions(instrs.reverse());
      })
      .catch((err) => {
        snackbar?.error("Could not load agent instructions");
      })
      .finally(() => {
        setIsLoading(false);
      });

    return () => {ignore = true;}
  }, []);


  const handleInstructionEvents = (event: InstructionEvent) => {
    if (instructions === null) { return; }

    const platformService = getContextEngineAgentPlatformService();
    const eventType = event.type;
    const isExisting = !!event.instructionId;

    switch (eventType) {
      case "save":
        let promise;

        // create
        if (event.instructionId) {
          promise = platformService.updateAgentDsl(
            agentId,
            event.instructionId,
            { user: event.user, ai: event.ai },
          );
        // update
        } else {
          promise = platformService.createAgentDsl(
            agentId,
            [{ user: event.user, ai: event.ai}]
          );
        }

        promise
          .then((savedInstrs: AgentDSLDefinition[] | AgentDSLDefinition) => {
            if (isExisting) {
              snackbar?.success("Example instructions updated 🎉");
            } else {
              snackbar?.success("New example instructions saved 🎉");
            }

            savedInstrs = (Array.isArray(savedInstrs) ? savedInstrs : [savedInstrs]);
            let newInstructions: AgentDSLDefinition[] = [];
            if (!event.instructionId) {
              newInstructions = savedInstrs;
            } else {
              savedInstrs.forEach((newInstr) => {
                const idx = instructions.findIndex((instr) => instr.dsl_id === newInstr.dsl_id);
                if (idx === -1) { return; }

                instructions[idx] = newInstr;
              });
            }
            setInstructions([...newInstructions, ...instructions]);
            event.onSuccess();
          })
          .catch((error) => {
            const msg = "Could not save instructions :(";
            console.log(msg, error);
            snackbar?.error(msg);
          });
        break;
      case "generate":
        platformService.autocompleteAgentDsl(agentId, {
          user: event.user,
          ai: [],
        })
        .then((result) => {
          event.onSuccess(result.ai);
          snackbar?.success("Generated new instructions from your description!");
        })
        .catch((error) => {
          console.error("Could not generate new instructions");
          console.error(error)
          snackbar?.error("Could not generate new instructions");
        });
        break;
      case "delete":
        const result = platformService.deleteAgentDsl(
          agentId,
          event.instructionId,
        )
        .then(() => {
          snackbar?.success("Deleted example instruction");
          const removeId = event.instructionId;
          const newInstrs = instructions.filter((keepInstr) => keepInstr.dsl_id !== removeId);
          setInstructions([...newInstrs]);
        })
        .catch((error) => {
          const msg = `Could not delete instructions: ${event.instructionId}`;
          console.error(msg, error);
          snackbar?.error("Could not delete instructions");
        });
        break;
      default:
        assertExhaustive(eventType);
        break;
    }
  };

  return (
    instructions &&
      <AgentInstructionList
        instructions={instructions}
        onSave={handleInstructionEvents}
        onGenerate={handleInstructionEvents}
        onDelete={handleInstructionEvents}
      />
  );
}
