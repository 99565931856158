/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BasicFunctionDefinition } from './BasicFunctionDefinition';
import type { BasicImportDefinition } from './BasicImportDefinition';

export type BasicContextDefinition = {
    id?: string;
    name: string;
    version?: BasicContextDefinition.version;
    owner_email: string;
    description: string;
    tools: Array<BasicFunctionDefinition>;
    codegen_examples: Array<BasicFunctionDefinition>;
    imports: Array<BasicImportDefinition>;
};

export namespace BasicContextDefinition {

    export enum version {
        BASIC_V1 = 'basic_v1',
        OPENAI_V1 = 'openai_v1',
    }


}

