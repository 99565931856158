/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AgentDSLDefinition } from '../models/AgentDSLDefinition';
import type { AgentDSLDefinitionInput } from '../models/AgentDSLDefinitionInput';
import type { AgentDSLDefinitionResponse } from '../models/AgentDSLDefinitionResponse';
import type { BasicContextDefinition } from '../models/BasicContextDefinition';
import type { ContextEngineAgent } from '../models/ContextEngineAgent';
import type { CreateBasicContextInput } from '../models/CreateBasicContextInput';
import type { CreateOpenAPIContextInput } from '../models/CreateOpenAPIContextInput';
import type { CreateOrUpdateContextEngineAgentInput } from '../models/CreateOrUpdateContextEngineAgentInput';
import type { OpenAPIContextDefinition } from '../models/OpenAPIContextDefinition';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PlatformService {

    /**
     * Get All Agents
     * @returns ContextEngineAgent Successful Response
     * @throws ApiError
     */
    public static getAllAgents(): CancelablePromise<Array<ContextEngineAgent>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/agent/',
        });
    }

    /**
     * Create Agent
     * @param requestBody
     * @returns ContextEngineAgent Successful Response
     * @throws ApiError
     */
    public static createAgent(
        requestBody: CreateOrUpdateContextEngineAgentInput,
    ): CancelablePromise<ContextEngineAgent> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/agent/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Agent
     * @param agentId
     * @returns ContextEngineAgent Successful Response
     * @throws ApiError
     */
    public static getAgent(
        agentId: string,
    ): CancelablePromise<ContextEngineAgent> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/agent/{agent_id}',
            path: {
                'agent_id': agentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Agent
     * @param agentId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteAgent(
        agentId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/agent/{agent_id}',
            path: {
                'agent_id': agentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Agent
     * @param agentId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateAgent(
        agentId: string,
        requestBody: CreateOrUpdateContextEngineAgentInput,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/agent/{agent_id}',
            path: {
                'agent_id': agentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Clone Agent
     * @param agentId
     * @returns ContextEngineAgent Successful Response
     * @throws ApiError
     */
    public static cloneAgent(
        agentId: string,
    ): CancelablePromise<ContextEngineAgent> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/agent/{agent_id}/clone',
            path: {
                'agent_id': agentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Deploy Agent
     * @param agentId
     * @returns ContextEngineAgent Successful Response
     * @throws ApiError
     */
    public static deployAgent(
        agentId: string,
    ): CancelablePromise<ContextEngineAgent> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/agent/{agent_id}/deploy',
            path: {
                'agent_id': agentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Undeploy Agent
     * @param agentId
     * @returns ContextEngineAgent Successful Response
     * @throws ApiError
     */
    public static undeployAgent(
        agentId: string,
    ): CancelablePromise<ContextEngineAgent> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/agent/{agent_id}/undeploy',
            path: {
                'agent_id': agentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Publish Agent
     * @param agentId
     * @returns ContextEngineAgent Successful Response
     * @throws ApiError
     */
    public static publishAgent(
        agentId: string,
    ): CancelablePromise<ContextEngineAgent> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/agent/{agent_id}/publish',
            path: {
                'agent_id': agentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Unpublish Agent
     * @param agentId
     * @returns ContextEngineAgent Successful Response
     * @throws ApiError
     */
    public static unpublishAgent(
        agentId: string,
    ): CancelablePromise<ContextEngineAgent> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/agent/{agent_id}/unpublish',
            path: {
                'agent_id': agentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Agent Dsl
     * @param agentId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getAllAgentDsl(
        agentId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/agent/{agent_id}/dsl',
            path: {
                'agent_id': agentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Agent Dsl
     * @param agentId
     * @param requestBody
     * @returns AgentDSLDefinition Successful Response
     * @throws ApiError
     */
    public static createAgentDsl(
        agentId: string,
        requestBody: Array<AgentDSLDefinitionInput>,
    ): CancelablePromise<Array<AgentDSLDefinition>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/agent/{agent_id}/dsl',
            path: {
                'agent_id': agentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Agent Dsl Plain
     * @param agentId
     * @returns AgentDSLDefinitionResponse Successful Response
     * @throws ApiError
     */
    public static getAllAgentDslPlain(
        agentId: string,
    ): CancelablePromise<Array<AgentDSLDefinitionResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/agent/{agent_id}/dsl/plain',
            path: {
                'agent_id': agentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Autocomplete Agent Dsl
     * @param agentId
     * @param requestBody
     * @returns AgentDSLDefinitionInput Successful Response
     * @throws ApiError
     */
    public static autocompleteAgentDsl(
        agentId: string,
        requestBody: AgentDSLDefinitionInput,
    ): CancelablePromise<AgentDSLDefinitionInput> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/agent/{agent_id}/dsl/autocomplete',
            path: {
                'agent_id': agentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Agent Dsl By Id
     * @param agentId
     * @param dslId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getAgentDslById(
        agentId: string,
        dslId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/agent/{agent_id}/dsl/{dsl_id}',
            path: {
                'agent_id': agentId,
                'dsl_id': dslId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Agent Dsl
     * @param agentId
     * @param dslId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteAgentDsl(
        agentId: string,
        dslId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/agent/{agent_id}/dsl/{dsl_id}',
            path: {
                'agent_id': agentId,
                'dsl_id': dslId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Agent Dsl
     * @param agentId
     * @param dslId
     * @param requestBody
     * @returns AgentDSLDefinition Successful Response
     * @throws ApiError
     */
    public static updateAgentDsl(
        agentId: string,
        dslId: string,
        requestBody: AgentDSLDefinitionInput,
    ): CancelablePromise<AgentDSLDefinition> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/agent/{agent_id}/dsl/{dsl_id}',
            path: {
                'agent_id': agentId,
                'dsl_id': dslId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Basic Custom Context
     * Create a basic custom context, by providing the source code for a Python module containing the context tools.
     * @param requestBody
     * @returns BasicContextDefinition Successful Response
     * @throws ApiError
     */
    public static createBasicCustomContext(
        requestBody: CreateBasicContextInput,
    ): CancelablePromise<BasicContextDefinition> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/context/basic',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Basic Custom Context
     * @param contextId
     * @returns BasicContextDefinition Successful Response
     * @throws ApiError
     */
    public static getBasicCustomContext(
        contextId: string,
    ): CancelablePromise<BasicContextDefinition> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/context/basic/{context_id}',
            path: {
                'context_id': contextId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Basic Custom Context
     * @param contextId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteBasicCustomContext(
        contextId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/context/basic/{context_id}',
            path: {
                'context_id': contextId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Basic Custom Context
     * @param contextId
     * @param requestBody
     * @returns BasicContextDefinition Successful Response
     * @throws ApiError
     */
    public static updateBasicCustomContext(
        contextId: string,
        requestBody: CreateBasicContextInput,
    ): CancelablePromise<BasicContextDefinition> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/context/basic/{context_id}',
            path: {
                'context_id': contextId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * List Basic Custom Contexts
     * @returns BasicContextDefinition Successful Response
     * @throws ApiError
     */
    public static listBasicCustomContexts(): CancelablePromise<Array<BasicContextDefinition>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/context/list/basic',
        });
    }

    /**
     * Create Openapi Custom Context
     * Create a custom context from an OpenAPI specification. The endpoints specified in the spec will be available as context tools.
     * @param requestBody
     * @returns OpenAPIContextDefinition Successful Response
     * @throws ApiError
     */
    public static createOpenapiCustomContext(
        requestBody: CreateOpenAPIContextInput,
    ): CancelablePromise<OpenAPIContextDefinition> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/context/openapi',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
