/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DSLParameter } from './DSLParameter';
import type { DSLType } from './DSLType';

export type OpenAPIEndpointDefinition = {
    name: string;
    description: string;
    url: string;
    method: OpenAPIEndpointDefinition.method;
    params: Array<DSLParameter>;
    return_type: DSLType;
};

export namespace OpenAPIEndpointDefinition {

    export enum method {
        GET = 'GET',
    }


}

