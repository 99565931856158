import {Accordion, AccordionDetails, AccordionSummary, Box,Button,Card,CardContent,CardHeader,Collapse,colors,Divider,Drawer,easing,FormControlLabel,List,ListItem,Stack,styled,Typography, useTheme} from "@mui/material";
import {useState} from "react";
import ReactJson from "react-json-view";
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';


export type ResponseVisualizerProps = {
  title: string,
  apiResponse: object;
  success: boolean;
};

export const ResponseVisualizer = ({title, apiResponse, success}: ResponseVisualizerProps) => {
  const [debugInfoOpen, setDebugInfoOpen] = useState(false);
  const theme = useTheme();

  return (
      <Stack spacing={3} direction="row">
        <Button
          variant="text"
          color={success ? "success" : "error"}
          size="small"
          onClick={()=>setDebugInfoOpen(!debugInfoOpen)}
          sx={{alignSelf: "start", whiteSpace: "nowrap", textAlign: "left"}}
          endIcon={debugInfoOpen ? <UnfoldLessIcon/> : <UnfoldMoreIcon/>}
        >
          Debug 
        </Button>
        <Collapse
          easing={theme.transitions.easing.easeIn}
          in={debugInfoOpen}
          sx={{width: "100%"}}
        >
          <Stack
            spacing={1}
            sx={{
              backgroundColor: theme.palette.info.light,
              padding: theme.spacing(2),
            }}
          >
            <Typography>Response JSON</Typography>
            <ReactJson
              src={apiResponse}
              theme="monokai"
              displayDataTypes={false}
              displayObjectSize={false}
              style={{ width: "100%", height: "100%"}}
              collapsed={true}
            />
          </Stack>
        </Collapse>
      </Stack>
  );
}
