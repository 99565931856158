/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export type FunctionParam = {
  name: string;
  type?: string;
};

export type FunctionDetails = {
  name: string;
  doc?: string;
  params: FunctionParam[];
  return_type?: string;
};

export type ContextEngineContextDetails = {
  tools: FunctionDetails[];
  examples: string[];
};

export class HelperService {

    /**
     * Get Built In Contexts
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getBuiltInContexts(): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/context_helper/context',
        });
    }

    /**
     * Get Custom Contexts
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getCustomContexts(): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/context_helper/custom_context',
        });
    }

    /**
     * Get Tools In Context
     * @param contextId
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getToolsInContext(
        contextId: string,
    ): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/context_helper/context/{context_id}/tools',
            path: {
                'context_id': contextId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Tools (Detailed) In Context
     * @param contextId
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getToolsDetailsInContext(
        contextId: string,
    ): CancelablePromise<ContextEngineContextDetails> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/context_helper/context/{context_id}/tools-detailed',
            path: {
                'context_id': contextId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Types In Context
     * @param contextId
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getTypesInContext(
        contextId: string,
    ): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/context_helper/context/{context_id}/types',
            path: {
                'context_id': contextId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
