import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, SxProps, Theme, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {ContextEngineAgent} from "../../context_engine_client";
import {assertExhaustive, getContextEngineAgentPlatformService} from "../../utilities";
import {AgentSearchFilter, filterAgents} from "../../utilities/agent";
import {AgentClickHandler, AgentList, AgentListEvent} from "./AgentList";
import {NewAgentDialog} from "./NewAgentDialog";
import {useSnackbar} from "./SnackMessage";
import AddIcon from '@mui/icons-material/Add';

type DeleteDialogState = {
  isOpen: boolean;
  agent?: ContextEngineAgent;
};

export type AgentListStandaloneProps = {
  agentBaseUrl?: string;
  onAgentCreated?: AgentClickHandler;
  onAgentSelected?: AgentClickHandler;
  sx?: SxProps<Theme>;
  agentsClickable?: boolean;
  showNewButton?: boolean;
  filter: AgentSearchFilter;
};

export const AgentListStandalone = ({
  agentBaseUrl,
  onAgentSelected,
  sx,
  agentsClickable,
  showNewButton,
  filter,
}: AgentListStandaloneProps) => {
  const [agents, setAgents] = useState<null | ContextEngineAgent[]>(null);
  const [showNewAgentDialog, setShowNewAgentDialog] = useState(false);
  const [deleteDialogState, setDeleteDialogState] = useState<DeleteDialogState>({isOpen: false});

  agentBaseUrl = agentBaseUrl || "..";
  if (agentBaseUrl.endsWith("/")) {
    agentBaseUrl = agentBaseUrl.substring(0, agentBaseUrl.length-1);
  }
  const agentRelUrl = (path: string) => `${agentBaseUrl}/${path}`;

  const snackbar = useSnackbar();

  const handleDeleteDialogClose = () => {
    setDeleteDialogState({isOpen: false});
  };
  const agentsToDisplay = filterAgents(agents || [], filter);

  const navigate = useNavigate();
  const agentEventHandler = (event: AgentListEvent) => {
    const eType = event.type;
    const platformService = getContextEngineAgentPlatformService();
    switch (eType) {
      case "new":
        platformService
          .createAgent({agent_name: event.info.name, agent_description: event.info.description})
          .then((newAgent: ContextEngineAgent) => {
            setAgents([...(agents || []), newAgent]);
            snackbar?.success("Successfully created agent");
          })
          .catch((error) => {
            snackbar?.error(`Could not create agent: ${error}`);
          })
          .finally(() => {
            setShowNewAgentDialog(false);
          });
        break;
      case "click":
        navigate(agentRelUrl(event.agent.agent_id));
        break;
      case "edit":
        navigate(agentRelUrl(`${event.agent.agent_id}/#action=edit`));
        break;
      case "clone":
        platformService.cloneAgent(event.agent.agent_id)
        .then((newAgent: ContextEngineAgent) => {
          setAgents([...(agents || []), newAgent]);
          snackbar?.success("Successfully cloned agent, loading agent page for editing...");
          setTimeout(() => {
            navigate(agentRelUrl(`${newAgent.agent_id}/#action=edit`));
          }, 1000);
        }).catch((error) => {
          snackbar?.error("Error cloning agent");
          console.error("Error cloning agent", error);
        }).finally(() => {
        });
        break;
      case "delete":
        platformService.deleteAgent(event.agent.agent_id).then(() => {
          snackbar?.success("Successfully deleted agent");
          setAgents((agents || []).filter((a) => a.agent_id !== event.agent.agent_id));
        }).catch((error) => {
          snackbar?.error("Error deleting agent");
          console.error("Error deleting agent", error);
        }).finally(() => {
        });
        break;
      case "test":
        break;
      default:
        assertExhaustive(eType);
        break;
    }
  };

  useEffect(() => {
    // they've already been provided for us
    if (agents) {
      return;
    }

    let ignore = false;
    setAgents([]);

    const platformService = getContextEngineAgentPlatformService();
    platformService
      .getAllAgents()
      .then((result) => {
        if (!ignore) {
          const newAgents = filterAgents(result, filter);
          setAgents(newAgents);
        }
        snackbar?.success("Loaded agents");
      })
      .catch((error) => {
        const msg = `Could not load agents: ${error}`;
        console.log(msg);
        snackbar?.error(msg);
      });

    // cleanup
    return () => {ignore = true;}
  }, []);

  return (<>
    <NewAgentDialog
      title="Create a new Agent"
      open={showNewAgentDialog}
      onCreate={(info) => agentEventHandler({type: "new", info})}
      onCancel={() => setShowNewAgentDialog(false)}
      actionVerb="Create!"
    />

    {deleteDialogState.agent &&
      <Dialog
        open={deleteDialogState.isOpen}
        onClose={handleDeleteDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Delete agent '${deleteDialogState.agent.agent_info.agent_name}'?`}
        </DialogTitle>
        <DialogContent sx={{minWidth: 350}}>
          <DialogContentText id="alert-dialog-description">
            This is unrecoverable.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose}>No</Button>
          <Button onClick={() => {
            handleDeleteDialogClose();
            (deleteDialogState.agent &&
              agentEventHandler({type: "delete", agent: deleteDialogState.agent}));
          }} autoFocus variant="contained">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    }

    <Stack gap={0}>
      {showNewButton &&
        <Button
          variant="contained"
          color="success"
          size="small"
          onClick={() => {
            setShowNewAgentDialog(true);
          }}
          sx={{
            alignSelf: "flex-end",
          }}
        >
          <AddIcon fontSize="small"/>
          <Typography fontSize="small">New</Typography>
        </Button>
      }

      <AgentList
        onAgentEdit={agentEventHandler}
        onAgentClone={agentEventHandler}
        onAgentDelete={(agentEvent) => setDeleteDialogState({isOpen: true, agent: agentEvent.agent})}
        onAgentSelected={(agentEvent) => onAgentSelected && onAgentSelected(agentEvent)}

        agents={agentsToDisplay}
        sx={sx}
        agentsClickable={agentsClickable}
      />
    </Stack>
  </>);
};
