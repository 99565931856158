/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_generate_and_execute_dsl_generate_and_execute_dsl_post } from '../models/Body_generate_and_execute_dsl_generate_and_execute_dsl_post';
import type { DSLDocument } from '../models/DSLDocument';
import type { DSLGeneratorInput } from '../models/DSLGeneratorInput';
import type { ExecutionDocument } from '../models/ExecutionDocument';
import type { ExecutionFeedbackRequest } from '../models/ExecutionFeedbackRequest';
import type { ExecutionRequestInput } from '../models/ExecutionRequestInput';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DefaultService {

    /**
     * Root
     * @returns any Successful Response
     * @throws ApiError
     */
    public static root(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/',
        });
    }

    /**
     * Generate Dsl
     * @param requestBody
     * @returns DSLDocument Successful Response
     * @throws ApiError
     */
    public static generateDsl(
        requestBody: DSLGeneratorInput,
    ): CancelablePromise<DSLDocument> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/dsl',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Dsl
     * @param requestId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getDsl(
        requestId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/dsl/{request_id}',
            path: {
                'request_id': requestId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Execute Dsl
     * @param dslId
     * @returns ExecutionDocument Successful Response
     * @throws ApiError
     */
    public static executeDsl(
        dslId: string,
    ): CancelablePromise<ExecutionDocument> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/execute/{dsl_id}',
            path: {
                'dsl_id': dslId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Generate And Execute Dsl
     * @param requestBody
     * @returns ExecutionDocument Successful Response
     * @throws ApiError
     */
    public static generateAndExecuteDsl(
        requestBody: Body_generate_and_execute_dsl_generate_and_execute_dsl_post,
    ): CancelablePromise<ExecutionDocument> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/generate_and_execute_dsl',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Execute Dsl With Option
     * @param requestBody
     * @returns ExecutionDocument Successful Response
     * @throws ApiError
     */
    public static executeDslWithOption(
        requestBody: ExecutionRequestInput,
    ): CancelablePromise<ExecutionDocument> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/execute_with_options',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Execution Feedback
     * @param executionId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static executionFeedback(
        executionId: string,
        requestBody: ExecutionFeedbackRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/execution/feedback/{execution_id}',
            path: {
                'execution_id': executionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Slackbot Redirect
     * @param query
     * @param appId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static slackbotRedirect(
        query: string = 'Who am I?',
        appId: string = 'Unknown',
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/slackbot/execute_and_redirect',
            query: {
                'query': query,
                'app_id': appId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Agent Telemetry
     * @param agentId
     * @param startDate
     * @param endDate
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getAgentTelemetry(
        agentId: string,
        startDate?: string,
        endDate?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/telemetry/{agent_id}/list',
            path: {
                'agent_id': agentId,
            },
            query: {
                'start_date': startDate,
                'end_date': endDate,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Execution Telemetry
     * @param agentId
     * @param executionId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getExecutionTelemetry(
        agentId: string,
        executionId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/telemetry/{agent_id}/execution/{execution_id}',
            path: {
                'agent_id': agentId,
                'execution_id': executionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
