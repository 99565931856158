import { Button } from "@mui/material";
import { useState } from 'react';

export const ImagePicker = ({ onFileChosen, label, accept }: { onFileChosen: any, label: string, accept?: string }) => {
  const [chosenFile, setChosenFile] = useState<File>();

  const handleFileUpload = (event: any) => {
    const file = event.target.files[0] as File;
    setChosenFile(file);
    const reader = new FileReader();

    reader.onloadend = () => {
      const dataUrl = reader.result as string;
      onFileChosen(dataUrl.split(',')[1]);
    };

    reader.readAsDataURL(file);
  };

  return (
    <label htmlFor="upload-image">
      <Button variant="outlined" color="secondary" component="span">{label}{chosenFile && ': ' + chosenFile.name}</Button>
      <input
        id="upload-image"
        hidden
        accept={accept || "image/*"}
        type="file"
        onChange={handleFileUpload}
      />
    </label>
  );
}