/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DocumentSnippet = {
    id?: string;
    file_id: string;
    doc_type: DocumentSnippet.doc_type;
    ns_id?: number;
    path?: string;
    url?: string;
    content: string;
    title: string;
    index: number;
    embedding?: Array<number>;
    modified_ts: string;
    fetched_ts: string;
    rev: string;
};

export namespace DocumentSnippet {

    export enum doc_type {
        PAPER = 'paper',
        CONFLUENCE = 'confluence',
        SERVICENOW = 'servicenow',
    }


}

