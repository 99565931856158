import { useState } from "react";
import { Box, Button, CircularProgress, FormControl, FormControlLabel, Grid, Input, InputLabel, MenuItem, Select, Slider, Switch, TextField, TextareaAutosize, Typography } from "@mui/material";
import ReactJson from "react-json-view";
import { SimpleList } from "../../components/SimpleList";
import { ImagePicker } from "../../components/ImagePicker";

type ModelChooserProps = { availableModels: string[], selectedModel: string, onModelChange: any };
const ModelChooser = ({ availableModels, selectedModel, onModelChange }: ModelChooserProps) => {
    return <SimpleList label="Model" id="model" options={availableModels} selectedOption={selectedModel} onChange={onModelChange} />
}

type BackendChooserProps = { availableBackends: string[], selectedBackend: string, onBackendChange: any };
const BackendChooser = ({ availableBackends, selectedBackend, onBackendChange }: BackendChooserProps) => {
    return <SimpleList label="Backend" id="backend" options={availableBackends} selectedOption={selectedBackend} onChange={onBackendChange} />
}

type ModelCapabilityType = "text/completion" | "text/edit" | "text/summary" | "image/describe" | "image/embeddings" | "text/embeddings" | "text/translation" | "text/qa";

const getAvailableModels = (type: ModelCapabilityType) => {
    switch (type) {
        case "image/describe":
            return ['blip2-flan-t5-xxl'];
        case "text/embeddings":
            return ['sbert_text_embedding:all_minilm_l6_v2'];
        case "text/edit":
            return ['flan-alpaca-xxl', 'flan-alpaca-ul2'];
        case "text/translation":
            return ['flan-alpaca-xxl', 'flan-alpaca-ul2'];
        case "text/qa":
            return ['flan-alpaca-xxl'];
        case "image/embeddings":
            return ['nlp_image_classification:clip_vit_b_32_gpu']
        default:
            return ['llama-v2-7b-f', 'llama-v2-13b-f', 'flan-alpaca-xxl', 'flan-alpaca-ul2', 'flan-ul2'];

    }
};

const getAvailableBackends = (type: ModelCapabilityType) => {
    switch (type) {
        default:
            return ['huggingface'];

    }
};

type MlCapabilitiesPlaygroundProps = { type: ModelCapabilityType, showSliders?: boolean, onRun: any };
export const MlCapabilitiesPlayground = ({ type = "text/completion", showSliders = true, onRun }: MlCapabilitiesPlaygroundProps) => {
    const [text, setText] = useState(''); // prompt, edit text, etc...
    const [instructions, setInstructions] = useState('')
    const [imageBytes, setImageBytes] = useState('');
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState({ output: '', result: {} });
    const [hasError, setHasError] = useState(false);
    const [showRaw, setShowRaw] = useState(false)

    const availableModels = getAvailableModels(type);
    const availableBackends = getAvailableBackends(type);
    const [selectedModel, setSelectedModel] = useState(availableModels[0]);
    const [selectedBackend, setSelectedBackend] = useState(availableBackends[0]);
    const [maxLength, setMaxLength] = useState(1024);

    const handleRun = async () => {
        setLoading(true);
        setHasError(false)
        setResult({ output: '', result: {} });
        try {
            const result = await onRun({ text, instructions, imageBytes, model: selectedModel, backend: selectedBackend, maxLength });
            setResult(result);
        } catch (e: any) {
            const result = { output: '', result: e.body };
            setShowRaw(true);
            setResult(result);
            setHasError(true);
        } finally {
            setLoading(false);
        }
    };

    const getInputs = () => {
        if (type === "text/completion") {
            let placeholder = "Prompt...";
            if(selectedModel.startsWith('llama')) {
                placeholder += " (For llama-v2 models, wrap your prompt in [INST][/INST])"
            }
            
            return <TextareaAutosize
                onChange={(e) => setText(e.target.value)}
                minRows={2}
                placeholder={placeholder}
                value={text}
                style={{ padding: 10, width: '60vw' }}
            />
        } else if (type === "text/edit") {
            return <>
                <TextareaAutosize
                    onChange={(e) => setText(e.target.value)}
                    minRows={2}
                    placeholder="Text to edit..."
                    value={text}
                    style={{ padding: 10, width: '30vw' }}
                />
                <TextareaAutosize
                    onChange={(e) => setInstructions(e.target.value)}
                    minRows={2}
                    placeholder="Edit instructions..."
                    value={instructions}
                    style={{ marginLeft: 20, padding: 10, width: '30vw' }}
                />
            </>
        } else if (type === "text/summary") {
            return <TextareaAutosize
                onChange={(e) => setText(e.target.value)}
                minRows={2}
                placeholder="Text to summarize..."
                value={text}
                style={{ padding: 10, width: '60vw' }}
            />
        } else if (type === "image/describe") {
            return <>
                <TextareaAutosize
                    onChange={(e) => setText(e.target.value)}
                    minRows={1}
                    placeholder="Optional question to ask about the image..."
                    value={text}
                    style={{ marginRight: 10, padding: 10, width: '40vw' }}
                />
                <ImagePicker label="Choose Image" onFileChosen={(imageBytes: string) => setImageBytes(imageBytes)} />
            </>
        } else if (type === "text/embeddings") {
            return <TextareaAutosize
                onChange={(e) => setText(e.target.value)}
                minRows={2}
                placeholder="Text for embeddings..."
                value={text}
                style={{ padding: 10, width: '60vw' }}
            />
        } else if (type === "image/embeddings") {
            return <ImagePicker accept=".ppm" label="Choose Image (.ppm files only)" onFileChosen={(imageBytes: string) => setImageBytes(imageBytes)} />
        } else if (type === "text/translation") {
            return <>
                <TextareaAutosize
                    onChange={(e) => setText(e.target.value)}
                    minRows={2}
                    placeholder="Text to translate (to French)..."
                    value={text}
                    style={{ padding: 10, width: '60vw' }}
                />
            </>
        } else if (type === "text/qa") {
            return <><TextareaAutosize
            onChange={(e) => setText(e.target.value)}
            minRows={2}
            placeholder="Question..."
            value={text}
            style={{ padding: 10, width: '30vw' }}
        />
        <TextareaAutosize
            onChange={(e) => setInstructions(e.target.value)}
            minRows={2}
            placeholder="Optional Dropbox File Id to use for answer..."
            value={instructions}
            style={{ marginLeft: 20, padding: 10, width: '15vw' }}
        /></>
        }
    }

    return (
        <>
            <Grid item xs={12} container direction='row' justifyContent='center' alignItems='center'>
                <Grid item alignItems="center" display="flex">
                    {getInputs()}
                </Grid>
                <Grid item xs='auto'>
                    <Button sx={{ m: 1 }} variant='contained' onClick={handleRun} disabled={loading}>{!loading ? "Run" : <CircularProgress color="info" size={20} />}</Button>
                </Grid>
            </Grid>

            <Grid item xs={12} container direction='row' justifyContent='center' alignItems='center'>
                <Grid item>
                    <ModelChooser availableModels={availableModels} selectedModel={selectedModel} onModelChange={setSelectedModel} />
                </Grid>
                <Grid item>
                    <BackendChooser availableBackends={availableBackends} selectedBackend={selectedBackend} onBackendChange={setSelectedBackend} />
                </Grid>
                {showSliders &&
                    <Grid item>
                        <Box sx={{ width: 250 }}>
                            <Typography gutterBottom>
                                Maximum Length: {maxLength}
                            </Typography>
                            <Slider
                                value={maxLength}
                                min={1}
                                step={1}
                                max={2048}
                                valueLabelDisplay="auto"
                                onChange={(_, value) => setMaxLength(value as number)}
                            />
                        </Box>
                    </Grid>}
            </Grid>

            <Grid item xs={12} container direction='column'>
                <Grid item alignSelf='flex-end'>
                    <FormControlLabel control={<Switch onChange={(e, checked) => setShowRaw(checked)} checked={showRaw} />} label="Raw response" />
                </Grid>
                <Grid item alignSelf='center'>
                    {
                        !showRaw ?
                            <TextField
                                id="filled-multiline-static"
                                label="Output"
                                error={hasError}
                                multiline
                                rows={20}
                                value={result.output}
                                variant="filled"
                                contentEditable={false}
                                style={{ width: '70vw' }}
                            /> : <div style={{ width: '70vw', textAlign: "left" }} >
                                <ReactJson src={result.result} />
                            </div>
                    }
                </Grid>
            </Grid>
        </>);
};
