/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AttendeeIdentifier } from './AttendeeIdentifier';
import type { ConferenceData } from './ConferenceData';
import type { TimeIdentifier } from './TimeIdentifier';
import type { UserIdentifier } from './UserIdentifier';

export type CalendarEvent = {
    kind?: string;
    etag: string;
    id: string;
    status: CalendarEvent.status;
    htmlLink?: string;
    created?: string;
    updated?: string;
    summary?: string;
    description?: string;
    location?: string;
    colorId?: string;
    creator?: UserIdentifier;
    organizer?: UserIdentifier;
    start?: TimeIdentifier;
    end?: TimeIdentifier;
    endTimeUnspecified?: boolean;
    recurrence?: Array<string>;
    recurringEventId?: string;
    originalStartTime?: TimeIdentifier;
    transparency?: string;
    visibility?: string;
    iCalUID?: string;
    sequence?: number;
    attendees?: Array<AttendeeIdentifier>;
    attendeesOmitted?: boolean;
    guestsCanInviteOthers?: boolean;
    guestsCanModify?: boolean;
    guestsCanSeeOtherGuests?: boolean;
    privateCopy?: boolean;
    locked?: boolean;
    eventType?: string;
    conferenceData?: ConferenceData;
};

export namespace CalendarEvent {

    export enum status {
        CONFIRMED = 'confirmed',
        TENTATIVE = 'tentative',
        CANCELLED = 'cancelled',
    }


}

