import {SxProps, Theme, Typography} from "@mui/material";
import {useEffect} from "react";
import {makeTaggedTitleString} from "../utilities";

export type TitleProps = {
  value: string;
  sx?: SxProps<Theme>,
};

export const Title = ({value, sx}: TitleProps) => {
  useEffect(() => {
    document.title = makeTaggedTitleString(value);
  }, []);

  return (
    <Typography variant="h1" sx={sx}>
      {value}
    </Typography>
  );
}
