/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MessageResponse } from '../models/MessageResponse';
import type { UserSyncSubscription } from '../models/UserSyncSubscription';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SyncService {

    /**
     * Start Org Chart Full Sync
     * Starts a sync of the org chart
     * @returns MessageResponse Successful Response
     * @throws ApiError
     */
    public static startOrgChartFullSync(): CancelablePromise<MessageResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v3/sync/org',
        });
    }

    /**
     * Start Slack User Sync
     * Starts a sync for the currently logged-in user
     * @returns MessageResponse Successful Response
     * @throws ApiError
     */
    public static startSlackUserSync(): CancelablePromise<MessageResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v3/sync/slack/user',
        });
    }

    /**
     * Force Slack Channel Sync
     * Forces a channel sync for a given channel
     * @param channelId
     * @returns MessageResponse Successful Response
     * @throws ApiError
     */
    public static forceSlackChannelSync(
        channelId: string,
    ): CancelablePromise<MessageResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v3/sync/slack/channel',
            query: {
                'channel_id': channelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Start Paper Sync
     * Starts a sync for the currently logged-in user
     * @returns MessageResponse Successful Response
     * @throws ApiError
     */
    public static startPaperSync(): CancelablePromise<MessageResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v3/sync/paper/user',
        });
    }

    /**
     * Start Meeting Sync
     * Starts a sync for the currently logged-in user
     * @returns MessageResponse Successful Response
     * @throws ApiError
     */
    public static startMeetingSync(): CancelablePromise<MessageResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v3/sync/meeting/user',
        });
    }

    /**
     * Start Gcal User Sync
     * @returns MessageResponse Successful Response
     * @throws ApiError
     */
    public static startGcalUserSync(): CancelablePromise<MessageResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v3/sync/gcal/user',
        });
    }

    /**
     * Subscribe
     * Adds sync subscriptions for the logged-in user. This will cause a sync to run every 24 hours, starting immediately.
     * @param syncType
     * @returns any Successful Response
     * @throws ApiError
     */
    public static subscribe(
        syncType: 'paper' | 'meeting' | 'slack' | 'gcal',
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v3/sync/subscribe',
            query: {
                'sync_type': syncType,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Unsubscribe
     * Removes sync subscriptions for the logged-in user
     * @param syncType
     * @returns any Successful Response
     * @throws ApiError
     */
    public static unsubscribe(
        syncType: 'paper' | 'meeting' | 'slack' | 'gcal',
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v3/sync/unsubscribe',
            query: {
                'sync_type': syncType,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Subscriptions
     * Gets the current sync subscriptions for the logged-in user
     * @returns UserSyncSubscription Successful Response
     * @throws ApiError
     */
    public static getSubscriptions(): CancelablePromise<Array<UserSyncSubscription>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/sync/subscriptions',
        });
    }

}
