import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
} from "react-router-dom";
import {RequireLoggedInUser} from "./contexts/UserProvider";
import {LoginPage} from "./Login";
import {APIReferencePage, ContextEngineAPIReferencePage} from "./DocumentationAPI";
import {ExamplesPage} from "./Examples";
import {DemosPage} from "./DemosPage";
import {DocumentationPage} from "./DocumentationPage";
import {AgentBasePage} from "./pages/agent/AgentBasePage";

import {PlaygroundPage} from "./pages";
import {
  AgentGettingStartedPage,
  AgentCeCliPage,
  AgentExamplesPage,
  AgentFaqPage,
  AgentPythonSdkPage,
  AgentTipsAndTricksPage,

  GettingStartedPage,
  AuthenticationPage,
  PythonSDKPage,
  DataSourcesPage,
  JupyterHubPage,
  WorkingWithLLMsPage,
  ContextEnginePage,
  FAQPage,
  OpenAIPage,
  OverviewPage,
  MlCapabilitiesPage,
  MakingASlackApp,
  NodeJSSDKPage,
} from "./pages/documentation";
import {AgentTelemetryPage} from "./AgentTelemetryPage";
import {Routes as AgentRoutes} from "./pages/agent";
import { RedirectExternal } from "./components/RedirectExternal";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        path="/"
        element={<Navigate to="/documentation" replace />}
      ></Route>
      <Route
        path="/documentation"
        element={
          <RequireLoggedInUser>
            <DocumentationPage />
          </RequireLoggedInUser>
        }
      >
        <Route path="agent">
          <Route path="getting-started" element={<AgentGettingStartedPage />} />
          <Route path="examples" element={<AgentExamplesPage />} />
          <Route path="ce-cli" element={<AgentCeCliPage />} />
          <Route path="faq" element={<AgentFaqPage />} />
          <Route path="python-sdk" element={<AgentPythonSdkPage />} />
          <Route path="tips-and-tricks" element={<AgentTipsAndTricksPage />} />
        </Route>
  
        <Route path="overview" element={<OverviewPage />} />
        <Route path="getting-started" element={<GettingStartedPage />} />
        <Route path="authentication" element={<AuthenticationPage />} />
        <Route path="python-sdk" element={<PythonSDKPage />} />
        <Route path="nodejs-sdk" element={<NodeJSSDKPage />} />
        <Route path="data-sources" element={<DataSourcesPage />} />
        <Route path="jupyterhub" element={<JupyterHubPage />} />
        <Route path="working-with-llms" element={<WorkingWithLLMsPage />} />
        <Route path="context-engine" element={<ContextEnginePage />} />
        <Route path="faqs" element={<FAQPage />} />
        <Route path="openai" element={<OpenAIPage />} />
        <Route path="ml-capabilities" element={<MlCapabilitiesPage />} />
        <Route path="slack-app" element={<MakingASlackApp />} />
        <Route path="" element={<Navigate to="/documentation/overview" />} />
      </Route>

      <Route
        path="/api-reference"
        element={
          <RequireLoggedInUser>
            <APIReferencePage />
          </RequireLoggedInUser>
        }
      />
      <Route
        path="/api-reference/agents"
        element={
          <RequireLoggedInUser>
            <ContextEngineAPIReferencePage />
          </RequireLoggedInUser>
        }
      />
      <Route
        path="/examples"
        element={
          <RequireLoggedInUser>
            <ExamplesPage />
          </RequireLoggedInUser>
        }
      />
      <Route
        path="/demos"
        element={
          <RequireLoggedInUser>
            <DemosPage />
          </RequireLoggedInUser>
        }
      />
      <Route
        path="/playground"
        element={
          <RequireLoggedInUser>
            <PlaygroundPage />
          </RequireLoggedInUser>
        }
      />

      <Route path="/connectors" element={
        <RequireLoggedInUser>
          <LoginPage />
        </RequireLoggedInUser>
      } />

      <Route path="/agent" element={
        <RequireLoggedInUser>
          <AgentBasePage />
        </RequireLoggedInUser>
      }>
        {AgentRoutes}
      </Route>

      <Route
        path="/agenttelemetry"
        element={
          <RequireLoggedInUser>
            <AgentTelemetryPage />
          </RequireLoggedInUser>
        }
      />

      <Route path="/login" element={<LoginPage />} />


      {/* These routes are for third party app integrations where we need to link to our policies with our own domain */}
      <Route
        path="/dropbox/raven"
        element={<RedirectExternal to="https://ampraven.com" />}
      />

      <Route
        path="/dropbox/privacy"
        element={<RedirectExternal to="https://www.dropbox.com/privacy" />}
      />

      <Route
        path="/dropbox/terms"
        element={<RedirectExternal to="https://www.dropbox.com/terms" />}
      />

      <Route
        path="/dropbox/contact"
        element={<RedirectExternal to="https://experience.dropbox.com/contact" />}
      />

      <Route
        path="/dropbox/zoom"
        element={<RedirectExternal to="https://ampraven.com" />}
      />
    </>
  ),
  {basename: "/"}
);
