import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { red } from "@mui/material/colors";
import { Header } from "../../Header";
import {
  getContextEngineAgentHelperService,
  getContextEngineAgentPlatformService,
  getContextEngineDefaultService,
} from "../../utilities";
import { useEffect, useState } from "react";
import {
  AgentDSLDefinition,
  AgentDSLDefinitionInput,
  BasicContextDefinition,
  ContextEngineAgent,
  DSLDocument,
  ExecutionDocument,
  ExecutionRequestInput,
} from "../../context_engine_client";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/default-highlight";
import { dark } from "react-syntax-highlighter/dist/esm/styles/hljs";

import { deepOrange, green } from "@mui/material/colors";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

import LoadingButton from "@mui/lab/LoadingButton";

import {InstructionGenerationResult, TestInstructionGenerator} from "../../components/agents/TestInstructionGenerator";
import {TestInstructionExecutor} from "../../components/agents/TestInstructionExecutor";

const ListCustomContext = ({
  customContextControl,
}: {
  customContextControl: number;
}) => {
  const [customContext, setCustomContext] = useState(
    [] as BasicContextDefinition[]
  );

  useEffect(() => {
    const contextEnginePlatformService = getContextEngineAgentPlatformService();
    contextEnginePlatformService.listBasicCustomContexts().then((result) => {
      console.log(result);
      setCustomContext(result);
    });
  }, []);

  return (
    <div>
      <List>
        {customContext.map((context) => (
          <ListItem key={context.id}>
            <ListItemText
              primary={context.name}
              secondary={context.description}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
};

const TestControl = ({ selectedAgentId }: { selectedAgentId: string }) => {
  const [dslResult, setDSLResult] = useState<undefined | InstructionGenerationResult>(undefined);
  const [userPrompt, setUserPrompt] = useState("");

  const handleUserPromptChange = (e: any) => {
    setUserPrompt(e.target.value);
  };

  return (
    <div style={{ margin: "20px" }}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h3">Test Your Agent</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={3} useFlexGap={true}>
            <TestInstructionGenerator
              agentId={selectedAgentId}
              onNewInstructions={(result) => setDSLResult(result)}
            />

            { dslResult?.success &&
              dslResult?.dslId &&
              <TestInstructionExecutor
                dslId={dslResult.dslId}
              />
            }
          </Stack>

        </AccordionDetails>
      </Accordion>
    </div>
  );
};

const CreateNewDSLControl = ({
  disabled,
  agentId,
  refreshDSLList,
}: {
  disabled: boolean;
  agentId: string;
  refreshDSLList: () => void;
}) => {
  const [userDSL, setUserDSL] = useState("");
  const [dslDefinition, setDSLDefinition] = useState([] as string[]);
  const [inspireInProgress, setInspireInProgress] = useState(false);
  const [addingDSLInProgress, setAddingDSLInProgress] = useState(false);

  const handleUserDSLChange = (e: any) => {
    setUserDSL(e.target.value);
  };

  const handleDSLDefinitionChange = (e: any) => {
    const lines = e.target.value.split("\n");
    setDSLDefinition(lines);
  };

  const handleAddDSL = (input: AgentDSLDefinitionInput) => {
    const contextEngineAgentPlatform = getContextEngineAgentPlatformService();
    setAddingDSLInProgress(true);
    contextEngineAgentPlatform
      .createAgentDsl(agentId, [input])
      .then((result) => {
        console.log(result);
        setUserDSL("");
        setDSLDefinition([]);
        refreshDSLList();
        setAddingDSLInProgress(false);
      })
      .catch((error) => {
        console.log(error);
        setAddingDSLInProgress(false);
      });
  };

  const handleInspireDSL = (input: AgentDSLDefinitionInput) => {
    const contextEngineAgentPlatform = getContextEngineAgentPlatformService();
    setInspireInProgress(true);
    contextEngineAgentPlatform
      .autocompleteAgentDsl(agentId, input)
      .then((result) => {
        console.log(result);
        setDSLDefinition([...result.ai]);
        setInspireInProgress(false);
      })
      .catch((error) => {
        console.log(error);
        setInspireInProgress(false);
      });
  };

  return (
    <div style={{ margin: "20px" }}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          disabled={disabled}
        >
          <Typography variant="h3">Create New DSL Entry</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ShowHelp />
          <Stack
            direction="column"
            sx={{ width: "100%", border: "1px solid grey", m: 1, p: 1 }}
          >
            <TextField
              sx={{ m: 2, width: "75%" }}
              label="User"
              value={userDSL}
              helperText="Write your example user query here..."
              onChange={(e) => handleUserDSLChange(e)}
            />
            <TextField
              sx={{ m: 2, width: "75%" }}
              label="DSL"
              value={dslDefinition.join("\n")}
              helperText="Write your AI response in terms of DSL here..."
              onChange={(e) => handleDSLDefinitionChange(e)}
              multiline
              fullWidth
              rows={4}
            />
          </Stack>
          <Stack direction="row">
            <LoadingButton
              sx={{ m: 2 }}
              variant="contained"
              onClick={() => handleAddDSL({ user: userDSL, ai: dslDefinition })}
              disabled={
                addingDSLInProgress ||
                inspireInProgress ||
                userDSL === "" ||
                dslDefinition.length === 0
              }
              loading={addingDSLInProgress}
            >
              Add DSL
            </LoadingButton>
            <LoadingButton
              sx={{ m: 2 }}
              variant="contained"
              onClick={() => handleInspireDSL({ user: userDSL, ai: [] })}
              disabled={
                addingDSLInProgress || inspireInProgress || userDSL === ""
              }
              loading={inspireInProgress}
            >
              Inspire DSL
            </LoadingButton>
            <Button
              sx={{ m: 2 }}
              variant="contained"
              disabled={
                addingDSLInProgress ||
                inspireInProgress ||
                (userDSL === "" && dslDefinition.length === 0)
              }
              onClick={() => {
                setUserDSL("");
                setDSLDefinition([]);
              }}
            >
              Clear
            </Button>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

const CreateNewAgentControl = ({
  handleAgentCreate,
}: {
  handleAgentCreate: () => void;
}) => {
  const [agentName, setAgentName] = useState("");
  const [agentDescription, setAgentDescription] = useState("");

  const handleCreateAgent = async () => {
    const platformService = getContextEngineAgentPlatformService();
    await platformService
      .createAgent({
        agent_name: agentName,
        agent_description: agentDescription,
      })
      .then((agent) => {
        setAgentName("");
        setAgentDescription("");
        handleAgentCreate();
      });
  };

  return (
    <div style={{ margin: "20px" }}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h3">Create New Agent</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack direction="column">
            <TextField
              sx={{ m: 2, width: "25%" }}
              label="Agent name"
              value={agentName}
              helperText="Short name for your agent."
              onChange={(e) => setAgentName(e.target.value)}
            />
            <TextField
              sx={{ m: 2, width: "75%" }}
              label="Agent description"
              helperText="Describe what this agent does..."
              value={agentDescription}
              onChange={(e) => setAgentDescription(e.target.value)}
            />
          </Stack>

          <Button
            sx={{ m: 2 }}
            variant="contained"
            onClick={() => handleCreateAgent()}
            disabled={agentName === "" || agentDescription === ""}
          >
            Create agent
          </Button>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

const ListContexts = ({
  handleContextChange,
}: {
  handleContextChange: (context: string) => void;
}) => {
  const [contexts, setContexts] = useState([] as string[]);

  const getContexts = async () => {
    const helperService = getContextEngineAgentHelperService();
    const contexts = await helperService.getBuiltInContexts();
    setContexts(contexts);
  };

  useEffect(() => {
    getContexts();
  }, []);

  return (
    <List>
      <Typography variant="h3">Built-in Contexts</Typography>
      <Box
        sx={{
          p: 2,
          border: "1px solid #ccc",
          borderRadius: "5px",
          backgroundColor: "#eee",
        }}
      >
        {contexts &&
          contexts.map((context) => (
            <ListItem key={context} disablePadding>
              <ListItemButton>
                <ListItemText
                  primary={context}
                  onClick={() => handleContextChange(context)}
                />
              </ListItemButton>
            </ListItem>
          ))}
      </Box>
    </List>
  );
};

const CodeSnippet = (props: { tool: string }) => {
  return (
    <SyntaxHighlighter language="python" style={dark}>
      {props.tool}
    </SyntaxHighlighter>
  );
};

const SelectedAgent = ({
  selectedAgent,
  controlEventUpdate: controlEventUpdateAt,
}: {
  selectedAgent: string;
  controlEventUpdate: number;
}) => {
  const [agent, setAgent] = useState({} as ContextEngineAgent);

  useEffect(() => {
    if (selectedAgent === "") {
      setAgent({} as ContextEngineAgent);
    } else {
      const platformService = getContextEngineAgentPlatformService();
      platformService.getAgent(selectedAgent).then((agent) => {
        setAgent(agent);
      });
    }
  }, [selectedAgent, controlEventUpdateAt]);

  const onCloneClicked = () => {
    const platformService = getContextEngineAgentPlatformService();
    platformService.cloneAgent(selectedAgent).then((agent) => {
      console.log(agent, "is cloned");
    });
  };

  const onMakePublicClicked = () => {
    const platformService = getContextEngineAgentPlatformService();
    platformService
      .updateAgent(selectedAgent, {
        agent_name: agent.agent_info.agent_name,
        agent_description: agent.agent_info.agent_description,
        url: agent.agent_info.url,
        logo_url: agent.agent_info.logo_url,
        coowners: agent.agent_info.coowners,
        context_ids: agent.agent_info.context_ids,
        public: true,
      })
      .then((agent) => {
        console.log(agent, "is made public");
        setAgent(agent);
      });
  };

  return agent?.agent_info === undefined ? (
    <div>
      <Typography variant="h3">Selected Agent</Typography>
      <Typography variant="h4">No agent selected</Typography>
    </div>
  ) : (
    <div>
      <Typography variant="h3">Selected Agent</Typography>
      <Card
        sx={{
          // minWidth: 275,
          textAlign: "left",
          m: 2,
          backgroundColor: "#eee",
          // maxWidth: 500,
        }}
      >
        <CardHeader
          title={agent.agent_info.agent_name}
          sx={{ color: "#000" }}
        />
        <CardContent>
          <Typography paragraph color="text.secondary">
            {agent.agent_info.agent_description}
          </Typography>
          <Stack direction="row" spacing={1}>
            <Typography>Created by</Typography>
            <Typography>{agent.agent_info.created_by}</Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Typography>at</Typography>
            <Typography>{agent.agent_info.created_at}</Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Typography>Last updated at</Typography>
            <Typography>{agent.agent_info.updated_at}</Typography>
          </Stack>
          {agent.agent_info.coowners !== undefined &&
          agent.agent_info.coowners.length > 0 ? (
            <Stack direction="row" spacing={1}>
              <Typography>Co-owners</Typography>
              <Typography>{agent.agent_info.coowners}</Typography>
            </Stack>
          ) : null}
          {agent.agent_info.context_ids !== undefined &&
          agent.agent_info.context_ids.length > 0 ? (
            <Stack direction="row" spacing={1}>
              <Typography>Imported contexts</Typography>
              <Typography>{agent.agent_info.context_ids}</Typography>
            </Stack>
          ) : null}
          <Stack direction="row" spacing={1}>
            Public:{" "}
            {agent.agent_info.public ? (
              <CheckCircleIcon sx={{ color: green[500] }} />
            ) : (
              <CancelIcon sx={{ color: deepOrange[500] }} />
            )}
          </Stack>
          <Stack direction="row" spacing={1}>
            Deployed:{" "}
            {agent.deployment_info.is_deployed ? (
              <CheckCircleIcon sx={{ color: green[500] }} />
            ) : (
              <CancelIcon sx={{ color: deepOrange[500] }} />
            )}
          </Stack>
          <Stack direction="row" spacing={1}>
            Published:{" "}
            {agent.deployment_info.is_published ? (
              <CheckCircleIcon sx={{ color: green[500] }} />
            ) : (
              <CancelIcon sx={{ color: deepOrange[500] }} />
            )}
          </Stack>
          <Stack direction="row" spacing={1}>
            Agent ID: {agent.agent_id}
          </Stack>
        </CardContent>

        <CardActions>
          <Button size="small" variant="contained" onClick={onCloneClicked}>
            Clone
          </Button>
          <Button
            size="small"
            variant="contained"
            onClick={onMakePublicClicked}
            disabled={agent.agent_info.public}
          >
            Public
          </Button>
        </CardActions>
      </Card>
    </div>
  );
};

const ListTypes = ({ selectedContext }: { selectedContext: string }) => {
  const [types, setTypes] = useState([] as string[]);

  useEffect(() => {
    if (selectedContext === "") {
      setTypes([] as string[]);
      return;
    }
    const helperService = getContextEngineAgentHelperService();
    helperService.getTypesInContext(selectedContext).then((types) => {
      setTypes(types);
    });
  }, [selectedContext]);

  return (
    <List>
      <Typography variant="h3">Types</Typography>
      <Box
        sx={{
          p: 2,
          border: "1px solid #ccc",
          borderRadius: "5px",
          backgroundColor: "#eee",
        }}
      >
        {types &&
          types.map((type) => (
            <ListItem key={type} disablePadding>
              <ListItemButton>
                <ListItemText
                  primary={
                    <React.Fragment>
                      <CodeSnippet tool={type} />
                    </React.Fragment>
                  }
                />
              </ListItemButton>
            </ListItem>
          ))}
      </Box>
    </List>
  );
};

const ListTools = ({ selectedContext }: { selectedContext: string }) => {
  const [tools, setTools] = useState([] as string[]);

  useEffect(() => {
    if (selectedContext === "") {
      setTools([] as string[]);
      return;
    }

    const helperService = getContextEngineAgentHelperService();
    helperService.getToolsInContext(selectedContext).then((tools) => {
      setTools(tools);
    });
  }, [selectedContext]);

  return (
    <List>
      <Typography variant="h3">Tools</Typography>
      <Box
        sx={{
          p: 2,
          border: "1px solid #ccc",
          borderRadius: "5px",
          backgroundColor: "#eee",
          minWidth: "800px",
          maxWidth: "1200px",
        }}
      >
        {tools &&
          tools.map((tool) => (
            <ListItem key={tool} disablePadding>
              <ListItemButton>
                <ListItemText
                  primary={
                    <React.Fragment>
                      <CodeSnippet tool={tool} />
                    </React.Fragment>
                  }
                />
              </ListItemButton>
            </ListItem>
          ))}
      </Box>
    </List>
  );
};

const ShowHelp = () => {
  const [selectedContext, setSelectedContext] = useState("");

  const handleContextChange = (context: string) => {
    console.log("Selected Context changed: " + context);
    setSelectedContext(context);
  };

  return (
    <Accordion sx={{ backgroundColor: "darkgray" }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h3">Help</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack direction="column" spacing={2}>
          <Stack direction="row" spacing={2}>
            <ListContexts handleContextChange={handleContextChange} />
            <ListTools selectedContext={selectedContext} />
            <ListTypes selectedContext={selectedContext} />
          </Stack>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export const AgentOldHomePage = () => {
  const [agentsLoading, setAgentsLoading] = useState(false);
  const [agents, setAgents] = useState([] as ContextEngineAgent[]);
  const [selectedAgent, setSelectedAgent] = useState("");
  const [agentDSLs, setAgentDSLs] = useState([] as AgentDSLDefinition[]);

  const [controlEventUpdate, setControlEventUpdate] = useState(0);
  const [customContextControlEventUpdate, setCustomContextControlEventUpdate] =
    useState(0);

  useEffect(() => {
    handleRun();
  }, []);

  const canEditAgent = () => {
    return (
      selectedAgent !== "" &&
      getAgent(selectedAgent)?.deployment_info.is_deployed === false &&
      getAgent(selectedAgent)?.deployment_info.is_published === false
    );
  };

  const isDeployed = () => {
    return (
      selectedAgent !== "" &&
      getAgent(selectedAgent)?.deployment_info.is_deployed === true
    );
  };

  const handleRun = async () => {
    setAgentsLoading(true);
    const contextEngineAgentPlatform = getContextEngineAgentPlatformService();
    contextEngineAgentPlatform
      .getAllAgents()
      .then((result) => {
        console.log(result);
        setAgentsLoading(false);
        setAgents(result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const displayAgents = () => {
    return (
      <div style={{ width: "50%", margin: "20px" }}>
        <Typography variant="h3">Available Agents</Typography>
        <Box
          sx={{
            p: 2,
            border: "1px solid #ccc",
            borderRadius: "5px",
            backgroundColor: "#eee",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {agents.map((agent) => (
            <Tooltip
              title={agent.agent_info.agent_description}
              key={agent.agent_id}
            >
              <Chip
                sx={{ m: 1 }}
                key={agent.agent_id}
                label={agent.agent_info.agent_name}
                onClick={(event) => handleAgentChange(event, agent.agent_id)}
                onDelete={(event) => handleDeleteAgent(event, agent.agent_id)}
                icon={<SupportAgentIcon />}
              />
            </Tooltip>
          ))}
        </Box>
      </div>
    );
  };

  const handleAgentChange = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    agent_id: string
  ) => {
    setSelectedAgent(agent_id);
    const contextEngineAgentPlatform = getContextEngineAgentPlatformService();
    contextEngineAgentPlatform
      .getAllAgentDsl(agent_id)
      .then((result: any): void => {
        console.log(result);
        setAgentDSLs(result);
      });
  };

  const handleDeleteAgent = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    agent_id: string
  ) => {
    const contextEngineAgentPlatform = getContextEngineAgentPlatformService();
    const result = await contextEngineAgentPlatform.deleteAgent(agent_id);
    console.log(result);
    handleRun();
  };

  const listDSLControl = () => {
    return (
      <div style={{ margin: "20px" }}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h3">Agent DSL</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {agentDSLs &&
              agentDSLs.map((dsl) => (
                <ListItem key={dsl.dsl_id} disablePadding>
                  <Stack
                    direction="column"
                    sx={{ width: "100%", border: "1px solid grey", m: 1, p: 1 }}
                  >
                    <TextField
                      id={"UserInput" + dsl.dsl_id}
                      sx={{ m: 1, width: "100%" }}
                      label="User"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder={dsl.user}
                      defaultValue={dsl.user}
                      disabled={!canEditAgent()}
                      onChange={(event) => {
                        dsl.user = event.target.value;
                      }}
                      multiline
                    />
                    <TextField
                      id={"AISuggestion" + dsl.dsl_id}
                      sx={{ m: 1, width: "100%" }}
                      label="DSL"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder={dsl.ai.join("\n")}
                      defaultValue={dsl.ai.join("\n")}
                      disabled={!canEditAgent()}
                      onChange={(event) => {
                        dsl.ai = event.target.value.split("\n");
                      }}
                      multiline
                      fullWidth
                    />
                    <Stack
                      direction="row"
                      spacing={2}
                      visibility={() => (canEditAgent() ? "visible" : "hidden")}
                    >
                      <Button onClick={(event) => handleUpdateDSL(event, dsl)}>
                        Update
                      </Button>
                      <Button onClick={(event) => handleDeleteDSL(event, dsl)}>
                        Delete
                      </Button>
                    </Stack>
                  </Stack>
                </ListItem>
              ))}
          </AccordionDetails>
        </Accordion>
      </div>
    );
  };

  const handleUpdateDSL = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    dsl: AgentDSLDefinition
  ) => {
    const contextEngineAgentPlatform = getContextEngineAgentPlatformService();
    const requestBody: AgentDSLDefinitionInput = {
      user: dsl.user,
      ai: dsl.ai,
    };

    const result = await contextEngineAgentPlatform.updateAgentDsl(
      selectedAgent,
      dsl.dsl_id,
      requestBody
    );
    console.log(result);
    handleRun();
  };

  const handleDeleteDSL = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    dsl: AgentDSLDefinition
  ) => {
    const contextEngineAgentPlatform = getContextEngineAgentPlatformService();
    const result = await contextEngineAgentPlatform.deleteAgentDsl(
      selectedAgent,
      dsl.dsl_id
    );
    console.log(result);
    handleRun();

    await refreshDSLList();
  };

  const refreshDSLList = () => {
    const contextEngineAgentPlatform = getContextEngineAgentPlatformService();
    contextEngineAgentPlatform.getAllAgentDsl(selectedAgent).then((result) => {
      console.log(result);
      setAgentDSLs(result);
    });
  };

  const getAgent = (agent_id: string) => {
    const agent = agents.find((agent) => agent.agent_id === agent_id);
    return agent;
  };

  const controlBanner = () => {
    if (!selectedAgent) {
      return <div>No Agent Selected</div>;
    }

    const agent = getAgent(selectedAgent);
    if (!agent) {
      return <div>No Agent Selected</div>;
    }

    const canShowDeploy = !agent.deployment_info.is_deployed;
    const canShowUnDeploy =
      agent.deployment_info.is_deployed && !agent.deployment_info.is_published;
    const canShowPublish =
      !agent.deployment_info.is_published && agent.deployment_info.is_deployed;
    const canShowUnPublish = agent.deployment_info.is_published;

    return (
      <div>
        {canShowUnDeploy && (
          <Button variant="contained" sx={{ m: 1 }} onClick={handleUnDeploy}>
            UnDeploy
          </Button>
        )}
        {canShowDeploy && (
          <Button variant="contained" sx={{ m: 1 }} onClick={handleDeploy}>
            Deploy
          </Button>
        )}
        {canShowUnPublish && (
          <Button variant="contained" sx={{ m: 1 }} onClick={handleUnPublish}>
            Unpublish
          </Button>
        )}
        {canShowPublish && (
          <Button variant="contained" sx={{ m: 1 }} onClick={handlePublish}>
            Publish
          </Button>
        )}
      </div>
    );
  };

  const handleDeploy = () => {
    const contextEngineAgentPlatform = getContextEngineAgentPlatformService();
    contextEngineAgentPlatform.deployAgent(selectedAgent).then((result) => {
      console.log(result);
      handleRun();
      setControlEventUpdate(controlEventUpdate + 1);
    });
  };

  const handleUnDeploy = () => {
    const contextEngineAgentPlatform = getContextEngineAgentPlatformService();
    contextEngineAgentPlatform.undeployAgent(selectedAgent).then((result) => {
      console.log(result);
      handleRun();
      setControlEventUpdate(controlEventUpdate + 1);
    });
  };

  const handlePublish = () => {
    const contextEngineAgentPlatform = getContextEngineAgentPlatformService();
    contextEngineAgentPlatform.publishAgent(selectedAgent).then((result) => {
      console.log(result);
      handleRun();
      setControlEventUpdate(controlEventUpdate + 1);
    });
  };

  const handleUnPublish = () => {
    const contextEngineAgentPlatform = getContextEngineAgentPlatformService();
    contextEngineAgentPlatform.unpublishAgent(selectedAgent).then((result) => {
      console.log(result);
      handleRun();
      setControlEventUpdate(controlEventUpdate + 1);
    });
  };




  //  console.log("rendering", selectedAgent, newDSLResponse);

  return (
    <div>
      <Header />
      <div style={{ paddingTop: "50px" }}>
        <Typography variant="h1">Agent Ecosystem</Typography>
      </div>
      <div>
        {agentsLoading && <CircularProgress size={24} />}
        <Stack direction="row" spacing={2}>
          {!agentsLoading && displayAgents()}
          <SelectedAgent
            selectedAgent={selectedAgent}
            controlEventUpdate={controlEventUpdate}
          />
        </Stack>
        <CreateNewAgentControl handleAgentCreate={handleRun} />
        {listDSLControl()}
        <CreateNewDSLControl
          disabled={!canEditAgent()}
          agentId={selectedAgent}
          refreshDSLList={refreshDSLList}
        />
        {controlBanner()}
        {selectedAgent !== "" &&
          getAgent(selectedAgent)?.deployment_info.is_deployed && (
            <TestControl selectedAgentId={selectedAgent} />
          )}
        {/* <ListCustomContext customContextControl={customContextControlEventUpdate} /> */}
      </div>
    </div>
  );
};
