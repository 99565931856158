import { ReactNode, useState, } from "react";
import { Header } from "../../Header";
import { Grid, ListSubheader, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { getPaperService, getOrgService, getSlackService, getGcalService } from "../../utilities";
import { Link } from "react-router-dom";

import { DefaultPlayground } from "./DefaultPlayground";
import { CreateTextCompletionRequest, CreateTextSummaryRequest, CreateTextEditRequest, ListModelsRequest, MlcapabilitiesService, CreateImageDescriptionRequest, CreateTextEmbeddingsRequest, SearchInput, CreateTextTranslationRequest, CreateImageEmbeddingRequest, CreateTextQuestionAnswerRequest } from "../../ampplat_client";
import { MlCapabilitiesPlayground } from "./MlCapabilitiesPlayground";
import { ConnectToDropbox, ConnectToGoogle, ConnectToSlack } from "../../components/Connectors";
import moment from "moment";

const MULTI_ANSWER_SEPARATOR = "\n---------------------------------\n";

type Endpoint = {
    name: string,
    group: string,
    link: string,
    Component: any
};

const endpoints: Endpoint[] = [
    {
        group: 'Paper',
        name: 'List Paper Docs in Home',
        link: '/api-reference#tag/paper/operation/list_all_paper_documents_in_home',
        Component: () => {
            const onRun = async () => {
                const result = await getPaperService().listAllPaperDocumentsInHome();
                const output = JSON.stringify(result, null, 2);
                return { result, output };
            };

            return <DefaultPlayground acceptsInput={false} onRun={onRun} initialShowRaw={true} />
        }
    },
    {
        group: 'Paper',
        name: 'List Paper Docs Shared with Me',
        link: '/api-reference#tag/paper/operation/list_paper_documents_shared_with_me',
        Component: () => {
            const onRun = async () => {
                const result = await getPaperService().listPaperDocumentsSharedWithMe();
                const output = JSON.stringify(result, null, 2);
                return { result, output };
            };

            return <DefaultPlayground acceptsInput={false} onRun={onRun} initialShowRaw={true} />
        }
    },
    {
        name: 'Answer Question from Paper',
        group: 'Paper',
        link: "/api-reference#tag/paper/operation/answer_question_from_paper",
        Component: () => {
            const onRun = async ({ query }: { query: string }) => {
                const result = await getPaperService().answerQuestionFromPaper(query);
                const output = result.map((answer) => `Answer: ${answer.answer}\n\nFrom File: ${answer.source.title}`).join(MULTI_ANSWER_SEPARATOR);
                return { result, output };
            };

            return <DefaultPlayground acceptsInput onRun={onRun} />
        }
    },
    {
        name: 'Search Paper Docs by Content',
        group: 'Paper',
        link: "/api-reference#tag/paper/operation/search_paper_documents_by_content",
        Component: () => {
            const onRun = async ({ query }: { query: string }) => {
                const result = await getPaperService().searchPaperDocumentsByContent(query);
                const output = result.map((doc) => `Title: ${doc.title}\n\nContent:\n${doc.content}`).join(MULTI_ANSWER_SEPARATOR);
                return { result, output };
            };

            return <DefaultPlayground acceptsInput onRun={onRun} />
        }
    },
    {
        name: 'Search Paper Docs by Title',
        group: 'Paper',
        link: '/api-reference#tag/paper/operation/search_paper_documents_by_title',
        Component: () => {
            const onRun = async ({ query }: { query: string }) => {
                const result = await getPaperService().searchPaperDocumentsByTitle(query);
                const output = JSON.stringify(result, null, 2);
                return { result, output };
            };

            return <DefaultPlayground acceptsInput onRun={onRun} initialShowRaw={true} />
        }
    },
    {
        name: 'Troubleshoot Paper',
        group: 'Paper',
        link: '/api-reference#tag/slack/operation/paper_troubleshooting',
        Component: () => {
            const onRun = async () => {
                const result = await getPaperService().paperTroubleshooting();
                const output = JSON.stringify(result, null, 2)
                return { result, output };
            }
            return <DefaultPlayground acceptsInput={false} onRun={onRun} initialShowRaw={true} />
        }
    },
    {
        name: 'Search Employees by First Name',
        group: 'Org',
        link: '/api-reference#tag/org/operation/org_search_employees_by_first_name',
        Component: () => {
            const onRun = async ({ query }: { query: string }) => {
                const result = await getOrgService().orgSearchEmployeesByFirstName(query);
                const output = JSON.stringify(result, null, 2)
                return { result, output };
            }
            return <DefaultPlayground acceptsInput onRun={onRun} initialShowRaw={true} />
        }
    },
    {
        name: 'Search Employees by Last Name',
        group: 'Org',
        link: '/api-reference#tag/org/operation/org_search_employees_by_last_name',
        Component: () => {
            const onRun = async ({ query }: { query: string }) => {
                const result = await getOrgService().orgSearchEmployeesByLastName(query);
                const output = JSON.stringify(result, null, 2)
                return { result, output };
            }
            return <DefaultPlayground acceptsInput onRun={onRun} initialShowRaw={true} />
        }
    },
    {
        name: 'Search Employees by Full Name',
        group: 'Org',
        link: '/api-reference#tag/org/operation/org_search_employees_by_full_name',
        Component: () => {
            const onRun = async ({ query }: { query: string }) => {
                const result = await getOrgService().orgSearchEmployeesByFullName(query);
                const output = JSON.stringify(result, null, 2)
                return { result, output };
            }
            return <DefaultPlayground acceptsInput onRun={onRun} initialShowRaw={true} />
        }
    },
    {
        name: 'Search Employees by Email',
        group: 'Org',
        link: '/api-reference#tag/org/operation/org_search_employees_by_email',
        Component: () => {
            const onRun = async ({ query }: { query: string }) => {
                const result = await getOrgService().orgSearchEmployeesByEmail(query);
                const output = JSON.stringify(result, null, 2)
                return { result, output };
            }
            return <DefaultPlayground acceptsInput onRun={onRun} initialShowRaw={true} />
        }
    },
    {
        name: 'Search Employees by Team',
        group: 'Org',
        link: '/api-reference#tag/org/operation/org_search_employees_by_team',
        Component: () => {
            const onRun = async ({ query }: { query: string }) => {
                const result = await getOrgService().orgSearchEmployeesByTeam(query);
                const output = JSON.stringify(result, null, 2);
                return { result, output };
            }
            return <DefaultPlayground acceptsInput onRun={onRun} initialShowRaw={true}/>
        }
    },
    {
        name: 'Search Slack Messages',
        group: 'Slack',
        link: '/api-reference#tag/slack/operation/slack_search_conversations',
        Component: () => {
            const onRun = async ({ query }: { query: string }) => {
                const searchInput : SearchInput = {
                    query
                }
                const result = await getSlackService().slackSearchConversations(searchInput);
                const output = result.map((conversation) => conversation.text).join(MULTI_ANSWER_SEPARATOR);
                return { result, output };
            }
            return <DefaultPlayground acceptsInput onRun={onRun} />
        }
    },
    {
        name: 'List your Slack Channels',
        group: 'Slack',
        link: '/api-reference#tag/slack/operation/slack_list_channels',
        Component: () => {
            const onRun = async () => {
                const result = await getSlackService().slackListChannels();
                const output = JSON.stringify(result, null, 2)
                return { result, output };
            }
            return <DefaultPlayground acceptsInput={false} onRun={onRun} initialShowRaw={true} />
        }
    },
    {
        name: 'Answer Question from Slack',
        group: 'Slack',
        link: '/api-reference#tag/slack/operation/slack_answer_question',
        Component: () => {
            const onRun = async ({ query }: { query: string }) => {
                const result = await getSlackService().slackAnswerQuestion(query);
                const output = result.map((answer) => `Answer: ${answer.answer}\n\nContext:\n\n${answer.context}`).join(MULTI_ANSWER_SEPARATOR);
                return { result, output };
            }
            return <DefaultPlayground acceptsInput onRun={onRun} />
        }
    },
    {
        name: 'Troubleshoot Slack',
        group: 'Slack',
        link: '/api-reference#tag/slack/operation/slack_troubleshooting',
        Component: () => {
            const onRun = async () => {
                const result = await getSlackService().slackTroubleshooting();
                const output = JSON.stringify(result, null, 2)
                return { result, output };
            }
            return <DefaultPlayground acceptsInput={false} onRun={onRun} initialShowRaw={true} />
        }
    },
    {
        name: 'List Calendar Events',
        group: 'Google Calendar',
        link: '/api-reference#tag/gcal/operation/gcal_list',
        Component: () => {
            const onRun = async () => {
                const startDate = new Date()
                const endDate = new Date().setDate(startDate.getDate() + 7);
                
                const result = await getGcalService().gcalList(moment(startDate).format("YYYY-MM-DDTHH:mm:ss"), moment(endDate).format("YYYY-MM-DDTHH:mm:ss"));
                const output = JSON.stringify(result, null, 2)
                return { result, output };
            }
            return <DefaultPlayground acceptsInput={false} onRun={onRun} initialShowRaw={true} />
        }
    },
    {
        name: 'Troubleshoot Google Calendar',
        group: 'Google Calendar',
        link: '/api-reference#tag/gcal/operation/gcal_troubleshooting',
        Component: () => {
            const onRun = async () => {
                const result = await getGcalService().gcalTroubleshooting();
                const output = JSON.stringify(result, null, 2)
                return { result, output };
            }
            return <DefaultPlayground acceptsInput={false} onRun={onRun} initialShowRaw={true} />
        }
    },
    {
        name: 'Create Text Completion',
        group: 'ML Capabilities',
        link: '/api-reference#tag/mlcapabilities/operation/create_text_completion',
        Component: () => {
            const onRun = async ({ text, model, backend, maxLength }: { text: string, model: string, backend: string, maxLength: number }) => {
                const input: CreateTextCompletionRequest = {
                    model_info: {
                        model_name: model,
                        model_source: backend
                    },
                    prompts: [text],
                    model_params: {
                        "max_length": {
                            "config_type": {
                                ".tag": "int32_config",
                                "int32_config": maxLength
                            }
                        }
                    }
                };
                const result = await MlcapabilitiesService.createTextCompletion(input);
                const output = result.generated_texts["0"].text;
                return { result, output };
            }
            return <MlCapabilitiesPlayground type="text/completion" onRun={onRun} />
        }
    },
    {
        name: 'Create Text Summary',
        group: 'ML Capabilities',
        link: '/api-reference#tag/mlcapabilities/operation/create_text_summary',
        Component: () => {
            const onRun = async ({ text, model, backend, maxLength }: { text: string, instructions: string, model: string, backend: string, maxLength: number }) => {
                const input: CreateTextSummaryRequest = {
                    model_info: {
                        model_name: model,
                        model_source: backend
                    },
                    input_texts: [text],
                    model_params: {
                        "max_length": {
                            "config_type": {
                                ".tag": "int32_config",
                                "int32_config": maxLength
                            }
                        }
                    }
                };
                const result = await MlcapabilitiesService.createTextSummary(input);
                const output = result.summaries[0].text.join("\n");
                return { result, output };
            }
            return <MlCapabilitiesPlayground type="text/summary" onRun={onRun} />
        }
    },
    {
        name: 'Create Text Edit',
        group: 'ML Capabilities',
        link: '/api-reference#tag/mlcapabilities/operation/create_text_edit',
        Component: () => {
            const onRun = async ({ text, instructions, model, backend, maxLength }: { text: string, instructions: string, model: string, backend: string, maxLength: number }) => {
                const input: CreateTextEditRequest = {
                    model_info: {
                        model_name: model,
                        model_source: backend
                    },
                    input_texts: [text],
                    instructions: [instructions],
                    model_params: {
                        "max_length": {
                            "config_type": {
                                ".tag": "int32_config",
                                "int32_config": maxLength
                            }
                        }
                    }
                };
                const result = await MlcapabilitiesService.createTextEdit(input);
                const output = result.edited_text[0].text;
                return { result, output };
            }
            return <MlCapabilitiesPlayground type="text/edit" onRun={onRun} />
        }
    },
    {
        name: 'Create Text Translation',
        group: 'ML Capabilities',
        link: '/api-reference#tag/mlcapabilities/operation/create_text_translation',
        Component: () => {
            const onRun = async ({ model, backend, text }: { model: string, backend: string, text: string }) => {
                const input: CreateTextTranslationRequest = {
                    model_info: {
                        model_name: model,
                        model_source: backend
                    },
                    input_texts: [text],
                    src_lang: "english",
                    dest_langs: ["french"]
                };
                const result = await MlcapabilitiesService.createTextTranslation(input);
                const output = result.translations[0].text;
                return { result, output };
            }
            return <MlCapabilitiesPlayground type="text/translation" onRun={onRun} showSliders={false} />
        }
    },
    {
        name: 'Answer Question',
        group: 'ML Capabilities',
        link: '/api-reference#tag/mlcapabilities/operation/create_text_question_answer',
        Component: () => {
            const onRun = async ({ model, backend, text, instructions }: { model: string, backend: string, text: string, instructions: string }) => {
                const input: CreateTextQuestionAnswerRequest = {
                    model_info: {
                        model_name: model,
                        model_source: backend
                    },
                    question: text
                };
                // using "instructions" as the file id
                if (instructions) {
                    input.file_ids = [
                        {".tag": "file_id", "file_id": instructions}
                    ]
                }
                const result = await MlcapabilitiesService.createTextQuestionAnswer(input);
                const output = result.answer;
                return { result, output };
            }
            return <MlCapabilitiesPlayground type="text/qa" onRun={onRun} showSliders={false} />
        }
    },
    {
        name: 'Create Text Embeddings',
        group: 'ML Capabilities',
        link: '/api-reference#tag/mlcapabilities/operation/create_text_embedding',
        Component: () => {
            const onRun = async ({ model, backend, text }: { model: string, backend: string, text: string }) => {
                const input: CreateTextEmbeddingsRequest = {
                    model_info: {
                        model_name: model,
                        model_source: backend
                    },
                    input_texts: [text],
                    model_params: {}
                };
                const result = await MlcapabilitiesService.createTextEmbeddings(input);
                const output = result.results[0].values;
                return { result, output };
            }
            return <MlCapabilitiesPlayground type="text/embeddings" onRun={onRun} showSliders={false} />
        }
    },
    {
        name: 'Describe Image',
        group: 'ML Capabilities',
        link: '/api-reference#tag/mlcapabilities/operation/create_image_description',
        Component: () => {
            const onRun = async ({ model, backend, text, imageBytes }: { model: string, backend: string, text: string, imageBytes: string }) => {
                const input: CreateImageDescriptionRequest = {
                    model_info: {
                        model_name: model,
                        model_source: backend
                    },
                    image_bytes: imageBytes,
                    prompts: [text],
                    model_params: {}
                };
                const result = await MlcapabilitiesService.createImageDescription(input);
                const output = result.generated_text[0];
                return { result, output };
            }
            return <MlCapabilitiesPlayground type="image/describe" onRun={onRun} showSliders={false} />
        }
    },
    // {
    //     name: 'Create Image Embeddings',
    //     group: 'ML Capabilities',
    //     link: '/api-reference#tag/mlcapabilities/operation/create_image_embedding',
    //     Component: () => {
    //         const onRun = async ({ model, backend, imageBytes }: { model: string, backend: string, imageBytes: string }) => {
    //             const input: CreateImageEmbeddingRequest = {
    //                 model_info: {
    //                     model_name: model,
    //                     model_source: backend
    //                 },
    //                 image_bytes: imageBytes,
    //                 model_params: {}
    //             };
    //             const result = await MlcapabilitiesService.createImageEmbeddings(input);
    //             const output = result.result.values;
    //             return { result, output };
    //         }
    //         return <MlCapabilitiesPlayground type="image/embeddings" onRun={onRun} showSliders={false} />
    //     }
    // },
    {
        name: 'List Models',
        group: 'ML Capabilities',
        link: '/api-reference#tag/mlcapabilities/operation/list_models',
        Component: () => {
            const onRun = async () => {
                const input: ListModelsRequest = {};
                const result = await MlcapabilitiesService.listModels(input);
                const output = JSON.stringify(result.models, null, 2);
                return { result, output };
            }
            return <DefaultPlayground acceptsInput={false} onRun={onRun} />
        }
    },
]

// group endpoints so we can add subheadings to the list for them
function groupEndpoints(endpoints: Endpoint[]): Record<string, { endpoint: Endpoint, index: number }[]> {
    return endpoints.reduce((indexedEndpoints: Record<string, { endpoint: Endpoint, index: number }[]>, endpoint: Endpoint, index: number) => {
        const { group } = endpoint;
        indexedEndpoints[group] = indexedEndpoints[group] || [];
        indexedEndpoints[group].push({ endpoint, index });
        return indexedEndpoints;
    }, {});
}

const groupedEndpoints = groupEndpoints(endpoints);

export const PlaygroundPage = () => {
    const [selectedEndpoint, setSelectedEndpoint] = useState('0');

    const handleEndpointChange = (event: SelectChangeEvent) => {
        setSelectedEndpoint(event.target.value);
    }

    const endpoint = endpoints[Number(selectedEndpoint)];
    const PlaygroundComponent = endpoint.Component;

    // add a Subheader for each group and then menu items for each endpoint in the group
    const endpointSelectItems: ReactNode[] = [];
    for (let [groupName, group] of Object.entries(groupedEndpoints)) {
        endpointSelectItems.push(<ListSubheader key={groupName}>{groupName}</ListSubheader>);
        for (let { endpoint, index } of group) {
            endpointSelectItems.push(<MenuItem key={index} value={index}>{endpoint.name}</MenuItem>)
        }
    }

    let EndpointSyncSuggestion = <></>;
    switch(endpoint.group) {
        case "Paper":
            EndpointSyncSuggestion = <div>
                <Typography gutterBottom>Not seeing your Paper docs here?<br/>Try running a Sync.</Typography>
                <ConnectToDropbox />
            </div>
            break;
        case "Slack":
            EndpointSyncSuggestion = <div>
                <Typography gutterBottom>Not seeing your Slack messages?<br/>Make sure you're connected then run a Sync.</Typography>
                <ConnectToSlack />
            </div>
            break;
        case "Google Calendar":
            EndpointSyncSuggestion = <div>
                <Typography gutterBottom>Not seeing your Google Calendar events?<br/>Make sure you're connected then run a Sync.</Typography>
                <ConnectToGoogle />
            </div>           
    }

    return (
        <div>
            <Header />
            <Grid style={{ paddingTop: '50px' }} container direction='column' justifyContent='center' alignItems='center'>
                <Typography variant="h1">Playground</Typography>
                <Grid container rowSpacing={5} direction='column'>
                    <Grid container item columnSpacing={10} justifyContent='center' alignItems='center'>

                        <Grid item xs='auto'>
                            <Select
                                autoWidth
                                value={selectedEndpoint}
                                onChange={handleEndpointChange}
                            >
                                {endpointSelectItems}
                            </Select>
                            <div style={{ paddingBottom: "50px", marginTop: "8px" }}>
                                <Link to={endpoint.link}>View API Reference</Link>
                            </div>

                        </Grid>
                    </Grid>
                    <Grid container item direction='column' rowSpacing={5}>
                        <PlaygroundComponent />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {EndpointSyncSuggestion}
                    <Typography sx={{marginTop:"8px"}}>Looking for something else? Visit <Link to="https://api.dbxos.com/v3/docs" target="_blank">our FastAPI docs.</Link></Typography>
                </Grid>
            </Grid>
        </div>
    );
};