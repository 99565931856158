/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateImageDescriptionRequest } from '../models/CreateImageDescriptionRequest';
import type { CreateImageDescriptionResponse } from '../models/CreateImageDescriptionResponse';
import type { CreateImageEmbeddingRequest } from '../models/CreateImageEmbeddingRequest';
import type { CreateImageEmbeddingResponse } from '../models/CreateImageEmbeddingResponse';
import type { CreateTextCompletionRequest } from '../models/CreateTextCompletionRequest';
import type { CreateTextCompletionResponse } from '../models/CreateTextCompletionResponse';
import type { CreateTextEditRequest } from '../models/CreateTextEditRequest';
import type { CreateTextEditResponse } from '../models/CreateTextEditResponse';
import type { CreateTextEmbeddingsRequest } from '../models/CreateTextEmbeddingsRequest';
import type { CreateTextEmbeddingsResponse } from '../models/CreateTextEmbeddingsResponse';
import type { CreateTextQuestionAnswerRequest } from '../models/CreateTextQuestionAnswerRequest';
import type { CreateTextQuestionAnswerResponse } from '../models/CreateTextQuestionAnswerResponse';
import type { CreateTextSummaryRequest } from '../models/CreateTextSummaryRequest';
import type { CreateTextSummaryResponse } from '../models/CreateTextSummaryResponse';
import type { CreateTextTranslationRequest } from '../models/CreateTextTranslationRequest';
import type { CreateTextTranslationResponse } from '../models/CreateTextTranslationResponse';
import type { ListModelsRequest } from '../models/ListModelsRequest';
import type { ListModelsResponse } from '../models/ListModelsResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MlcapabilitiesService {

    /**
     * Create Text Summary
     * Create text summary from raw text input, using the specified model.
     *
     * Example Input:
     * ```
     * {
         * "model_info": {
             * "model_name": "flan-ul2",
             * "model_source": "huggingface"
             * },
             * "input_texts": [
                 * "Dropbox brings files together in one central place by creating a special folder on the user's computer.The contents of these folders are synchronized to Dropbox's servers and to other computers and devices where the user has installed Dropbox, keeping the same files up-to-date on all devices. Dropbox uses a freemium business model, where users are offered a free account with set storage size, with paid subscriptions available that offer more capacity and additional features. Dropbox Basic users are given two gigabytes of free storage space.Dropbox offers computer apps for Microsoft Windows, Apple macOS, and Linux computers, and mobile apps for iOS, Android, and Windows Phone smartphones and tablets.In March 2013, the company acquired Mailbox, a popular email app, and in April 2014, the company introduced Dropbox Carousel, a photo and video gallery app. Both Mailbox and Carousel were shut down in December 2015, with key features from both apps implemented into the regular Dropbox service. In October 2015, it officially announced Dropbox Paper, its collaborative document editor."
                 * ],
                 * "model_params": {
                     * "max_length": {
                         * "config_type": {
                             * "tag": "int32_config",
                             * "int32_config": 50
                             * }
                             * }
                             * }
                             * }
                             * ```
                             * backend_name is optional and it can be one of ( huggingface, openai ), default is huggingface
                             * @param requestBody
                             * @returns CreateTextSummaryResponse Successful Response
                             * @throws ApiError
                             */
                            public static createTextSummary(
                                requestBody: CreateTextSummaryRequest,
                            ): CancelablePromise<CreateTextSummaryResponse> {
                                return __request(OpenAPI, {
                                    method: 'POST',
                                    url: '/v3/mlcapabilities/text/summary',
                                    body: requestBody,
                                    mediaType: 'application/json',
                                    errors: {
                                        422: `Validation Error`,
                                    },
                                });
                            }

                            /**
                             * Create Text Completion
                             * Create text completion from prompt input, using the specified model.
                             *
                             * Example input:
                             * ```
                             * {
                                 * "model_info": {
                                     * "model_name": "flan-alpaca-xxl"
                                     * },
                                     * "prompts": [
                                         * "The following is a list of companies and the categories they fall into:\nApple, Facebook, Fedex\nCategory:"
                                         * ]
                                         * }
                                         * ```
                                         * @param requestBody
                                         * @returns CreateTextCompletionResponse Successful Response
                                         * @throws ApiError
                                         */
                                        public static createTextCompletion(
                                            requestBody: CreateTextCompletionRequest,
                                        ): CancelablePromise<CreateTextCompletionResponse> {
                                            return __request(OpenAPI, {
                                                method: 'POST',
                                                url: '/v3/mlcapabilities/text/completion',
                                                body: requestBody,
                                                mediaType: 'application/json',
                                                errors: {
                                                    422: `Validation Error`,
                                                },
                                            });
                                        }

                                        /**
                                         * Create Text Edit
                                         * Create text edit from prompt input, using the specified openAI model.
                                         *
                                         * Example Input:
                                         * ```
                                         * {
                                             * "model_info": {
                                                 * "model_name": "text-davinci-edit-001",
                                                 * "model_source": "openai"
                                                 * },
                                                 * "input_texts": ["She the market not went"],
                                                 * "instructions": ["Fix this to grammatically correct standard English without changing the meaning"]
                                                 * }
                                                 * ```
                                                 * @param requestBody
                                                 * @returns CreateTextEditResponse Successful Response
                                                 * @throws ApiError
                                                 */
                                                public static createTextEdit(
                                                    requestBody: CreateTextEditRequest,
                                                ): CancelablePromise<CreateTextEditResponse> {
                                                    return __request(OpenAPI, {
                                                        method: 'POST',
                                                        url: '/v3/mlcapabilities/text/edit',
                                                        body: requestBody,
                                                        mediaType: 'application/json',
                                                        errors: {
                                                            422: `Validation Error`,
                                                        },
                                                    });
                                                }

                                                /**
                                                 * Create Text Translation
                                                 * @param requestBody
                                                 * @returns CreateTextTranslationResponse Successful Response
                                                 * @throws ApiError
                                                 */
                                                public static createTextTranslation(
                                                    requestBody: CreateTextTranslationRequest,
                                                ): CancelablePromise<CreateTextTranslationResponse> {
                                                    return __request(OpenAPI, {
                                                        method: 'POST',
                                                        url: '/v3/mlcapabilities/text/translation',
                                                        body: requestBody,
                                                        mediaType: 'application/json',
                                                        errors: {
                                                            422: `Validation Error`,
                                                        },
                                                    });
                                                }

                                                /**
                                                 * Create Text Embeddings
                                                 * @param requestBody
                                                 * @returns CreateTextEmbeddingsResponse Successful Response
                                                 * @throws ApiError
                                                 */
                                                public static createTextEmbeddings(
                                                    requestBody: CreateTextEmbeddingsRequest,
                                                ): CancelablePromise<CreateTextEmbeddingsResponse> {
                                                    return __request(OpenAPI, {
                                                        method: 'POST',
                                                        url: '/v3/mlcapabilities/text/embedding',
                                                        body: requestBody,
                                                        mediaType: 'application/json',
                                                        errors: {
                                                            422: `Validation Error`,
                                                        },
                                                    });
                                                }

                                                /**
                                                 * Create Text Question Answer
                                                 * Answer a question, using Dropbox file ids for additional context to answer the question.
                                                 * @param requestBody
                                                 * @returns CreateTextQuestionAnswerResponse Successful Response
                                                 * @throws ApiError
                                                 */
                                                public static createTextQuestionAnswer(
                                                    requestBody: CreateTextQuestionAnswerRequest,
                                                ): CancelablePromise<CreateTextQuestionAnswerResponse> {
                                                    return __request(OpenAPI, {
                                                        method: 'POST',
                                                        url: '/v3/mlcapabilities/text/qa',
                                                        body: requestBody,
                                                        mediaType: 'application/json',
                                                        errors: {
                                                            422: `Validation Error`,
                                                        },
                                                    });
                                                }

                                                /**
                                                 * Create Image Description
                                                 * Describe the contents of an image, either by passing a Dropbox file id or the image bytes (as a base64 encoded string in JSON)
                                                 * @param requestBody
                                                 * @returns CreateImageDescriptionResponse Successful Response
                                                 * @throws ApiError
                                                 */
                                                public static createImageDescription(
                                                    requestBody: CreateImageDescriptionRequest,
                                                ): CancelablePromise<CreateImageDescriptionResponse> {
                                                    return __request(OpenAPI, {
                                                        method: 'POST',
                                                        url: '/v3/mlcapabilities/image/description',
                                                        body: requestBody,
                                                        mediaType: 'application/json',
                                                        errors: {
                                                            422: `Validation Error`,
                                                        },
                                                    });
                                                }

                                                /**
                                                 * Create Image Embeddings
                                                 * @param requestBody
                                                 * @returns CreateImageEmbeddingResponse Successful Response
                                                 * @throws ApiError
                                                 */
                                                public static createImageEmbeddings(
                                                    requestBody: CreateImageEmbeddingRequest,
                                                ): CancelablePromise<CreateImageEmbeddingResponse> {
                                                    return __request(OpenAPI, {
                                                        method: 'POST',
                                                        url: '/v3/mlcapabilities/image/embedding',
                                                        body: requestBody,
                                                        mediaType: 'application/json',
                                                        errors: {
                                                            422: `Validation Error`,
                                                        },
                                                    });
                                                }

                                                /**
                                                 * List Models
                                                 * List all available deployed models in k-serve and their info or info of the specified models in the req.
                                                 *
                                                 * Example input to get specific model info:
                                                 * ```
                                                 * {
                                                     * "model_names": ["flan-alpaca-xxl", "flan-alpaca-ul2"]
                                                     * }
                                                     * ```
                                                     * To list all the models provided by this API, leave input empty `{}`
                                                     * @param requestBody
                                                     * @returns ListModelsResponse Successful Response
                                                     * @throws ApiError
                                                     */
                                                    public static listModels(
                                                        requestBody?: ListModelsRequest,
                                                    ): CancelablePromise<ListModelsResponse> {
                                                        return __request(OpenAPI, {
                                                            method: 'POST',
                                                            url: '/v3/mlcapabilities/list_models',
                                                            body: requestBody,
                                                            mediaType: 'application/json',
                                                            errors: {
                                                                422: `Validation Error`,
                                                            },
                                                        });
                                                    }

                                                }
