import {Box, Card, CardContent, Divider, Link, List, ListItem, ListItemIcon, Stack, Typography} from "@mui/material";
import {Title} from "../../components/Title";
import {Markdown} from "../../components/Markdown";
import {dedent} from "../../utilities";
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import GroupIcon from '@mui/icons-material/Group';

export type AgentHomePageProps = {
};

const HomePageCard = ({content}: {content: string}) => {
  return <></>;
};

export const AgentHomePage = ({}: AgentHomePageProps) => {
  const cardContents: {content: string}[] = [
    {
      content: `
        # Getting Started

        Check out our documentation here to get started
      `,
    },
  ];

  return (
    <Stack spacing={3} useFlexGap={true}>
      <Title value="Agent Builder" />

      <Typography paragraph>
        Build personalized AI agents to do tasks for you, in just a few minutes! You do not have to be technical to be able to build an agent! 
      </Typography>

      <Typography paragraph>
        Think of an AI agent like a robot assistant. It can learn things on its own so it can help you with different tasks. For example, it can help you do tasks at work or play games with your kids at home. Given an objective, it can make a plan and execute the plan all by itself.
      </Typography>

      <Typography paragraph>
        If this is your first time, visit the Getting Started documentation for a tutorial. To read more about why we’ve built this, why you should try it, and the surrounding infrastructure, visit drl/amp-agents. 
      </Typography>

      <Divider />

      Use the links below to get started!

      <List>
        <ListItem>
          <ListItemIcon>
            <GroupIcon fontSize="large"/>
          </ListItemIcon>
          <Link href="/agent/list">
            <Typography variant="h3" >
              View and manage new agents
            </Typography>
          </Link>
        </ListItem>

        <ListItem>
          <ListItemIcon>
            <LibraryBooksIcon fontSize="large"/>
          </ListItemIcon>
          <Link href="/documentation/agent/getting-started">
            <Typography variant="h3" >
              Getting Started Documentation
            </Typography>
          </Link>
        </ListItem>
      </List>

      {/*
      <Box sx={{display: "flex", width: 1.0}}>
        {cardContents.map((cardInfo) =>
          <Card>
            <CardContent>
              <Markdown content={dedent(cardInfo.content)} />
            </CardContent>
          </Card>
        )}
      </Box>
      */}
    </Stack>
  );
}
