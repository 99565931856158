import { useEffect, useState } from "react";
import { Header } from "./Header";

import DemosDoc from './docs/sections/hackweek-demos.md'; 
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import remarkToc from "remark-toc";
import remarkSlug from "remark-slug";
import emoji from 'remark-emoji';
import CopyToClipboard from "react-copy-to-clipboard";
import { IoIosCopy } from "react-icons/io";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark, dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Box, CssBaseline, Typography } from "@mui/material";

export const DemosPage = () => {
    const [bodyText, setBodyText] = useState('');

    useEffect(() => {
        fetch(DemosDoc).then(res => res.text()).then(text => setBodyText(text));
    });

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            <Header />
            <Box style={{marginTop: '63px'}}>  
                <Typography paragraph align="center" margin='10px 15px 0px 15px'>
                    <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        remarkPlugins={[remarkGfm, emoji, remarkToc, remarkSlug]}
                        components={{
                            a: ({ node, className, children, ...props }) => {
                                // Markdown doesn't support embedding video natively, so we create an anchor element in Markdown
                                // and convert the anchor to an iFrame here
                                if (props.href?.includes('capture.dropbox.com/embed')) {
                                    return (
                                        <div>
                                            <div>{props.href}</div>
                                            <iframe title={props.title} src={props.href} width="560" height="315" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                        </div>                   
                                    );
                                }
                                return (
                                    <a href={props.href}>{children}</a>
                                );
                            },
                        }}
                    >
                        {bodyText}
                    </ReactMarkdown>
                </Typography>
            </Box>
        </Box>
    );
};