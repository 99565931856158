import {Components, ComponentsOverrides, Theme} from '@mui/material';

export const paperOverrides: Pick<Components<Theme>, 'MuiPaper'> = {
  MuiPaper: {
    styleOverrides: {
      root: ({theme}: {theme: Theme}) => ({
        backgroundColor: theme.palette.primary.contrastText,
      }),
    },
  },
};
export const accordionOverrides = {
  MuiAccordion: {
    styleOverrides: {
      root: ({theme}: {theme: Theme}) => ({
        '&:before': {
          height: 0,
          opacity: 0,
        },
        backgroundColor: theme.palette.info.main,
      }),
    },
  },
};

export const tableOverrides : Pick<Components<Theme>, 'MuiTableHead'> = {
  MuiTableHead: {
    styleOverrides: {
      root: ({theme}: {theme: Theme}) => ({
        backgroundColor: theme.palette.grey[100]
      }),
    }
  },
}
