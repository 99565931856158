/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BasicFunctionDefinition } from './BasicFunctionDefinition';
import type { OpenAPIEndpointDefinition } from './OpenAPIEndpointDefinition';

/**
 * Webhook interface for custom contexts given an OpenAPI spec
 */
export type OpenAPIContextDefinition = {
    id?: string;
    name: string;
    version?: OpenAPIContextDefinition.version;
    owner_email: string;
    description: string;
    trusted?: boolean;
    spec_url: string;
    codegen_examples: Array<BasicFunctionDefinition>;
    tools: Array<OpenAPIEndpointDefinition>;
};

export namespace OpenAPIContextDefinition {

    export enum version {
        BASIC_V1 = 'basic_v1',
        OPENAI_V1 = 'openai_v1',
    }


}

