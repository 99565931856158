import {Badge, Box, Button, ButtonGroup, Card, CardContent, CardHeader, Chip, darken, FormControlLabel, FormGroup, Grid, Paper, Stack, Switch, ToggleButton, ToggleButtonGroup, Tooltip, Typography, useTheme} from "@mui/material";
import {PropsWithChildren, ReactNode, useContext, useEffect, useState} from "react";
import {ContextEngineAgent} from "../../context_engine_client";
import {assertExhaustive, booleanIcon, getContextEngineAgentPlatformService} from "../../utilities";
import VisibilityIcon from '@mui/icons-material/Visibility';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import {NewAgentDialog} from './NewAgentDialog';
import type {NewAgentInfo} from './NewAgentDialog';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PublicIcon from '@mui/icons-material/Public';
import {FileCopy} from "@mui/icons-material";
import DeleteIcon from '@mui/icons-material/Delete';
import ScienceIcon from '@mui/icons-material/Science';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import EditIcon from '@mui/icons-material/Edit';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import {useSnackbar} from "./SnackMessage";
import {finished} from "stream";
import {AgentPermissionsContext} from "../../contexts/AgentPermissions";
import {AgentTestDialog} from "./AgentTestDialog";
import {TooltipButton} from "../TooltipButton";
import {AgentClickHandler} from "./AgentList";

type AgentNameRowProps = {
  agent: ContextEngineAgent;
  isHeading: boolean;
};

const AgentNameRow = ({agent, isHeading}: AgentNameRowProps) => {
  const isPublic = agent.agent_info.public;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: -2,
      }}
    >
      <Typography variant={isHeading ? "h1" : "h3"} margin="0" fontWeight="bold">
        {agent.agent_info.agent_name}
      </Typography>
      {!isPublic
        ? <>
          <LockOutlinedIcon color="error" sx={{marginLeft: 1}} fontSize="small" />
          <Typography color="error">Private</Typography>
        </>
        : <>
          <PublicIcon sx={{marginLeft: 1}} fontSize="small" />
          <Typography>Public</Typography>
        </>
      }
    </Box>
  );
}

export type BooleanChipBadgeProps = PropsWithChildren & {
  value: boolean;
  label: string;
};

export const BooleanChipBadge = ({value, label}: BooleanChipBadgeProps) => {
  return (
      <Chip
        color={value ? "success" : "error"}
        sx={{
          opacity: value ? 0.5 : 0,
        }}
        variant={value ? "filled" : "outlined"}
        label={label}
        size="small"
      />
  );
}

export type AgentBadgeRowProps = {
  agent: ContextEngineAgent;
  onChange: (info: {published?: boolean, deployed?: boolean, finishedCallback: (success: boolean) => void}) => void;
  badgeOnly?: boolean;
};

export const AgentBadgeRow = ({agent, onChange, badgeOnly=false}: AgentBadgeRowProps) => {
  const isDeployed = agent.deployment_info.is_deployed;
  const isPublished = agent.deployment_info.is_published;

  const perms = useContext(AgentPermissionsContext);

  return (
      badgeOnly
        ? <Box sx={{display: "flex", flexDirection: "row", gap: 2}}>
            <BooleanChipBadge value={!!isDeployed} label="Deployed" />
            <BooleanChipBadge value={!!isPublished} label="Published" />
          </Box>
        : <FormGroup sx={{flexDirection: "row"}}>
            <FormControlLabel label="Deployed?" control={
              <Switch
                disabled={!perms.admin.modify.can}
                checked={isDeployed}
                onChange={(event) => {
                  // we're undeploying, so we need to make sure we unpublish first
                  // this should be enforced server-side too
                  if (!event.target.checked) {
                    onChange({
                      published: false, finishedCallback: (success: boolean) => {
                        // only undeploy if we successfully unpublished first
                        success && onChange({deployed: false, finishedCallback: () => null});
                      }
                    });
                    return;
                  }

                  onChange({deployed: event.target.checked, finishedCallback: () => null});
                }}
              />
            } />
            <FormControlLabel label="Published?" control={
              <Switch
                disabled={!perms.admin.modify.can || !isDeployed}
                checked={isPublished}
                onChange={(event) => {
                  onChange({published: event.target.checked, finishedCallback: () => null});
                }}
              />
            } />
        </FormGroup>
  );
}

export type AgentButtonBarProps = {
  agentId: string;
  onEdit: () => void;
  onDelete: () => void;
  onTest: () => void;
  onClone: () => void;
  testable?: boolean;
};

export const AgentButtonBar = ({agentId, onEdit, onDelete, onTest, onClone, testable=true}: AgentButtonBarProps) => {
  const copyDefaultState = {
    title: "Click to Copy!",
    color: "default",
  };
  const copyCopiedState = {
    title: "Copied!",
    color: "success",
  };
  const [idCopyState, setIdCopyState] = useState<typeof copyDefaultState>(copyDefaultState);
  const perms = useContext(AgentPermissionsContext);

  return (
    <ButtonGroup
      sx={{
        gap: 2,
        justifyItems: "right",
      }}
    >
      {perms.admin.modify.can &&
        <Button
          variant="text"
          size="small"
          aria-label="edit"
          onClick={(event) => {
            onDelete();
            event.stopPropagation();
            return false;
          }}
        >
          <DeleteIcon fontSize="small" />
          <Typography fontSize="small" marginLeft={1}>Delete</Typography>
        </Button>
      }

      <Button
        variant="text"
        size="small"
        aria-label="edit"
        onClick={(event) => {
          onClone();
          event.stopPropagation();
          return false;
        }}
      >
        <FileCopyIcon fontSize="small" />
        <Typography fontSize="small" marginLeft={1}>Clone</Typography>
      </Button>

      {perms.admin.modify.can &&
        <Button
          variant="text"
          size="small"
          aria-label="edit"
          onClick={(event) => {
            onEdit();
            event.stopPropagation();
            return false;
          }}
        >
          <EditIcon fontSize="small" />
          <Typography fontSize="small" marginLeft={1}>Edit</Typography>
        </Button>
      }

      { testable &&
        <TooltipButton
          tooltip={perms.test.reason}
          tooltipOnlyWhenDisabled={true}

          variant="outlined"
          color="warning"
          disabled={!perms.test.can}
          size="small"
          aria-label="test"
          onClick={() => onTest()}
        >
          <ScienceIcon fontSize="small" />
          <Typography fontSize="small" marginLeft={1}>Test Agent</Typography>
        </TooltipButton>
      }

      <Button
        variant="contained"
        sx={{
          backgroundColor: "secondary.light",
        }}
        size="small"
        aria-label="copy agent id"
        onClick={(event) => {
          window.navigator.clipboard.writeText(agentId);
          setIdCopyState(copyCopiedState);
          setTimeout(() => setIdCopyState(copyDefaultState), 1000);
          event.stopPropagation();
          return false;
        }}
      >
        <Tooltip
          title={idCopyState.title}
          arrow={true}
          placement="left"
          color={idCopyState.color}
        >
          <div style={{display: "flex"}}>
            <FingerprintIcon fontSize="small" />
            <Typography fontSize="small" marginLeft={1} whiteSpace="nowrap">Copy ID</Typography>
          </div>
        </Tooltip>
      </Button>
    </ButtonGroup>
  );
}

export type AgentInfoProps = {
  agent: ContextEngineAgent;
  isHeading: boolean;
  defaultAction?: "view" | "edit";
  compact?: boolean;
  clickable?: boolean;
  onClick?: AgentClickHandler;
  onEdit?: AgentClickHandler;
  onDelete?: AgentClickHandler;
  onTest?: AgentClickHandler;
  onClone?: AgentClickHandler;
};

export const AgentInfo = ({agent, isHeading, defaultAction = "view", compact = false, clickable = false, onClick, onEdit, onDelete, onTest, onClone}: AgentInfoProps) => {
  const [isEditing, setIsEditing] = useState(defaultAction === "edit");
  const [isTesting, setIsTesting] = useState(false);

  const snackbar = useSnackbar();
  const theme = useTheme();
  const agentPerms = useContext(AgentPermissionsContext);

  const updateAgent = (newAgentInfo: NewAgentInfo) => {
    const platformService = getContextEngineAgentPlatformService();
    platformService
      .updateAgent(
        agent.agent_id,
        {
          agent_name: newAgentInfo.name,
          agent_description: newAgentInfo.description,
          public: newAgentInfo.public,
        }
      )
      .then((newAgent: ContextEngineAgent) => {
        snackbar?.success("Updated agent");
        agent.agent_info.agent_name = newAgent.agent_info.agent_name;
        agent.agent_info.agent_description = newAgent.agent_info.agent_description;
        agent.agent_info.public = newAgent.agent_info.public;
      })
      .catch((error) => {
        const msg = "Could not update agent :(";
        console.log(msg, error);
        snackbar?.error(msg);
      })
      .finally(() => {
        setIsEditing(false);
      });
  };

  const handlePublishDeployChange = ({published, deployed, finishedCallback}: {published?: boolean, deployed?: boolean, finishedCallback: (success: boolean) => void}) => {
    const platformService = getContextEngineAgentPlatformService();
    let promise;
    let action: string;

    if (published !== undefined) {
      if (published) {
        action = "publish"
        promise = platformService.publishAgent(agent.agent_id);
      } else {
        action = "unpublish";
        promise = platformService.unpublishAgent(agent.agent_id);
      }
    }

    if (deployed !== undefined) {
      if (deployed) {
        action = "deploy"
        promise = platformService.deployAgent(agent.agent_id);
      } else {
        action = "undeploy";
        promise = platformService.undeployAgent(agent.agent_id);
      }
    }

    promise
      ?.then((newAgent: ContextEngineAgent) => {
        agent.deployment_info.is_published = newAgent.deployment_info.is_published;
        agent.deployment_info.is_deployed = newAgent.deployment_info.is_deployed;
        snackbar?.success(`Successfully ${action}ed agent`);
        finishedCallback(true);
      })
      .catch((error) => {
        const msg = error.body?.message || `Could not ${action} agent`;
        console.error(msg, error);
        snackbar?.error(msg);
        finishedCallback(false);
      });
  };

  const handleEdit = () => {
    if (onEdit) {
      onEdit({"type": "edit", agent});
    } else {
      setIsEditing(true);
    }
  };

  const handleDelete = () => {
    onDelete && onDelete({"type": "delete", agent});
  };

  const handleTest = () => {
    if (onTest) {
      onTest({"type": "test", agent});
    } else {
      setIsTesting(true);
    }
  };

  const handleClone = () => {
    onClone && onClone({"type": "clone", agent});
  };

  return (
    <>
      <Paper
        variant={compact ? "outlined" : "elevation"}
        elevation={compact ? 0 : 2}
        sx={{
          backgroundColor: "info.light",
          padding: compact ? 1 : 2,
          position: "relative",
          ":hover": {
            backgroundColor: clickable ? theme.palette.info.main : "inherit",
          },
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "row",
            padding: compact ? 1 : 2,
            cursor: clickable ? "pointer" : "inherit",
          }}
          onClick={() => onClick && onClick({type: "click", agent: agent})}
        >
          <Stack spacing={compact ? 2 : 3} margin="0" useFlexGap={true} width={1.0}>
            <AgentNameRow agent={agent} isHeading={isHeading} />

            <Typography color={darken(theme.palette.info.dark, 0.3)}>{agent.agent_info.agent_description}</Typography>

            <Box sx={{display: "flex", width: 1.0}}>
              <div style={{flexGrow: 1}}>
                <AgentBadgeRow
                  agent={agent}
                  onChange={handlePublishDeployChange}
                  badgeOnly={compact || !agentPerms.admin.modify.can }
                />
              </div>

              <div style={{flexGrow: 0}}>
                <AgentButtonBar
                  agentId={agent.agent_id}
                  onEdit={() => handleEdit()}
                  onTest={() => handleTest()}
                  onClone={() => handleClone()}
                  onDelete={() => handleDelete()}
                  testable={!compact}
                />
              </div>
            </Box>
          </Stack>

        </Box>
      </Paper>

      <NewAgentDialog
        title="Edit your agent"
        open={isEditing}
        onCreate={updateAgent}
        onCancel={() => setIsEditing(false)}
        initName={agent.agent_info.agent_name}
        initDescription={agent.agent_info.agent_description}
        initPublic={agent.agent_info.public}
      />

      <AgentTestDialog
        open={isTesting}
        onClose={() => setIsTesting(false)}
        agent={agent}
      />
    </>
  )
}
