/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type TelemetryEvent = {
    user: string;
    product: TelemetryEvent.product;
    version: string;
    ring: string;
    request_id: string;
    event_name: string;
    timestamp: string;
    payload: Record<string, string>;
};

export namespace TelemetryEvent {

    export enum product {
        AMPPLAT = 'AMPPlat',
        CUBE = 'Cube',
        RAVEN = 'Raven',
    }


}

