import { Box, CssBaseline, Toolbar, Typography } from "@mui/material";
import { Header } from "./Header";
import { MarkdownLoader } from "./components/Markdown";
import ExampleAppsDoc from './docs/sections/starter-apps.md';

export const ExamplesPage = () => {
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Header />
            <Box component="main" sx={{ flexGrow: 1, p: 5 }}>
                <Toolbar />
                <Typography align="left">
                    <MarkdownLoader contentUrl={ExampleAppsDoc} />
                </Typography>
            </Box>
        </Box>
    );
};