import { useState } from "react";
import { Button, CircularProgress, FormControlLabel, Grid, InputLabel, Switch, TextField } from "@mui/material";
import ReactJson from "react-json-view";

export const DefaultPlayground = ({ acceptsInput, onRun, initialShowRaw }: { acceptsInput: boolean, onRun: any, initialShowRaw?: boolean }) => {
    const [query, setQuery] = useState('');
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState({ output: '', result: {} });
    const [hasError, setHasError] = useState(false);
    const [showRaw, setShowRaw] = useState(initialShowRaw || false)

    const handleRun = async () => {
        setLoading(true);
        setHasError(false)
        setResult({ output: '', result: {} });
        try {
            const result = await onRun({ query });
            setResult(result);
        } catch (e) {
            const error: any = (e as any).body || e;
            setResult({output: JSON.stringify(error, null, 2), result: JSON.parse(JSON.stringify(error))})
            setShowRaw(true);
            setHasError(hasError);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <Grid item xs={12} container direction='row' justifyContent='center' alignItems='center'>
                {acceptsInput &&
                    <>
                        <Grid item>
                            <InputLabel sx={{ m: 1 }}>Input:</InputLabel>
                        </Grid>
                        <Grid item>
                            <TextField
                                onChange={(e) => setQuery(e.target.value)}
                                sx={{ m: 1 }}
                                id="outlined-multiline-flexible"
                                label="Type your query here..."
                                multiline
                                value={query}
                                style={{ width: '50vw' }}
                            />
                        </Grid>
                    </>}
                <Grid item xs='auto'>
                    <Button sx={{ m: 1 }} variant='contained' onClick={handleRun} disabled={loading}>Run</Button>
                    {loading && (
                        <CircularProgress
                            size={24}
                        />
                    )}
                </Grid>
            </Grid>
            <Grid item xs={12} container direction='column'>
                <Grid item alignSelf='flex-end'>
                    <FormControlLabel control={<Switch onChange={(e, checked) => setShowRaw(checked)} checked={showRaw} />} label="Raw response" />
                </Grid>
                <Grid item alignSelf='center'>
                    {
                        !showRaw ?
                            <TextField
                                id="filled-multiline-static"
                                label="Output"
                                error={hasError}
                                multiline
                                rows={20}
                                value={result.output}
                                variant="filled"
                                contentEditable={false}
                                style={{ width: '70vw' }}
                            /> : <div style={{ width: '70vw', textAlign: "left" }} >
                                <ReactJson src={result.result} />
                            </div>
                    }
                </Grid>
            </Grid>
        </>);
};
