/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum FeedbackType {
    POSITIVE = 'positive',
    NEGATIVE = 'negative',
}
