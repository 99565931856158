/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_send_chat_message_v3_datasource_slack_send_chat_message_post } from '../models/Body_send_chat_message_v3_datasource_slack_send_chat_message_post';
import type { CE_RelevantSlackConversation } from '../models/CE_RelevantSlackConversation';
import type { CE_SlackMessageResponse } from '../models/CE_SlackMessageResponse';
import type { CE_SlackQuestionAnswer } from '../models/CE_SlackQuestionAnswer';
import type { ChannelInfo } from '../models/ChannelInfo';
import type { ConversationMessageInfo } from '../models/ConversationMessageInfo';
import type { MessageResponse } from '../models/MessageResponse';
import type { models__slack__SlackUserInfo } from '../models/models__slack__SlackUserInfo';
import type { SearchInput } from '../models/SearchInput';
import type { SlackEmbeddedSnippet } from '../models/SlackEmbeddedSnippet';
import type { SlackTroubleshootingInfo } from '../models/SlackTroubleshootingInfo';
import type { SlackUser } from '../models/SlackUser';
import type { SlackUserBasic } from '../models/SlackUserBasic';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SlackService {

    /**
     * Slack Login Endpoint
     * @param postLoginRedirectUri
     * @returns any Successful Response
     * @throws ApiError
     */
    public static slackLogin(
        postLoginRedirectUri?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/auth/slack/login',
            query: {
                'post_login_redirect_uri': postLoginRedirectUri,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Slack Logout Endpoint
     * @returns any Successful Response
     * @throws ApiError
     */
    public static slackLogout(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v3/auth/slack/logout',
        });
    }

    /**
     * Slack List Channels
     * Returns a list of channels the logged-in user is a member of
     * @param skip
     * @param limit
     * @returns ChannelInfo Successful Response
     * @throws ApiError
     */
    public static slackListChannels(
        skip?: number,
        limit: number = 100,
    ): CancelablePromise<Array<ChannelInfo>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/slack/channels_list',
            query: {
                'skip': skip,
                'limit': limit,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Slack Get Channel
     * Gets the channel info given channel_id
     * @param channelId
     * @returns ChannelInfo Successful Response
     * @throws ApiError
     */
    public static slackGetChannel(
        channelId: string,
    ): CancelablePromise<ChannelInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/slack/channels/{channel_id}',
            path: {
                'channel_id': channelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Slack Check Membership In Channel
     * Returns a boolean with membership of the user in a channel
     * @param channelId
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static slackCheckMembershipInChannel(
        channelId: string,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/slack/channels/{channel_id}/check_membership',
            path: {
                'channel_id': channelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Slack List Messages In Channel
     * Returns a of slack messages in a channel ordered by recency
     * @param channelId
     * @param skip
     * @param limit
     * @returns ConversationMessageInfo Successful Response
     * @throws ApiError
     */
    public static slackListMessagesInChannel(
        channelId: string,
        skip?: number,
        limit: number = 200,
    ): CancelablePromise<Array<ConversationMessageInfo>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/slack/channels/{channel_id}/messages',
            path: {
                'channel_id': channelId,
            },
            query: {
                'skip': skip,
                'limit': limit,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Slack Get User
     * Gets the Slack user info given user_id
     * @param userId
     * @returns models__slack__SlackUserInfo Successful Response
     * @throws ApiError
     */
    public static slackGetUser(
        userId: string,
    ): CancelablePromise<models__slack__SlackUserInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/slack/users/{user_id}',
            path: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Slack List All Users
     * Returns a list of all users in the workspace
     * @returns SlackUserBasic Successful Response
     * @throws ApiError
     */
    public static slackListAllUsers(): CancelablePromise<Array<SlackUserBasic>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/slack/users_list',
        });
    }

    /**
     * Slack List Users In Conversation
     * Returns a list of all users that have participated in a slack thread
     * @param channelId
     * @param threadTs
     * @returns models__slack__SlackUserInfo Successful Response
     * @throws ApiError
     */
    public static slackListUsersInConversation(
        channelId: string,
        threadTs: string,
    ): CancelablePromise<Array<models__slack__SlackUserInfo>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/slack/conversations/{channel_id}/{thread_ts}/list_all_users',
            path: {
                'channel_id': channelId,
                'thread_ts': threadTs,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Slack Get Conversations User Is Involved In
     * Returns a list of all threaded conversations the user has participated in ordered by recency. Skip and limit apply to messages fetched
     * @param skip
     * @param limit
     * @returns ConversationMessageInfo Successful Response
     * @throws ApiError
     */
    public static slackGetConversationsUserIsInvolvedIn(
        skip?: number,
        limit: number = 200,
    ): CancelablePromise<Array<Array<ConversationMessageInfo>>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/slack/conversations',
            query: {
                'skip': skip,
                'limit': limit,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Slack Get Snippets With Embeddings For Conversation
     * @param channelId
     * @param threadTs
     * @returns SlackEmbeddedSnippet Successful Response
     * @throws ApiError
     */
    public static slackGetSnippetsWithEmbeddingsForConversation(
        channelId: string,
        threadTs: string,
    ): CancelablePromise<Array<SlackEmbeddedSnippet>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/slack/snippets/{channel_id}/{thread_ts}',
            path: {
                'channel_id': channelId,
                'thread_ts': threadTs,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Slack Search Conversations
     * Searches for messages in the workspace that match the input query within the specified timespan
     * @param requestBody
     * @returns CE_RelevantSlackConversation Successful Response
     * @throws ApiError
     */
    public static slackSearchConversations(
        requestBody: SearchInput,
    ): CancelablePromise<Array<CE_RelevantSlackConversation>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v3/datasource/slack/search/conversations',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Slack Answer Question
     * Returns a list of possible answers for the question asked, by searching through the user's slack conversations.
     * The first answer is the most likely based on the most relevant search result.
     * @param query
     * @param numResults
     * @returns CE_SlackQuestionAnswer Successful Response
     * @throws ApiError
     */
    public static slackAnswerQuestion(
        query: string,
        numResults: number = 1,
    ): CancelablePromise<Array<CE_SlackQuestionAnswer>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/slack/answer_question',
            query: {
                'query': query,
                'num_results': numResults,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Slack Delete Content
     * Deletes all slack related content for a user and signs them out of slack.
     * @returns MessageResponse Successful Response
     * @throws ApiError
     */
    public static slackDeleteContent(): CancelablePromise<MessageResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v3/datasource/slack/delete_content',
        });
    }

    /**
     * Slack Delete Index
     * Deletes the index for the current user
     * @returns MessageResponse Successful Response
     * @throws ApiError
     */
    public static slackDeleteIndex(): CancelablePromise<MessageResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/slack/delete_index',
        });
    }

    /**
     * Slack Troubleshooting
     * If Slack messages do not seem to be updating, try this endpoint first to check for common issues
     * @returns SlackTroubleshootingInfo Successful Response
     * @throws ApiError
     */
    public static slackTroubleshooting(): CancelablePromise<SlackTroubleshootingInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/slack/troubleshooting',
        });
    }

    /**
     * Auth Get Slack Token
     * Gets the Slack access token for the current user
     * @returns string Successful Response
     * @throws ApiError
     */
    public static authGetSlackToken(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/slack/accesstoken',
        });
    }

    /**
     * Slack Get User Object
     * Gets the Slack user object given user_id
     * @returns SlackUser Successful Response
     * @throws ApiError
     */
    public static slackGetUserObject(): CancelablePromise<SlackUser> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/datasource/slack/user_object',
        });
    }

    /**
     * Send Chat Message
     * Sends a message to a channel
     * @param formData
     * @returns CE_SlackMessageResponse Successful Response
     * @throws ApiError
     */
    public static sendChatMessage(
        formData: Body_send_chat_message_v3_datasource_slack_send_chat_message_post,
    ): CancelablePromise<CE_SlackMessageResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v3/datasource/slack/send_chat_message',
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
