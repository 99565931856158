import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { Header } from './Header';
import api_reference from './dbxos_api_reference.json';
import contextengine_api_reference from './contextengine_api_reference.json';

import { RedocStandalone } from 'redoc';

  
export const APIReferencePage = () => {
    return (
        <>
            <Box>
                <CssBaseline />
                <Header />
                <Box style={{marginTop: '63px'}}>     
                    <RedocStandalone 
                      spec={api_reference}
                      options={{
                        scrollYOffset: '#appBarHeader'
                      }}
                     />     
                </Box>
            </Box>
        </>
  );
}

export const ContextEngineAPIReferencePage = () => {
  return (
      <>
          <Box>
              <CssBaseline />
              <Header />
              <Box style={{marginTop: '63px'}}>     
                  <RedocStandalone 
                    spec={contextengine_api_reference}
                    options={{
                      scrollYOffset: '#appBarHeader'
                    }}
                   />     
              </Box>
          </Box>
      </>
);
}