import { useEffect, useState, useContext, createContext, ReactNode } from "react";
import { getAuthService, getLogoutUrl } from "../utilities";
import { useNavigate } from "react-router-dom";
import { ApiError } from "../ampplat_client";

type AMPPlatUserContext = {
    curUser: AMPPlatUser | null
    logOut?: () => void
}

const UserContext = createContext<AMPPlatUserContext>({curUser: null});

const allowedDomains = [
    "dropbox.com",
    "dbx51.com"
];

function isAllowedEmail(email: string) {
    const emailDomain = email.split("@")[1];
    return allowedDomains.includes(emailDomain);
}


export function ProvideUser({ children }: any): JSX.Element {
    const auth = useProvideUser();
    return <UserContext.Provider value={auth}>{children}</UserContext.Provider>;
}

export const useCurrentUser = () => {
    return useContext(UserContext);
};

export type AMPPlatUser = {
    user_id: string
    display_name: string;
    email: string;
    token: string;
};

const userStorageKey = "dbxos_user";

export const setUserLocalStorage = (user: AMPPlatUser | null) => {
    if (!user) {
        localStorage.removeItem(userStorageKey);
    } else {
        localStorage.setItem(userStorageKey, JSON.stringify(user));
    }
};

export const getUserFromLocalStorage = () => {
    const userString = localStorage.getItem(userStorageKey);
    if (userString) {
        return JSON.parse(userString) as AMPPlatUser;
    } else {
        return null;
    }
};

function useProvideUser() {
    const [curUser, setCurUser] = useState<AMPPlatUser | null>(getUserFromLocalStorage());

    const setUser = (user: AMPPlatUser | null) => {
        setCurUser(user);
        setUserLocalStorage(user);
    };

    const logOut = () => { 
        return getAuthService().routeLogoutAndRemoveCookie()
            .then((response) => {
                console.log("logged out response:", response);
            }).catch((error) => {
                console.log("error logging out", error);
            }).finally(() => {
                console.log("logged out -- redirecting")
                setUser(null);
                window.location.href = getLogoutUrl();
            });
    };

    useEffect(() => {
        const loadCurUser = async () => {
            try {
                const loggedInUser = await getAuthService().readUsersMe();
                if(!isAllowedEmail(loggedInUser.email)) {
                    console.log("User email domain is not allowed, logging out.");
                    await logOut();
                    return;
                }

                const token = await getAuthService().getToken();
                const user: AMPPlatUser = {
                    ...loggedInUser,
                    token
                };

                setUser(user);
            } catch (e) {
                if(e instanceof ApiError) {
                    if(e.status === 401) { // auth error, kick them out
                        setUser(null);
                    }
                }

                console.error(e);
            }
        };

        loadCurUser();
    }, []);

    return { curUser, logOut };
}

export const RequireLoggedInUser = ({children}: {children?:ReactNode}) => {
    const { curUser } = useCurrentUser();
    const navigate = useNavigate();

    useEffect(() => {
        let timeoutId: any;

        if (!curUser) {
          console.log('No user detected, redirecting in 1 second...');
      
          timeoutId = setTimeout(() => {
            console.log("navigating to login");
            navigate('/login', { replace: true });
          }, 1000);
        }
      
        return () => {
          clearTimeout(timeoutId);
        };
    }, [curUser, navigate]);

    if (!curUser) {
        console.log('Have no user info, rendering blank page.')
        return <></>;
    } else {
        return <>{children}</>;
    };
};
