import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

type SimpleListProps<T> = { 
    label: string,
    id: string,
    options: T[], 
    selectedOption: T,
    onChange: (option: T) => void
}
export const SimpleList = ({options, selectedOption, label, id, onChange}: SimpleListProps<string | number>) => {
    return (
        <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id={id}>{label}</InputLabel>
            <Select
                labelId={id}
                label={label}
                value={selectedOption}
                onChange={(e) => onChange(e.target.value)}
            >
                {options.map(option => <MenuItem value={option}>{option}</MenuItem>)}
            </Select>
        </FormControl>
    );
}